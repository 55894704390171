import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TAResources } from 'src/app/Interface/resources';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-candidate-dashboard-answers',
  templateUrl: './candidate-dashboard-answers.component.html',
  styleUrls: ['./candidate-dashboard-answers.component.css']
})
export class CandidateDashboardAnswersComponent implements OnInit, OnDestroy {

  user: any;
  question: any;
  data: any;
  _selectedButtonValue: string;
  task: any;
  @Input()
  public set rowData(v: any) {
    this.user = v
  }

  @Input()
  public set colData(v: any) {
    this.question = v
  }
  @Input()
  public set selectedButtonValue(v: string) {
    this._selectedButtonValue = v

    if (this._selectedButtonValue == 'Questions') {
      this.tafbs.getUserAnswerDATA(this.user.UserId, this.question.id).subscribe(d => {
        if (d) {
          this.data = d
        } else {
          this.data = null
        }
      })
    } else if (this._selectedButtonValue == 'InterviewQuestions') {
      this.task = this.tafbs.getUserinterviewAnswerDATA(this.user.UserId, this.question.id).pipe(take(1)).subscribe(d => {
        if (d) {
          this.data = d
        } else {
          this.data = null
        }
      })
    }


  }
  constructor(private tafbs: TekarchFbService) { }

  ngOnInit() {
  }
  ngOnDestroy() {
    this.task.unsubscribe();
  }
}
