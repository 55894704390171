// List of roles displayed in the dropdown
// these collection comes from "staticdropdown" collection inside "roles" collection

import { Component, OnInit, Input } from '@angular/core';
import { DropDownHelper } from '../../helper/dropdownhelper';
import { ColDD } from 'src/app/Interface/col-dd';
import { TACourse } from 'src/app/Interface/course';


@Component({
  selector: 'app-time-dd',
  templateUrl: '../../helper/bl-dd.html'
})
export class TimeDdComponent extends DropDownHelper<ColDD> {
  colName = "Time";
  placeholder = "Choose Time Zone";
  field = "time";
  // ddSize = 50;

  @Input()
  selectedElement: string;

  @Input()
  public set course(v: boolean) {
    if (v == true) {
      this.selectedElement = null;
    }
  }

  @Input()
  ddSize: number;


  getDisplayField(obj: ColDD): string {
    return obj.time;
  }

  getKeyField(obj: ColDD): string {
    return obj.time;
  }

  // objects has special filtering logic. So thisis completely overridden
  filterAction(query, data: ColDD[]): ColDD[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: ColDD[] = [];
    for (let i = 0; i < data.length; i++) {
      let obj = data[i];
      if (this.selectedElement) {
        if (
          this.selectedElement == obj.time &&
          obj.time.toLowerCase().indexOf(query.toLowerCase()) != -1
        ) {
          filtered.push(obj);
        }
      } else {
        if (obj.time.toLowerCase().indexOf(query.toLowerCase()) != -1) {
          filtered.push(obj);
        }
      }
    }
    return filtered;
  }

}
