import { Component, OnInit, Input } from '@angular/core';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TekarchDataService } from 'src/app/service/tekarchdataservice';
import { AngularFirestore } from '@angular/fire/firestore';
import { InterviewQaService } from 'src/app/service/interview-qa.service';

@Component({
  selector: 'app-interview-qa-que-heading',
  templateUrl: './interview-qa-que-heading.component.html',
  styleUrls: ['./interview-qa-que-heading.component.css']
})
export class InterviewQaQueHeadingComponent implements OnInit {
  question: any;
  EditHeading: any;
  EditQuestionHeading: any;
  display: boolean;
  selecteduserlist: any;
 
  click: boolean;
  _heading: any;
  currentuserRole: string;
  questionslist: any[];
  Questiondialog: boolean;
  EditUserQuestion: any;
  EditUserData: any;
  selectedUser: any;
  dragval: any;
  Answerdialog: boolean;
  EditUserAnswer: any;
  editansdetails: any;
  text1: string = '<div>Hello World!</div><div>PrimeNG <b>Editor</b> Rocks</div><div><br></div>';

  @Input()
  public set Click(v: boolean) {
    if (v) {
      this.click = v;
    }
  }
  @Input()
  public set SelectedUserList(v: any) {
    if (v) {
      let unique_array1 = [];
      this.selecteduserlist = v;
      // console.log( this.selecteduserlist);
    }
  }
  @Input()
  public set heading(v: any) {
    this._heading = v;
    //  console.log(v);
    if(this.currentuserRole=='Student'){
        this.taiqs.getuserInterviewQAheading(this._heading.id).subscribe(data => {
        //  console.log(data["name"]);
        // console.log(this._heading);
        
         let date=new Date().getTime()
        //  console.log(this._heading.audiodate>=date);
        //  console.log(this._heading.audiodate+""+date);
         if(this._heading.audiodate>=date){
          this._heading.audiovisible=true
         }else{
          this._heading.audiovisible=false
         }
        //  this._heading.audiodate=
         this._heading.name=data["name"]
         return this._heading
        })
      this.taiqs.getselectedInterviewQAquestions(this._heading.id).subscribe(data => {
        this.questionslist = data;
        this.questionslist.map(d=>{
      return d.audiovisible=this._heading.audiovisible
        })
        // console.log(this.questionslist);
        
      })
    }
    else{
    this.taiqs.getselectedInterviewQAquestions(this._heading.id).subscribe(data => {
      this.questionslist = data;
    })
  }
 

  }
  constructor(private taiqs:InterviewQaService, private tds: TekarchDataService, public afs: AngularFirestore) {
    this.currentuserRole = this.tds.getCurrentUser().RoleName;
  }
  ngOnInit() {
  }
 
  EditiconClick(field) {
    this.EditUserData = field;
    this.EditUserQuestion = field.Question;
    this.Questiondialog = true;
  }
  clickTime(){
    const clicktime = new Date().getTime()
    this.tds.setAccordiantime(clicktime)
  }
  UpdateQuestion(field, qus) {
   
    this.taiqs.updateInterviewQAques(this._heading.id, qus,field.id);
    this.Questiondialog = false;
  }
  cancelQuestion(field) {

    this.taiqs.getselectedInterviewQAquestions(this._heading.id).subscribe(data => {
      this.questionslist = data;
    })
    this.Questiondialog = false;
  }
  deleteQuestion(field) {
    this.taiqs.deleteInterviewQAques(this._heading.id,field.id);
    this.Questiondialog = false;
  }
  isNullOrEmpty(str) {
    return !str || !str.trim();
  }
  saveQuestions(q) {
    let id = this.afs.createId();
    if (q) {
      q = q.charAt(0).toUpperCase() + q.slice(1);
      if (this.isNullOrEmpty(q)) {
        return;
      } else {
        this.taiqs.addInterviewQAQuestion(this._heading.id,id,q);
        // this.tfs.getInterviewQuestionList("questions", "IndexNo").pipe(take(1)).subscribe(d => {
        //   if (d[0] == undefined) {
        //     this.indexnumber = 1;
        //   } else {
        //     this.indexnumber = d[0].IndexNo + 1;
        //   }
        //   this.tfs.addQuestionForUsers(this._heading, q, id, this.questionNo, this.indexnumber);
        // })

      }
    }

    this.question = null;
  }
  deleteHeadingDialog(h) {
    const clicktime = new Date().getTime()
    this.tds.setAccordiantime(clicktime)
    this.EditHeading = h;
    this.EditQuestionHeading = this.EditHeading.name;
    this.display = true;
    console.log('qus');
    
  }
  UpdateHeading(h,sh){
  this.taiqs.updateInterviewQAHeading(h, sh)
 }
  cancelHeading() {
    this.taiqs.getselectedInterviewQAquestions(this._heading.id).subscribe(data => {
      this.questionslist = data;
    })
    this.display = false;
  }
  deleteHeading() {

    this.taiqs.deleteInterviewQAHeading(this._heading.id);
    // }
    this.display = false;
  }
  multipleSelectOnclick(e){
    const clicktime = new Date().getTime();
    this.tds.setAccordiantime(clicktime);
  }
  onChangeMultiselect(e){}
  audioVisibleaccess(e,heading){
    const clicktime = new Date().getTime()
    this.tds.setAccordiantime(clicktime)
    let now = new Date()
    let last2days = new Date(now.setDate(now.getDate() + 2))
    let date=new Date(last2days.toUTCString()).getTime();
    var b=this.selecteduserlist.UserId;
    var key=b.replace(/\./g,"_");
    var usersUpdate = {};
    if(e.checked==true){
    let audioaccess=key+"audio"
    // usersUpdate[`${key}`] = true;
    usersUpdate["audiodate"]=date;
    // usersUpdate["userid"]=this.selecteduserlist.UserId;
    // usersUpdate["id"]=heading.id
    usersUpdate[`${audioaccess}`]=true
  //  console.log(usersUpdate);
   
    this.afs.collection("visibledates").doc(this.selecteduserlist.UserId).collection("date").doc(heading.id).update(
      usersUpdate
    );
    }else{
      let audioaccess=key+"audio"
      usersUpdate["audiodate"]=null;
      usersUpdate[`${audioaccess}`]=false
      this.afs.collection("visibledates").doc(this.selecteduserlist.UserId).collection("date").doc(heading.id).update(
        usersUpdate
      );
    }
  }
  questionVisibleaccess(e,heading){
    const clicktime = new Date().getTime()
    this.tds.setAccordiantime(clicktime)
    let now = new Date()
    let last2days = new Date(now.setDate(now.getDate() +60))
    let date=new Date(last2days.toUTCString()).getTime();
    var b=this.selecteduserlist.UserId;
    var key=b.replace(/\./g,"_");
    var usersUpdate = {};
    if(e.checked==true){
    usersUpdate["questiondate"]=date;
    usersUpdate["userid"]=this.selecteduserlist.UserId;
    usersUpdate["id"]=heading.id
    usersUpdate[`${key}`]=true
   this.afs.collection("visibledates").doc(this.selecteduserlist.UserId).set({id:heading.id})
   this.afs.collection("visibledates").doc(this.selecteduserlist.UserId).collection("date").doc(heading.id).set(
    usersUpdate
  );
   }
   else{
    usersUpdate["questiondate"]=null;
    usersUpdate["audiodate"]=null;
    // usersUpdate["userid"]=this.selecteduserlist.UserId;
    // usersUpdate["id"]=heading.id
    usersUpdate[`${key}`]=false;
  //  console.log(usersUpdate);
  //  this.afs.collection("dates").doc(this.selecteduserlist.UserId).set({id:heading.id})
   this.afs.collection("visibledates").doc(this.selecteduserlist.UserId).collection("date").doc(heading.id).update(usersUpdate);}
  }
  
  dragStart(e) {
    // console.log(e);
    
   this.dragval=e
  }
  dragEnd(e){
// console.log(e);

  }
  drop(topic,e){
    console.log(e);
   let dropval=e
   if(this.dragval.Question!=e.Question){
    //  console.log(e.index-1);
     let val=e.SortBy-1
     this.taiqs.updateIndexno(topic,this.dragval.id,e.id,val)
   }
  }
  questiondatechecked(_heading){
    if(_heading) return  _heading.questiondate==true
   }
   audiodatechecked(_heading){
    if(_heading) return  _heading.audiodate==true
   }
   UpdateAnsDialog(e){
     this.Answerdialog=true
     this.editansdetails=e
     this.EditUserAnswer=e.Answer
   }
   UpdateAnswer(ans){
     console.log(ans);
     
     let answer=ans
    this.taiqs.updateInterviewQAAns(this._heading.id, answer,this.editansdetails.id);
    this.Answerdialog = false;
   }
   cancelAnswer(){
    this.Answerdialog = false;
   }
   deleteAnswer(){
    this.taiqs.updateInterviewQAAns(this._heading.id, null,this.editansdetails.id);
    this.Answerdialog = false;
   }
}
