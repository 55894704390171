import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { ColDD, webacc } from 'src/app/Interface/col-dd';
import { TACourse } from 'src/app/Interface/course';
import { TAUser } from 'src/app/Interface/ta-user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firestore } from 'firebase/app';
import Timestamp = firestore.Timestamp;
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-webinarheading-dd',
  templateUrl: './webinarheading-dd.component.html',
  styleUrls: ['./webinarheading-dd.component.css']
})
export class WebinarheadingDdComponent implements OnInit {

  userlist: any[];
  ddSize: number = 40;
  SelectedUser: any;
  courselist: TACourse;
  timezone:string="Indian Standard Time (IST) - UTC+05:30"
  @Output()
  selectedwebinar: EventEmitter<webacc[]> = new EventEmitter<webacc[]>();
  account: any;
  cols: { field: string; header: string; }[];
  deleteConfimation: boolean;
  accountdetail: webacc;
  webinardetail: any;
  editConfimation: boolean;
  heading:string
  starttime:Date
  endtime:Date
  val1:string="classroom"
  start: Date;
  end: Date;
  datepipe: any;
  ddreset: any;
  @Input()
  public set course(v: TACourse) {
    this.courselist=v
    this.tafbs.getfuturewebinarlist<webacc>(this.courselist.id).subscribe(data => {
      this.userlist = data
      // this.userlist =  data.map(d =>{ 
      //   let a=d.start.seconds
      //      d.date=Timestamp.fromMillis(a * 1000).toDate()
      //      return d
      //    })
      // console.log(data);
      // let a=new Date(this.userlist[0].start)
      // console.log(this.userlist[0].start,a);
    });
    // this._ResourseList = v;
  }
  // @Input()
  // public set ddReset(v: any) {
  //   if (v == null || v == undefined) {
  //     this.courselist = null;
  // }
    
  // }

  constructor(private tafbs: TekarchFbService,private messageService: MessageService,private http:HttpClient) { }

  ngOnInit() {
    this.cols = [
      // { field: 'date', header: 'Time' },
      { field: 'name', header: 'Webinar Name' },
      
  ];
  }
  onKeyPress(event) {
   
  }

  filterAction(query, data: any[]): any[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    for (let i = 0; i < data.length; i++) {
      let selecteduser = data[i];
      console.log(selecteduser);
      
      if (selecteduser.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(selecteduser);
      }
    }
    return filtered;
  }

  //#endregion

//   onDropDownSelect(e){
// console.log(e);
// this.selectedwebinar.emit(e);

//   }
  selectedwebinaredit(e){
    this.webinardetail=e
    console.log(this.webinardetail)
    this.starttime=this.webinardetail.start
    console.log(this.webinardetail,this.starttime);
    
    // this.endtime=Timestamp.fromMillis(e.end.seconds * 1000).toDate();;
    this.endtime=this.webinardetail.end
    this.heading=e.name
    this.val1=e.session_type
console.log(e);
this.tafbs.getaccColList<webacc>("GoToWebinar",e.webinar_account).subscribe(data => {
  this.accountdetail = data[0];
console.log(data);
})
this.editConfimation=true
  }
  selectedwebinardelete(e){
    this.webinardetail=e
    this.tafbs.getaccColList<webacc>("GoToWebinar",e.webinar_account).subscribe(data => {
      this.accountdetail = data[0];
    console.log(data);
    })
console.log(e);
this.deleteConfimation=true
  }
  async deleteuser(){
    this.deleteConfimation=false
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.accountdetail.access_token
      })
    };
    let url="https://api.getgo.com/G2W/rest/v2/organizers/"+this.accountdetail.organizer_key+"/webinars/"+this.webinardetail.webinar_id
    await this.http.delete(url,httpOptions1).subscribe(d=>{
      this.messageService.add({severity:'Successfully Deleted', summary: 'Success Message'});
      // alert("successfully deleted")
      setTimeout(() => {
        this.tafbs.deetewebinar("courses",this.webinardetail.course_id,"webinar_sessions", this.webinardetail.id)
      }, 2000);
    }, err => alert( err.message),)
    console.log(this.webinardetail);
   
  }
  Canceldeleteuser(){
    this.deleteConfimation=false
    this.editConfimation=false
  }

  savewebinar(heading,s,e,type){
    // this.start=s;
    // this.end=e
    // let a=new Date(s.setHours(s.getHours() + 4));
    // let b=new Date(e.setHours(e.getHours() + 4))
                
    // let startdate =this.datepipe.transform(a, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
    // let enddate =this.datepipe.transform(b, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
   
    // const httpOptions1 = {
    //   headers: new HttpHeaders({
    //     'Content-Type':  'application/json',
    //     'Authorization': this.accountdetail.access_token
    //   })
    // };
    // let url="https://api.getgo.com/G2W/rest/v2/organizers/"+this.accountdetail.organizer_key+"/webinars"
    // let data={
    //   "subject": heading,
    //   "description": heading+" - TekArch Portal",
    //   "times": [
    //     {
    //       "startTime": startdate,
    //       "endTime": enddate
    //     }
    //   ],
    //   "timezone": "GMT-08:00",
    //   "isPasswordProtected": false
    // }
    
    // this.http.put(url,data,httpOptions1).subscribe(d=>{
      // this.ddreset=null
      // let a=Object.values(d)
      // let webinarid=a[0]
      // console.log(d);
      console.log(this.webinardetail);
      console.log(this.courselist);
      this.editConfimation=false
      
      this.tafbs.updatewebinar(this.webinardetail,this.courselist.id,heading,type)
      // this.account=null;this.heading=null; this.course=null;this.starttime=null;this.endtime=null;this.end=null;
    // })
    alert("Successfully Edited")
     }
    
}
