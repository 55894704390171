import { Component, OnInit, Input } from '@angular/core';
import { TAProgram } from 'src/app/Interface/program';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TekarchDataService } from 'src/app/service/tekarchdataservice';

@Component({
  selector: 'app-assignment-list',
  templateUrl: './assignment-list.component.html',
  styleUrls: ['./assignment-list.component.css']
})
export class AssignmentListComponent implements OnInit {
  click: boolean;
  _heading: TAProgram;
  questionslist: any;
  EditHeading: any;
  EditQuestionHeading: any;
  display: boolean;
  questionslistQus: any;
  currentuserRole: string;
  courseId: any;
  courseHeading: any;
  tableDialog: boolean;
  @Input()
  public set Click(v: boolean) {
    if (v) {
      this.click = v;
    }
  }
  @Input()
  public set heading(v: TAProgram) {
    this._heading = v;
    this.tfs.getAssignmentbyHeading("assignment", this._heading.id).subscribe(data => {
      this.questionslist = data;
    })
  }
  constructor(private tfs: TekarchFbService, private tds: TekarchDataService) {
    this.currentuserRole = this.tds.getCurrentUser().RoleName;

  }

  ngOnInit() {
  }
  clickTime() {
    const clicktime = new Date().getTime()
    this.tds.setAccordiantime(clicktime)
  }
  resourceHeadingDialog(h) {
    const clicktime = new Date().getTime()
    this.tds.setAccordiantime(clicktime)
    this.EditHeading = h;
    this.EditQuestionHeading = this.EditHeading.Heading;
    this.display = true;
  }
  async UpdateHeading(h, sh) {
    this.tfs.updateHeadingresources(h, sh)
    // this.newUpdateHeading(h, sh);
    if (this.questionslist.length != 0) {
      await this.tfs.getUserCourseId(this._heading).subscribe(d => {
        var result = d.reduce((unique, o) => {
          if (!unique.some(obj => obj.UserId === o.UserId)) {
            unique.push(o);
          }
          return unique;
        }, []);
      })
    }

    this.display = false;
  }
  async newUpdateHeading(h, sh) {

    await this.questionslist.forEach(d => {
      this.tfs.QuestionColHeadingUpdate(d, sh);
    })
    await this.tfs.updateHeadingresources(h, sh)
  }
  cancelHeading() {
    this.tfs.getAssignmentbyHeading("assignment", this._heading.Heading).subscribe(data => {
      this.questionslist = data;
    })
    this.display = false;
  }
  deleteHeading() {
    if (this.questionslist.length != 0) {
      this.tfs.getUserCourseId(this._heading).subscribe(d => {
        var result = d.reduce((unique, o) => {
          if (!unique.some(obj => obj.UserId === o.UserId)) {
            unique.push(o);
          }
          return unique;
        }, []);
        result.forEach(data => {
          this.questionslist.forEach(d => {
            this.questionslistQus = d;
            this.tfs.deleteQuestions(d, data);
            this.tfs.deleteQuestion(d);
            this.tfs.deleteQuestionHeading(this._heading);
          })
        })
      })
    } else {
      this.tfs.deleteQuestionHeading(this._heading);
    }
    this.display = false;
    // this.questionslist.forEach(d => {
    //   this.tfs.deleteQuestion(d);
    // })

    // this.tfs.deleteQuestionHeading(this._heading);
  }
  answerTable(heading) {
    const clicktime = new Date().getTime()
    this.tds.setAccordiantime(clicktime)
    // console.log(heading);
    
    this.courseId = heading;
    this.courseHeading = heading.id;
    this.tableDialog = true;
  }
}
