import { Component } from '@angular/core';
import { AuthService } from './Auth/auth.service';
import { Router, NavigationStart } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TekarchFbService } from './service/tekarchfbservice';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { saveAs } from 'file-saver';
import { firestore } from 'firebase/app';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  showWelcomeStyle: boolean;
  dl:any={}
  RoleName: any;
  dataa: any[];
  constructor(private tafbs: TekarchFbService, private db: AngularFirestore, public auth: AuthService, private _router: Router, private http: HttpClient) {
    this._router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        this.VerifyNavigation(e);
      }
    });
  }
  title = 'TAPortal';

  VerifyNavigation(e: NavigationStart) {
    this.showWelcomeStyle = (e.url.length <= 1 || e.url.includes('welcome')) ? true : false;

  }

  

}
