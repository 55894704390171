import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TAResources } from 'src/app/Interface/resources';


@Component({
  selector: 'app-resource-heading-dd',
  templateUrl: './resource-heading-dd.component.html',
  styleUrls: ['./resource-heading-dd.component.css']
})
export class ResourceHeadingDdComponent implements OnInit {
  _ResourseList: TAResources[];
  Resource: TAResources;
  ddsize:number
  @Output()
  SelectedResourceHeading = new EventEmitter();

  @Input()
  public set resourseList(v: TAResources[]) {
    this.Resource=null
    this._ResourseList = v;
  }

  @Input()
  public set ddSize(v : number) {
    this.ddsize = v;
  }

  constructor() { }

  ngOnInit() {
  }
  onKeyPress(event) {
    this._ResourseList = this.filterAction(event.query, this._ResourseList)
  }

  filterAction(query, data: TAResources[]): TAResources[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: TAResources[] = [];
    for (let i = 0; i < data.length; i++) {
      let resource = data[i];
      if (resource.Heading.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(resource);
      }
    }
    return filtered;
  }

  //#endregion

  onDropDownSelect(e) {
    this.SelectedResourceHeading.emit(e);
  }
}
