import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './Component/home-page/home-page.component';
import { DashboardComponent } from './Component/dashboard/dashboard.component';
import { AuthGuard } from './Auth/auth.guard';
import { ManageCourseComponent } from './Component/manage-course/manage-course.component';
import { ManageStudentAccessComponent } from './Component/manage-student-access/manage-student-access.component';
import { ManageUsersComponent } from './Component/manage-users/manage-users.component';
import { ManageProgramsComponent } from './Component/manage-programs/manage-programs.component';
import { ManageProgramResourceComponent } from './Component/manage-program-resource/manage-program-resource.component';
import { TaProgramComponent } from './Component/ta-program/ta-program.component';
import { TaCourseComponent } from './Component/ta-course/ta-course.component';
import { ManageUserProgressComponent } from './Component/manage-user-progress/manage-user-progress.component';
import { AdminGuard } from './Auth/admin.guard';
import { ManageVideoResourceComponent } from './Component/manage-video-resource/manage-video-resource.component';
import { DatasheetComponent } from './Component/datasheet/datasheet.component';
import { EmailComponent } from './Component/email/email.component';

import { AssignmentComponent } from './Component/assignment/assignment.component';
import { QuestionTopicDashboardComponent } from './Component/question-topic-dashboard/question-topic-dashboard.component';
import { PredefinedQuestionsComponent } from './Component/predefined-questions/predefined-questions.component';
import { PredefinedInterviewQuestionsComponent } from './Component/predefined-interview-questions/predefined-interview-questions.component';
import { InterviewPreperationComponent } from './Component/interview-preperation/interview-preperation.component';
import { InterviewQaComponent } from './Component/interview-qa/interview-qa.component';
import { InterviewQaQuestionComponent } from './Component/interview-qa-question/interview-qa-question.component';
import { WebinarComponent } from './Component/webinar/webinar.component';
import { UserFilterDashboardComponent } from './Component/user-filter-dashboard/user-filter-dashboard.component';
import { AccessComponent } from './Component/access/access.component';


const routes: Routes = [{ path: "welcome", component: DashboardComponent, canActivate: [AuthGuard] },
{ path: "", component: HomePageComponent },
{ path: "Course", component: ManageCourseComponent, canActivate: [AuthGuard] },
{ path: "manageuser", component: ManageUsersComponent, canActivate: [AdminGuard] },
{ path: "managecourse", component: ManageCourseComponent, canActivate: [AdminGuard] },
{ path: "manageprogram", component: ManageProgramsComponent, canActivate: [AdminGuard] },
{ path: "managestudentaccess", component: ManageStudentAccessComponent, canActivate: [AdminGuard] },
{ path: "manageprogramresource", component: ManageProgramResourceComponent, canActivate: [AdminGuard] },
{ path: "managevideoresource", component: ManageVideoResourceComponent, canActivate: [AdminGuard] },
{ path: "program/:id", component: TaProgramComponent, canActivate: [AuthGuard] },
{ path: "course", component: TaCourseComponent, canActivate: [AuthGuard] },
{ path: "manageuserprogress", component: ManageUserProgressComponent, canActivate: [AuthGuard] },
{ path: "datasheet", component: DatasheetComponent, canActivate: [AuthGuard] },
{ path: "email", component: EmailComponent, canActivate: [AuthGuard] },
{ path: "assignment", component: AssignmentComponent, canActivate: [AuthGuard] },
{ path: "interviewpreperation", component: InterviewPreperationComponent, canActivate: [AuthGuard] },
{ path: "managewebinar", component: WebinarComponent, canActivate: [AuthGuard] },
{ path: "interviewQA", component: InterviewQaQuestionComponent, canActivate: [AuthGuard] },
{ path: "useranswer", component: QuestionTopicDashboardComponent, canActivate: [AuthGuard] },
{ path: "predefinedquestions", component: PredefinedQuestionsComponent, canActivate: [AuthGuard] },
{ path: "predefinedInterviewquestions", component: PredefinedInterviewQuestionsComponent, canActivate: [AuthGuard] },
{ path: "filteruser", component: UserFilterDashboardComponent, canActivate: [AuthGuard] },
{ path: "noaccess", component: AccessComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
