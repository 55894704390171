import { Component, OnInit, ViewChild } from '@angular/core';
import { TAProgram } from 'src/app/Interface/program';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TekarchDataService } from 'src/app/service/tekarchdataservice';
import { InterviewQaService } from 'src/app/service/interview-qa.service';
import { InterviewQaQueHeadingComponent } from '../interview-qa-que-heading/interview-qa-que-heading.component';
import { TAUser } from 'src/app/Interface/ta-user';
import { AngularFirestore } from '@angular/fire/firestore';
@Component({
  selector: 'app-interview-qa-question',
  templateUrl: './interview-qa-question.component.html',
  styleUrls: ['./interview-qa-question.component.css']
})
export class InterviewQaQuestionComponent implements OnInit {
  programListData: TAProgram[];
  accordion: string;
  predefinedInterviewQuestionList: any[];
  heading: any;
  SelectedCourse: any;
  multiple:boolean=true
  click=true
  selecteduserlist: any;
  currentuserRole: string;
  currentuser:TAUser;
  data:any={}
  constructor(private taiqs:InterviewQaService,private tads: TekarchDataService,private db: AngularFirestore,private tafbs: TekarchFbService) { 
    this.currentuserRole = this.tads.getCurrentUser().RoleName;
    this.currentuser = this.tads.getCurrentUser();
  }
  ngOnInit() {
    if(this.currentuserRole=="Student"){
    this.taiqs.getuserInterviewQAList(this.currentuser.UserId).subscribe(d => {  
      this.predefinedInterviewQuestionList = d;
    })
  }
//     var date2 = new Date(); 
// var date1 =new Date("2016-08-28T07:00:00.000Z");
// var diff = Math.abs(date1.getTime() - date2.getTime());
// var diffDays = Math.ceil(diff / (1000 * 3600 * 24)); 
// console.log(diffDays);

// console.log((datetwo.getTime()-dateone.getTime())/ (1000 * 3600 * 24));
// console.log(dateone.getDate());
// var dayDif:Number = (datetwo - dateone)  / 1000 / 60 / 60 / 24;
  }
  SelectedCourseDD(e){
    this.SelectedCourse=e
//     let now = new Date()
// console.log('Today: ' + now.toUTCString())
// let last30days = new Date(now.setDate(now.getDate() - 30))
// console.log('Previous: ' + last30days.toUTCString())
// console.log(new Date().getTime());
// console.log(new Date(last30days.toUTCString()).getTime());


  }
  
  SelectedStudentDD(e){

    this.selecteduserlist=e
    if(e&&this.currentuserRole=="Admin"){
      this.taiqs.getInterviewQAList().subscribe(d => {  
        this.predefinedInterviewQuestionList = d;
        this.predefinedInterviewQuestionList.map(quelist=>{
          if(this.currentuserRole!="Admin") return
return this.taiqs.getselectedInterviewQADates(quelist.id,this.selecteduserlist.UserId).subscribe(d=>{
  let now = new Date().getTime()
// let last2days = new Date(now.setDate(now.getDate() + 2))
// console.log('Previous: ' + new Date(last2days.toUTCString()).getTime())
// console.log("90days"+new Date(now.setDate(now.getDate() - 90)).getTime());
// let date=new Date(last2days.toUTCString()).getTime();
  quelist.questiondate=d[0]?true:false
  if(d[0]&&d[0].audiodate>=now){
  quelist.audiodate=true
}else{quelist.audiodate=false}
  return quelist
})
        })
      })
    }
  }
  Accordian(e) {
    const clicktime = new Date().getTime()
    if (clicktime - this.tads.getAccordiantime() < 1000) {
      return
    } else {
      if (e) {
        if (e == this.tads.getviewprogram()) {
          this.tads.setviewprogram("null");
          this.accordion = "null"
        }
        else {
          this.accordion = e
          this.tads.setviewprogram(e);
        }
      }

    }
  }
  saveHeading(q){
    let que=q.charAt(0).toUpperCase() + q.slice(1);
    this.taiqs.addInterviewQAHeading(que);
    this.heading = null;
  }
  bulkupdate(){
    let batch = this.db.firestore.batch();
    this.tafbs.getColl().subscribe(val=>{
      if(val.length>=2){
        
      val.forEach(data=>{ 
        this.data.CourseName = data.CourseName?data.CourseName:"";
        this.data.CreatedAt = data.createdAt?data.createdAt:"";
        this.data.UpdatedAt = data.updatedAt?data.updatedAt:"";
        this.data.id = data.id;
        this.db.collection("ta_courses").doc(data.id).set(this.data) 
      }) 
    
    }batch.commit().catch(err => console.error(err));
    }
    
  )
  }
}

