import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TAUser } from 'src/app/Interface/ta-user';

@Component({
  selector: 'app-datasheet',
  templateUrl: './datasheet.component.html',
  styleUrls: ['./datasheet.component.css']
})
export class DatasheetComponent implements OnInit {
  types: SelectItem[];
  rangeDates: Date[];
  startDate: Date;
  endDate: Date;
  selectedType: string;
  _selecteduserlist: string;
  selectedUserlist: any;
  a: any[];
  userlist: any[];
  selectedlist: any[];
  uselist: any;
  unique_array1: any[];
  unique_array2: any[];
  _courselist: string;
  AllCandidatelist: TAUser[];
  click: boolean = true;
  click1: boolean
  constructor(private tafs: TekarchFbService) {
    this.tafs.getUserList<TAUser>("candidate").subscribe(
      async doc => {
        this.userlist = doc;
        doc.forEach(async element => {
          this.tafs.getcourse(element).subscribe(d => {
            let data = []
            for (let index = 0; index < d.length; index++) {
              data.push(d[index].CourseName)
              this._courselist = data.join()
            } element.CourseList = this._courselist
          });
        });
        this.AllCandidatelist = doc

      });
  }

  ngOnInit() {
    this.selectedType = "All Candidate"
    this.types = [
      { label: 'All Candidate', value: 'All Candidate', icon: 'fas fa-users' },
      // {label: 'Selected Candidate', value: 'Selected Candidate', icon: 'fas fa-user'}
    ];

  }
  selectedtypevalue(e) {
  }
  selectedrange(event) {
    this.startDate = new Date(this.rangeDates[0]);
    this.endDate = new Date(this.rangeDates[1]);
    if (this.startDate <= this.endDate) {
      this.click = true;
      this.click1 = true
    }



  }
  DownloadAllCandidate() {
    setTimeout(() => {
      this.JSONToCSVConvertor(this.AllCandidatelist, "Candidate Report", true);
    }, 2000);

  }
  GetSelectedlist() {
    this.tafs.getCandidateByDate(this.startDate, this.endDate).subscribe(
      docs => {
        this.unique_array1 = [];
        this.unique_array2 = [];
        for (let index = 0; index < docs.length; index++) {
          if (this.unique_array1.indexOf(docs[index].UserId) == -1) {
            this.unique_array1.push(docs[index].UserId);
            this.unique_array2.push(docs[index]);
          }
        }
      });
  }
  DownloadSelectedCandidate() {

    this.unique_array2.forEach(async element => {
      this.tafs.getcourse(element).subscribe(d => {
        let selecteddata = []
        for (let index = 0; index < d.length; index++) {
          selecteddata.push(d[index].CourseName)
          this._selecteduserlist = selecteddata.join()
        } element.CourseList = this._selecteduserlist
      });
    }); 
    this.JSONToCSVConvertor(this.unique_array2, "Candidate Report", true);
  }

  JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
 
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    var CSV = '';
    //Set Report title in first row or line

    CSV += ReportTitle + '\r\n\n';

    //This condition will generate the Label/Header
    if (ShowLabel) {
      var row = "";
    
      //This loop will extract the label from 1st index of on array
      for (var index in arrData[0]) {
  
        //Now convert each value to string and comma-seprated
        row += index + ',';
      }

      row = row.slice(0, -1);
            //append Label row with line break
      CSV += row + '\r\n';
    }

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      var row = "";

      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in arrData[i]) {
        row += '"' + arrData[i][index] + '",';
      }

      row.slice(0, row.length - 1);

      //add a line break after each row
      CSV += row + '\r\n';
    }

    if (CSV == '') {
      alert("Invalid data");
      return;
    }

    //Generate a file name
    var fileName = "MyReport_";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension    

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    // link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  Click() {
    // this.tafs.getUserList<TAUser>("candidate").subscribe(data => {
    // this.userlist = data
    this.userlist.forEach(d => {
      this.tafs.updateUserstatus(d);
    })

    // })
    // setTimeout(() => {
    //   for (let index = 0; index < this.userlist.length; index++) {
    //     this.tafs.updateUserstatus(this.userlist[index]).then(d => { console.log(d) }

    //     )

    //   }
    // }, 2500);
  }
}
