import { Component, OnInit, Input } from '@angular/core';
import { TAResources } from 'src/app/Interface/resources';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TekarchDataService } from 'src/app/service/tekarchdataservice';
import { AngularFirestore } from '@angular/fire/firestore';
import { TAUser } from 'src/app/Interface/ta-user';
import { AudioRecordingService } from 'src/app/service/audio-recording.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-question-candidate-view',
  templateUrl: './question-candidate-view.component.html',
  styleUrls: ['./question-candidate-view.component.css']
})
export class QuestionCandidateViewComponent implements OnInit {
  _questionList: any[];
  _heading: TAResources;
  currentUser: TAUser;
  currentUserRole: string;
  aaaa: any;
  _questionList1: any;

  click:boolean=false
  showAudio:boolean=false

  @Input()
  public set questionlist(v: any[]) {
    this._questionList = v;
    this.getAnswer(this._questionList)
  }

  @Input()
  public set heading(v: TAResources) {
    this._heading = v;
    // console.log(v);
    var b=this.currentUser.UserId;
    var key=b.replace(/\./g,"_");
    this.showAudio=this._heading[key]
    // console.log(this._heading[key]);
    
    // console.log( this.showAudio);
    
  }
  constructor(private audioRecordingService: AudioRecordingService,private sanitizer: DomSanitizer,private tfs: TekarchFbService, private tds: TekarchDataService, public afs: AngularFirestore) {
    this.currentUser = this.tds.getCurrentUser();
    this.currentUserRole = this.tds.getCurrentUser().RoleName;
    
   
  }
  getAnswer(Ans) {
    this._questionList1=Ans
    if (this._questionList1)
    this._questionList1.map(element => {
        // this.tfs.getUserinterviewAnswers(this.currentUser, element).subscribe(d => {
        //   if (d) {
        //     this.aaaa = d
        //     let data={"name":this.aaaa.SelectedAnswer}
        //     element.SelectedAnswer = data
        //   }
        // })
        // 
        // console.log(Ans);
        // console.log(element);
        // console.log(element["CourseId"]);
        // console.log(element["krishna_softwiz@gmail_com"]);
        // console.log(element[this.currentUser.UserId]);
        var b=this.currentUser.UserId
        var key=b.replace(/\./g,"_");
    
       
       if(element[key]){
        let data=element[key];
          return  element.SelectedAnswer = data
        }
          //   this._questionList1=element
            // console.log( this._questionList1);
            
          // return element
      });    
  }

  ngOnInit() {
  }
  onClick(ans, selectedAns) {
    var usersUpdate = {};
    var b=this.currentUser.UserId
    var key=b.replace(/\./g,"_");
    usersUpdate[`${key}`] = selectedAns;
    this.afs.collection("questions").doc(ans.id).update(usersUpdate);
    this.tfs.adduserAns(this.currentUser, ans, selectedAns);
  }
  trackbyfunction(index, item) {
    return index

  }
  
  playAudio(field){

    let audio = new Audio();
    audio.src = field.AudioUrl;
    audio.load();
    audio.play();

  }
 
}
