import { Component, OnInit } from '@angular/core';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import * as firebase from "firebase/app";
import { AngularFirestore } from '@angular/fire/firestore';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-user-filter-dashboard',
  templateUrl: './user-filter-dashboard.component.html',
  styleUrls: ['./user-filter-dashboard.component.css']
})
export class UserFilterDashboardComponent implements OnInit {

  conditionVal: any[];
  expiryDate:Date
  startDate:Date
  endDate:Date
  selectedDD: any;
  ddValue: String;
  cols: { field: string; header: string; }[];
  filteredData: any[];
  types: { label: string; value: string; }[];
  selectedButtonValue:string='createdAt'
  SelectedCourse: any;
  userByCourse: any[];
  editselectedUser: any;
  editdialogVisible: boolean;
  multieditdialogVisible: boolean;
  multiupdateexpiryDate:Date
  constructor(private db:TekarchFbService,private dbs: AngularFirestore,public datepipe: DatePipe) { }

  ngOnInit() {
    this.cols = [
      { field: "UserId", header: "User Id" },
      { field: "UserName", header: "User Name" },
      { field: "CourseName", header: "Coures Name" },
      { field: "expiryDate", header: "Expiry Date" },
    ];
    this.conditionVal = [
      {name: 'Before', code: 'before'},
      {name: 'After', code: 'after'},
      {name: 'Between', code: 'between'}
  ];
  this.types = [
    { label: 'SortBy CreatedAt', value: 'createdAt' },
    { label: 'SortBy Batch', value: 'courseName' }
  ];
}
onChange(e) {
  console.log(e);
  this.selectedButtonValue=e.value
}

SelectedCourseDD(e) {
  this.SelectedCourse = e;
  console.log(e);
  this.db.getUserCourseUsingId(e.CourseId).subscribe(d=>{
    console.log(d);})
  this.db.getUserCourseUsingId(e.CourseId).subscribe(d=>{
    console.log(d);
    d.map((element:any) => {
      console.log(element);
      
      if(element&&element.expiryDate&&element.expiryDate.seconds){
        var seconds=element.expiryDate.seconds
        console.log(seconds);
        
      var theDate = new Date( seconds* 1000);
var dateString = this.datepipe.transform(theDate, 'dd-MM-yyyy');
      element.expiryDate=dateString
      console.log(dateString);
      }
    });


this.userByCourse=d
  })
  
}
CancelUserUpdate() {
  this.db.getUserCourseUsingId(this.SelectedCourse.CourseId).subscribe(d=>{
    d.map((element:any) => {
      if(element&&element.expiryDate&&element.expiryDate.seconds){
      var theDate = new Date(element.expiryDate.seconds * 1000);
var dateString = theDate;
      element.expiryDate=dateString
      console.log(dateString);
      }
    });
    this.userByCourse=d
      })
  this.editselectedUser = null;
  this.editdialogVisible = false;
}
selectedUserEdit(e) {
  console.log(e);
  
  this.editselectedUser = e;
  this.editdialogVisible = true;
}
onDropDownSelect(e){

  this.ddValue=e.toString()
  console.log(this.ddValue);
  
  if(this.ddValue=="After"){
  }
  if(this.ddValue=="Before"){

  }
  if(this.ddValue=="Between"){

  }
}

UpdateExpiryDate(e){
  console.log(e);
  this.db.updateExpiryDate(e)

}
selectedExpieryDate(){

  console.log(  this.expiryDate);
  let operator;
  if(this.ddValue=="After"){
    operator=">="
    this.db.getStudentManagementByDate(this.expiryDate,operator).subscribe(d=>{
      console.log(d);
      d.map((element:any) => {
        if(element&&element.expiryDate&&element.expiryDate.seconds){
        var theDate = new Date(element.expiryDate.seconds * 1000);
  var dateString = theDate.toUTCString();
        element.expiryDate=dateString
        console.log(dateString);
        }
      });
      this.filteredData=d
    })
  }
  if(this.ddValue=="Before"){
    operator="<="
    this.db.getStudentManagementByDate(this.expiryDate,operator).subscribe(d=>{
      console.log(d);
      d.map((element:any) => {
        if(element&&element.expiryDate&&element.expiryDate.seconds){
        var theDate = new Date(element.expiryDate.seconds * 1000);
  var dateString = theDate.toUTCString();
        element.expiryDate=dateString
        console.log(dateString);
        }
      });
      this.filteredData=d
    })

  }
 
}
selectedEndExpieryDate(){
  if(this.startDate&&this.endDate){
    this.db.getStudentManagementByRange(this.startDate,this.endDate).subscribe(d=>{
      console.log(d);
    
      d.map((element:any) => {
        if(element&&element.expiryDate&&element.expiryDate.seconds){
        var theDate = new Date(element.expiryDate.seconds * 1000);
  var dateString = theDate.toUTCString();
        element.expiryDate=dateString
        console.log(dateString);
        }
      });
      this.filteredData=d
    })
  }

}
multiselectedUserEdit(){
  this.multieditdialogVisible = true;
}
selectedStartExpieryDate(){
  console.log(  this.startDate);
  if(this.startDate&&this.endDate){
    this.db.getStudentManagementByRange(this.startDate,this.endDate).subscribe(d=>{
      console.log(d);
      d.map((element:any) => {
        if(element&&element.expiryDate&&element.expiryDate.seconds){
        var theDate = new Date(element.expiryDate.seconds * 1000);
  var dateString = theDate.toUTCString();
        element.expiryDate=dateString
        console.log(dateString);
        }
      });
      this.filteredData=d
    })
  }

}
multiUpdateExpiryDate(e){
  let batch = this.dbs.firestore.batch();
  console.log(e);
  
  this.userByCourse.forEach(data => {
    this.dbs.collection("studentmanagement").doc(data.id).update({expiryDate:e,})
  }); batch.commit().catch(err => console.error(err));
  this.multieditdialogVisible = false;
}
multiCancelUserUpdate(){
  this.multieditdialogVisible = false;
}
}
