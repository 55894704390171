import { Component, OnInit, Input } from '@angular/core';
import { TAResources } from 'src/app/Interface/resources';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TekarchDataService } from 'src/app/service/tekarchdataservice';
import { AngularFirestore } from '@angular/fire/firestore';
import { TAUser } from 'src/app/Interface/ta-user';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-assignment-data',
  templateUrl: './assignment-data.component.html',
  styleUrls: ['./assignment-data.component.css']
})
export class AssignmentDataComponent implements OnInit {
  _questionList: any[];
  _heading: TAResources;
  currentUser: TAUser;
  currentUserRole: string;
  data: any;
  _questionList1: any;
  indexNo: number;
  questionNo: number;
  question: any;
  assignmentNo: number;
  assignmentheading: string
  @Input()
  public set questionlist(v: any[]) {
    this._questionList = v;

  }

  @Input()
  public set heading(v: TAResources) {
    this._heading = v;

  }
  constructor(private tfs: TekarchFbService, private tds: TekarchDataService, public afs: AngularFirestore) { }

  ngOnInit() {
    this.currentUser = this.tds.getCurrentUser();
    this.currentUserRole = this.tds.getCurrentUser().RoleName;
  }
  trackbyfunction(index, item) {
    if (!item) return index

    return item
  }
  getAnswer(Ans) {
    if (Ans)
      Ans.forEach(element => {
        this.tfs.getUserAnswers(this.currentUser, element).subscribe(d => {
          if (d) {
            this.data = d
            element.selectedAns = this.data.SelectedAnswer
          }
        })
        this._questionList1 = Ans;
      });
  }
  isNullOrEmpty(str) {
    return !str || !str.trim();
  }
  saveHeading(q) {
    let id = this.afs.createId();
    if (q) {
      q = q.charAt(0).toUpperCase() + q.slice(1);
      if (this.isNullOrEmpty(q)) {
        return;
      } else {
        this.tfs.getCourseAssignmentValue(this._heading.CourseId, this._heading.Heading).pipe(take(1)).subscribe(async d => {
          if (d[0] == undefined) {
            this.assignmentNo = 1;
          } else {
            this.assignmentNo = d[0].AssignmentNo + 1;
          }
          this.tfs.getCourseAssignmentIndexValue(this._heading.CourseId, this._heading.Heading).pipe(take(1)).subscribe(async d => {
            if (d[0] == undefined) {
              this.indexNo = 1;
            } else {
              this.indexNo = d[0].IndexNo + 1;
            }
            await this.assignmentNo;
            await this.tfs.addAssignmentForUsers(this._heading, q, id, this.assignmentNo, this.indexNo);
          })

        })
      }
    }


    this.assignmentheading = null;
  }
}
