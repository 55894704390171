import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { TAProgram } from 'src/app/Interface/program';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';


@Component({
  selector: 'app-multiple-program-dd',
  templateUrl: './multiple-program-dd.component.html',
  styleUrls: ['./multiple-program-dd.component.css']
})
export class MultipleProgramDdComponent implements OnInit {

  programList: TAProgram[];
  SelectedProgram: TAProgram;
  selctedProgramList: Array<TAProgram> = [];
  _autocom: boolean;
  ddsize:number
  selectedCourse: string;

  @Output()
  SelectedPrograms: EventEmitter<TAProgram[]> = new EventEmitter<TAProgram[]>();


  @Input()
  
  public set SelectedCourse(v : string) {
    this.selectedCourse = v;
    this.SelectedProgram=null
  }
  


  @Input()
  public set ddSize(v : number) {
    this.ddsize = v;
  }
  @Input()
  public set ddReset(v: boolean) {
    if (v == true) {
      this.SelectedProgram = null;
    }
  }

  constructor(private tafbs: TekarchFbService) { }

  ngOnInit() {
    if (this.selectedCourse) {
      this.tafbs.getProgrambyCourse<TAProgram>("programs", this.selectedCourse).subscribe(data => {
        this.programList = data;
      });
    }
  }
  onKeyPress(event) {
    this.tafbs.getProgrambyCourse<TAProgram>("programs", this.selectedCourse).subscribe(data => {
      this.programList = this.filterAction(event.query, data);
    });
  }

  filterAction(query, data: TAProgram[]): TAProgram[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: TAProgram[] = [];
    for (let i = 0; i < data.length; i++) {
      let SelectedProgram = data[i];
      if (SelectedProgram.ProgramName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(SelectedProgram);
      }
    }
    return filtered;
  }
  onDropDownSelect(e) {
    if (this.selctedProgramList.indexOf(e) === -1) {
      this.selctedProgramList.push(e);
      this.SelectedPrograms.emit(this.selctedProgramList);
    }
  }
  onDropDownUnSelect(e) {
    const index = this.selctedProgramList.findIndex(data => data.ProgramName === e.ProgramName);
    this.selctedProgramList.splice(index, 1);
    this.SelectedPrograms.emit(this.selctedProgramList);
  }

}
