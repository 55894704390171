import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { TopBarComponent } from './Component/top-bar/top-bar.component';
import { HomePageComponent } from './Component/home-page/home-page.component';
import { AuthService } from './Auth/auth.service';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FirestoreService } from './service/firestoreservice';
import { TekarchDataService } from './service/tekarchdataservice';
import { TekarchFbService } from './service/tekarchfbservice';
import { AuthGuard } from './Auth/auth.guard';
import { NotifyService } from './Auth/notify.service';
import { DashboardComponent } from './Component/dashboard/dashboard.component';
import { ManageCourseComponent } from './Component/manage-course/manage-course.component';
import { ManageUsersComponent } from './Component/manage-users/manage-users.component';
import { ManageProgramsComponent } from './Component/manage-programs/manage-programs.component';
import { ManageStudentAccessComponent } from './Component/manage-student-access/manage-student-access.component';
import { ManageProgramResourceComponent } from './Component/manage-program-resource/manage-program-resource.component';
import { TableModule } from 'primeng/table';
import { OverlayPanelModule } from "primeng/overlaypanel";
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { FooterComponent } from './Component/footer/footer.component';
import { TaCourseComponent } from './Component/ta-course/ta-course.component';
import { RatingModule } from 'primeng/rating';
import { TaProgramComponent } from './Component/ta-program/ta-program.component';
import { ProgramViewComponent } from './Component/program-view/program-view.component';
import { ProgramVideoComponent } from './Component/program-video/program-video.component';
import { RolesDdComponent } from './Component/roles-dd/roles-dd.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropDownHelper } from './helper/dropdownhelper';
import { CourseDdComponent } from './Component/course-dd/course-dd.component';
import { ProgramDdComponent } from './Component/program-dd/program-dd.component';
import { UserDdComponent } from './Component/user-dd/user-dd.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ResourceTypeDdComponent } from './Component/resource-type-dd/resource-type-dd.component';
import { ManageUserProgressComponent } from './Component/manage-user-progress/manage-user-progress.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ChartModule } from 'primeng/chart';
import { SafePipe } from './pipe/safe.pipe';
import { AccordionModule } from 'primeng/accordion';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ConfirmationService, MessageService } from 'primeng/api';
import { MultipleProgramDdComponent } from './Component/multiple-program-dd/multiple-program-dd.component';
import { AdminGuard } from './Auth/admin.guard';
import { StudentGuard } from './Auth/student.guard';
import { ManageVideoResourceComponent } from './Component/manage-video-resource/manage-video-resource.component';
import { ResourceHeadingDdComponent } from './Component/resource-heading-dd/resource-heading-dd.component';
import { InputMaskModule } from 'primeng/inputmask';
import { ProgramsDdComponent } from './Component/programs-dd/programs-dd.component';
import { CheckboxModule } from 'primeng/checkbox';
import { DatasheetComponent } from './Component/datasheet/datasheet.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CalendarModule } from 'primeng/calendar';
import { EmailComponent } from './Component/email/email.component';
import { DropdownModule } from 'primeng/dropdown';
import { HeadingComponent } from './Component/heading/heading.component';
import { FileUploadComponent } from './Component/file-upload/file-upload.component';
import { ProgramHeadingComponent } from './Component/program-heading/program-heading.component';
import { AssignmentComponent } from './Component/assignment/assignment.component';
import { CandidateQuestionListComponent } from './Component/candidate-question-list/candidate-question-list.component';
import { QuestionTopicDashboardComponent } from './Component/question-topic-dashboard/question-topic-dashboard.component';
import { HeadingDdComponent } from './Component/heading-dd/heading-dd.component';
import { QuestionDdComponent } from './Component/question-dd/question-dd.component';
import { UserFilterDdComponent } from './Component/user-filter-dd/user-filter-dd.component';
import { AssignmentListComponent } from './Component/assignment-list/assignment-list.component';
import { AssignmentDataComponent } from './Component/assignment-data/assignment-data.component';
import { AssignmentHeadingComponent } from './Component/assignment-heading/assignment-heading.component';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { AssignmentAnswerComponent } from './Component/assignment-answer/assignment-answer.component';
import { AssignmentAnswerSheetComponent } from './Component/assignment-answer-sheet/assignment-answer-sheet.component';
import { QuestionsDashboardAnswerComponent } from './Component/questions-dashboard-answer/questions-dashboard-answer.component';
import { InterviewQueheadingComponent } from './Component/interview-que-heading/interview-que-heading.component';
import { InterviewQueTopicDashboardComponent } from './Component/interview-que-topic-dashboard/interview-que-topic-dashboard.component';
import { InterviewQueCandidateViewComponent } from './Component/interview-que-candidate-view/interview-que-candidate-view.component';
import { InterviewansDatasheetComponent } from './Component/interviewans-datasheet/interviewans-datasheet.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CandidateDashboardComponent } from './Component/candidate-dashboard/candidate-dashboard.component';
import { TreeTableModule } from 'primeng/treetable';
import { CandidateDashboardAnswersComponent } from './Component/candidate-dashboard-answers/candidate-dashboard-answers.component';
import { QuestionHeadingComponent } from './Component/question-heading/question-heading.component';
import { QuestionCandidateViewComponent } from './Component/question-candidate-view/question-candidate-view.component';
import { ReplacePipe } from './pipe/replace.pipe';
import { AudioRecoderComponent } from './Component/audio-recoder/audio-recoder.component';
import { PredefinedQuestionsComponent } from './Component/predefined-questions/predefined-questions.component';
import { PredefinedInterviewQuestionsComponent } from './Component/predefined-interview-questions/predefined-interview-questions.component';
import { PredefinedQuestionsHeadingComponent } from './Component/predefined-questions-heading/predefined-questions-heading.component';
import { PredefinedInterviewQuesHeadingComponent } from './Component/predefined-interview-ques-heading/predefined-interview-ques-heading.component';
import {MultiSelectModule} from 'primeng/multiselect';
import { InterviewPreperationComponent } from './Component/interview-preperation/interview-preperation.component';
import { CourseUserDdComponent } from './Component/course-user-dd/course-user-dd.component';
import { InterviewQueHeadingUserComponent } from './Component/interview-que-heading-user/interview-que-heading-user.component';
import { InterviewQueCandidateViewUserComponent } from './Component/interview-que-candidate-view-user/interview-que-candidate-view-user.component';
import { UserCourseDdComponent } from './Component/user-course-dd/user-course-dd.component';

import { InterviewAudioRecoderComponent } from './Component/interview-audio-recoder/interview-audio-recoder.component';
import { InterviewAudioRecordingService } from './service/interview-audio-service';
import { PredefineInterviewAudioRecordingService } from './service/predefine-interview-audio-service';
import { PredefinedInterviewAudioRecordComponent } from './Component/predefined-interview-audio-record/predefined-interview-audio-record.component';
import { InterviewQaComponent } from './Component/interview-qa/interview-qa.component';
import { InterviewQaQuestionComponent } from './Component/interview-qa-question/interview-qa-question.component';
import { InterviewQaQueHeadingComponent } from './Component/interview-qa-que-heading/interview-qa-que-heading.component';
import { InterviewQaAudioRecordComponent } from './Component/interview-qa-audio-record/interview-qa-audio-record.component';
import {TooltipModule} from 'primeng/tooltip';
import { DragDropModule } from 'primeng/primeng';
import { QuillModule } from 'ngx-quill';
import { WebinarComponent } from './Component/webinar/webinar.component';
import { StartEndTimeDdComponent } from './Component/dd/start-end-time.component';
import { TimeDdComponent } from './Component/dd/time-dd.component';
import { WebinarAccountDdComponent } from './Component/dd/webinar-account-dd.component';
import { WebinarHeadingDdComponent } from './Component/dd/webinar-heading.component';
import { DatePipe } from '@angular/common';
import { AttendanceComponent } from './Component/attendance/attendance.component';
import { WebinarheadingDdComponent } from './Component/webinarheading-dd/webinarheading-dd.component';
import {ToastModule} from 'primeng/toast';
import { UserFilterDashboardComponent } from './Component/user-filter-dashboard/user-filter-dashboard.component';
import { AccessComponent } from './Component/access/access.component';
@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    StartEndTimeDdComponent,
    TimeDdComponent,
    WebinarAccountDdComponent,
    WebinarHeadingDdComponent,
    HomePageComponent,
    DashboardComponent,
    ManageCourseComponent,
    ManageUsersComponent,
    ManageProgramsComponent,
    ManageStudentAccessComponent,
    ManageProgramResourceComponent,
    FooterComponent,
    TaCourseComponent,
    TaProgramComponent,
    ProgramViewComponent,
    ProgramVideoComponent,
    RolesDdComponent,
    DropDownHelper,
    CourseDdComponent,
    ProgramDdComponent,
    UserDdComponent,
    ResourceTypeDdComponent,
    ManageUserProgressComponent,
    SafePipe,ReplacePipe,
    MultipleProgramDdComponent,
    ManageVideoResourceComponent,
    ResourceHeadingDdComponent,
    ProgramsDdComponent,
    DatasheetComponent,
    EmailComponent,
    HeadingComponent,
    FileUploadComponent,
    ProgramHeadingComponent,
    AssignmentComponent,
    QuestionHeadingComponent,
    CandidateQuestionListComponent,
    QuestionTopicDashboardComponent,
    HeadingDdComponent,
    QuestionDdComponent,
    UserFilterDdComponent,
    QuestionsDashboardAnswerComponent,
    AssignmentListComponent,
    AssignmentDataComponent,
    AssignmentHeadingComponent,
    AssignmentAnswerComponent,
    AssignmentAnswerSheetComponent,
    InterviewQueheadingComponent,
    InterviewQueTopicDashboardComponent,
    InterviewQueCandidateViewComponent,
    InterviewansDatasheetComponent,
    CandidateDashboardComponent,
    CandidateDashboardAnswersComponent,
    QuestionCandidateViewComponent,
    AudioRecoderComponent,
    PredefinedQuestionsComponent,
    PredefinedInterviewQuestionsComponent,
    PredefinedQuestionsHeadingComponent,
    PredefinedInterviewQuesHeadingComponent,
    InterviewPreperationComponent,
    CourseUserDdComponent,
    InterviewQueHeadingUserComponent,
    InterviewQueCandidateViewUserComponent,
    UserCourseDdComponent,
    
    InterviewAudioRecoderComponent,
    
    PredefinedInterviewAudioRecordComponent,
    
    InterviewQaComponent,
    
    InterviewQaQuestionComponent,
    
    InterviewQaQueHeadingComponent,
    
    InterviewQaAudioRecordComponent,
    
    WebinarComponent,
    
    AttendanceComponent,
    
    WebinarheadingDdComponent,
    
    UserFilterDashboardComponent,
    
    AccessComponent
  ],
  imports: [
    BrowserModule,DragDropModule,
    SelectButtonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    InputSwitchModule,
    TableModule,
    FormsModule,
    EditorModule,
    CalendarModule,
    OverlayPanelModule,
    HttpClientModule,ToastModule,
    InputTextModule,TooltipModule,
    DialogModule,
    DropdownModule,
    ButtonModule,
    RatingModule,
    InputMaskModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AccordionModule,
    RadioButtonModule,
    AngularFireStorageModule,
    AutoCompleteModule,
    QuillModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    ReactiveFormsModule,
    ChartModule,
    CheckboxModule,
    ConfirmDialogModule,
    DropdownModule,
    FileUploadModule,
    TreeTableModule,
    MultiSelectModule
  ],
  providers: [AuthService,MessageService,DatePipe, AdminGuard, StudentGuard, TekarchFbService, TekarchDataService, FirestoreService, NotifyService, AuthGuard, ConfirmationService,InterviewAudioRecordingService,PredefineInterviewAudioRecordingService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
