import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TekarchDataService } from './tekarchdataservice';
import { FirestoreService } from './firestoreservice';
import { TAUser } from '../Interface/ta-user';
import { Roles } from '../Interface/roles';
import { TAStudentManagement } from '../Interface/studentmanagement';
import { TAProgram } from '../Interface/program';
import { TACourse } from '../Interface/course';
import { e } from '@angular/core/src/render3';
import { TAResources, TAResourseType } from '../Interface/resources';
import { identifierModuleUrl } from '@angular/compiler';
import { HttpBackend, HttpClient } from '@angular/common/http';
import * as firebase from "firebase/app";

import { AngularFirestore } from '@angular/fire/firestore';
import { ColDD, webacc } from '../Interface/col-dd';
import { DatePipe } from '@angular/common';
import { arrayRemove, arrayUnion } from 'firebase/firestore';
import { firestore } from 'firebase/app';




@Injectable()
export class TekarchFbService {
  currentuser: TAUser;

  constructor(private db: FirestoreService,public datepipe: DatePipe, private tads: TekarchDataService, private http: HttpClient, public afs: AngularFirestore) {
    this.currentuser = this.tads.getCurrentUser()
  }
  getUserProfile(user_id?: string | null): Observable<TAUser> {
    console.log("getUserProfile","+",'get user profile details from candidate for dashboard');
    
    return this.db.doc$("candidate/" + user_id)
  }
  getRoles(): Observable<Roles[]> {
    return this.db.colWithIds$("roles");
  }
  getUserList<T>(colName: string): Observable<T[]> {
    return this.db.colWithIds$(colName);
  }
  getDDColList<T>(colName: string): Observable<T[]> {
    if(colName=="courses"){
      return this.db.colWithIds$(colName, res => res.orderBy("CourseId","desc"));
    }else{
    return this.db.colWithIds$(colName);
  }
  }
  getaccColList<T>(colName: string,val:string): Observable<T[]> {
   
      return this.db.colWithIds$(colName, res => res.where("email","==",val));
   
  }
  getCourseList<T>(colName: string): Observable<T[]> {
    console.log("getcourselist","+","get list of courses");
    
    return this.db.colWithIds$(colName, res => res.orderBy("CourseId","desc"));
  }
  updateUserDetails(user: TAUser) {
    return this.db.partialUpdate("candidate/" + user.UserId, {
      UserName: user.UserName ? user.UserName : '',
      RoleName: user.RoleName ? user.RoleName : '',
      UserPhoneNo: user.UserPhoneNo ? user.UserPhoneNo : '',
      UserId: user.UserId ? user.UserId : '',
      InActive: user.InActive ? user.InActive : false
    });
  }
  getDetailList<T>(colName: string): Observable<T[]> {
    return this.db.colWithIds$(colName);
  }
  updateProgramDetails(program: TAProgram) {
    return this.db.partialUpdate("programs/" + program.id, {
      Description: program.Description ? program.Description : "",
      ProgramName: program.ProgramName,
      CourseName: program.CourseName,
      MinimumScore: program.MinimumScore ? program.MinimumScore : 0
    });
  }
  addCourse(courses: TACourse, courseid: number) {
    return this.db.add("courses/", {
      CourseName: courses.CourseName,
      CourseId: courseid,
      Description: courses.Description ? courses.Description : ""
    });
  }
  addProgram(program: TAProgram, courses: TACourse, programid: number) {
    return this.db.add("programs/", {
      CourseName: courses.CourseName,
      CourseId: courses.CourseId,
      ProgramId: programid,
      ProgramName: program.ProgramName,
      Description: program.Description ? program.Description : "",
      MinimumScore: program.MinimumScore ? program.MinimumScore : 0
    }).then(() => console.log('program Added'))
  }
  addUserCourse(useraccess: TAStudentManagement) {
    return this.db.add("studentmanagement", useraccess);
  }
  getCourseId<T>(colName: string, CourseName: string): Observable<T[]> {
    console.log("getCourseID","+",'get CourseId from resources for sortby order',CourseName);
    return this.db.colWithIds$(colName, res => res.where("CourseName", "==", CourseName)
    );
  }
  getProgramId<T>(colName: string, ProgramName: string): Observable<T[]> {

    return this.db.colWithIds$(colName, res => res.where("ProgramName", "==", ProgramName)
    );
  }
  deleteStudentManagement(e: TAStudentManagement) {
    return this.db.delete("studentmanagement/" + e.id)
  }
  getStudentlist<T>(colName: string): Observable<T[]> {
    return this.db.colWithIds$(colName, res => res.where("RoleName", "==", "Student"));
  }
  getCourselistIP<T>(colName: string,userid:string): Observable<T[]> {
    return this.db.colWithIds$(colName, res => res.where("UserId", "==", userid));
  }
  addStudentPrograms(student: TAUser[], course: TACourse,expiryDate:Date) {
    console.log(expiryDate);
    
    student.forEach(data => {
      return this.db.add("studentmanagement/", {
        UserName: data.UserName,
        UserId: data.UserId,
        CourseName: course.CourseName,
        CourseId: course.CourseId,
        expiryDate:expiryDate
      })
    })
  }
  getCourseID<T>(colName: string): Observable<T[]> {
    console.log("getCourseID","+",'get CourseId from resources for sortby order');
    return this.db.colWithIds$(colName, res => res.orderBy("CourseId", "desc"));
  }
  getProgramID<T>(colName: string): Observable<T[]> {
    console.log('get CourseId from resources for sortby order');
    return this.db.colWithIds$(colName, res => res.orderBy("CourseId", "desc"));
  }
  programId<T>(colName: string): Observable<T[]> {
    console.log('get ProgramId from resources for sortby order');
    return this.db.colWithIds$(colName, res => res.orderBy("ProgramId", "desc"));
  }
  getResourseID<T>(colName: string): Observable<T[]> {
    console.log("getResourseID","+",'get ResourseID from resources for sortby order');
    
    return this.db.colWithIds$(colName, res => res.orderBy("ResourceId", "desc"));
  }
  deleteCourse(e) {
    return this.db.delete("courses/" + e.id);
  }
  deleteProgram(e) {
    return this.db.delete("programs/" + e.id);
  }
  deleteProgramResourses(e) {
    return this.db.delete("resources/" + e.id);
  }
  getResoursesList<T>(colName: string, courseId: number, programId: number): Observable<T[]> {
    return this.db.colWithIds$(colName, res => res.where("CourseId", "==", courseId).where("ProgramId", "==", programId))
  }

  getUserProgress<T>(colName: string, courseId: number, programId: number): Observable<T[]> {
    return this.db.colWithIds$(colName, res => res.where("CourseId", "==", courseId).where("ProgramId", "==", programId).where("ParentResource", "==", null).orderBy("Heading"))
  }
  getAssValue<T>(colName: string, courseId: number, programId: number, heading: string, que: number, user: string): Observable<T[]> {
    return this.db.colWithIds$(colName + "/" + courseId + "/" + heading + "/" + user + "/" + que)
  }
  getSelectedResourseList<T>(colName: string, resourseId: number): Observable<T[]> {
    return this.db.colWithIds$(colName, res => res.where("ResourceId", "==", resourseId))
  }
  updateResourse(resourses: TAResources, heading: string, description: string, resourseurl: string) {

    return this.db.partialUpdate("resources/" + resourses.id, {
      ResourceUrl: resourseurl ? resourseurl : "",
      Description: description ? description : "",
      Heading: heading ? heading : ""
    })
  }
  addResourses(heading: string, description: string, resourseurl: string, courseid: number, programid: number, resoursetype: TAResourseType, resourseid: number) {
    return this.db.add("resources/", {
      CourseId: courseid,
      ProgramId: programid,
      Heading: heading,
      Description: description ? description : '',
      ResourceUrl: resourseurl ? resourseurl : '',
      ResourceTypeName: resoursetype.ResourceTypeName,
      ResourceTypeId: resoursetype.ResourceTypeId,
      ParentResource: null,
      ResourceId: resourseid
    });
  }
  addQuestionHeading(heading: string, description: string, resourseurl: string, courseid: number, resoursetype: TAResourseType, resourseid: number) {
  console.log('add  heading to resource');
  
    return this.db.add("resources/", {
      CourseId: courseid,
      Heading: heading,
      Description: description ? description : '',
      ResourceUrl: resourseurl ? resourseurl : '',
      ResourceTypeName: resoursetype.ResourceTypeName,
      ResourceTypeId: resoursetype.ResourceTypeId,
      ParentResource: null,
      ResourceId: resourseid
    });
  }
  addQuestionHeadinguser(heading: string,userid:string, description: string, resourseurl: string, courseid: number, resoursetype: TAResourseType, resourseid: number) {
    var usersUpdate = {};
    var b=userid
    const timestamp = this.timestamp;
    var key=b.replace(/\./g,"_");
    usersUpdate[`${key}`] = false;
    return Promise.all([
    this.db.partialUpdate("usertopic/"+heading, {
      CourseId: courseid,
      Heading: heading,
      Description: description ? description : '',
      ResourceUrl: resourseurl ? resourseurl : '',
      ResourceTypeName: "UserQuestion",
      ParentResource: null,
      // UserId:userid,
      CreatedAt:timestamp
    }),
    this.afs.collection("usertopic").doc(heading).update(usersUpdate)
  ]) 
  }
  updateQuestionHeadinguser(id: string,userid:string, heading: string, description: string, resourseurl: string, courseid: number, resoursetype: TAResourseType, resourseid: number) {
    return this.db.partialUpdate("usertopic/" + id, {
      CourseId: courseid,
      Heading: heading,
      Description: description ? description : '',
      ResourceUrl: resourseurl ? resourseurl : '',
      ResourceTypeName: "UserQuestion",
     UserId:userid,
      id: id
    });
  }
  updateQuestionHeading(id: string, heading: string, description: string, resourseurl: string, courseid: number, resoursetype: TAResourseType, resourseid: number) {
  console.log('update question heading');
  
    return this.db.partialUpdate("resources/" + id, {
      CourseId: courseid,
      Heading: heading,
      Description: description ? description : '',
      ResourceUrl: resourseurl ? resourseurl : '',
      ResourceTypeName: resoursetype.ResourceTypeName,
      ResourceTypeId: resoursetype.ResourceTypeId,
      ParentResource: null,
      ResourceId: resourseid,
      id: id
    });
  }
  deleteResourse(e: TAResources) {
    return this.db.delete("resources/" + e.id)
  }

  getStudentManagementList<T>(colName: string, filterkey: string): Observable<T[]> {
    let currentDate=new Date();
    console.log(currentDate);
    return this.db.colWithIds$(colName, res => res.where("UserId", "==", filterkey)
   
    // return this.db.colWithIds$(colName, res => res.where("expiryDate", ">=", currentDate).where("UserId", "==", filterkey)
    // .orderBy("CourseId")
    );
  }
  getStudentManagementByDate<T>(expiryDate:Date,operator:string): Observable<T[]>{
    return this.db.colWithIds$("studentmanagement", res => res.where("createdAt", operator, expiryDate).orderBy("createdAt")
    )
 
  }
  getStudentManagementByRange<T>(startDate:Date,endDate:Date): Observable<T[]>{
    return this.db.colWithIds$("studentmanagement", res => res.where("createdAt", ">=", startDate).where("createdAt", "<=", endDate).orderBy("createdAt")
    )
  }
  getResourceProgramList<T>(colName: string, filterkey: TAProgram): Observable<T[]> {
    console.log("getResourceProgramListforAss","+",'get video ProgramList from resources');
    
    return this.db.colWithIds$(colName, res => res.where("CourseId", "==", filterkey.CourseId).where("ProgramId", "==", filterkey.ProgramId).orderBy("Heading"));
  }
  getResourceProgramListforAss<T>(colName: string, courseid: string, filterkey: string): Observable<T[]> {
    console.log("getResourceProgramListforAss","+","'get program list for assignment '");
    
    return this.db.colWithIds$(colName, res => res.where("CourseId", "==", filterkey).where("ProgramId", "==", filterkey).orderBy("Heading"));
  }
  getProgrambyCourse<T>(colName: string, coursename: string): Observable<T[]> {

console.log('get program list based on selected course');

    return this.db.colWithIds$(colName, res => res.where("CourseName", "==", coursename).orderBy("updatedAt","desc")
    );
  }
  getUserbyCourse<T>(colName: string, coursename: string): Observable<T[]> {


    return this.db.colWithIds$(colName, res => res.where("CourseName", "==", coursename)
    );
  }
  getUserProgrambyCourse<T>(colName: string, filterkey: string): Observable<T[]> {
    return this.db.colWithIds$(colName, res => res.where("CourseName", "==", filterkey).where("UserId", "==", this.tads.getCurrentUser().UserId)
    );
  }
  deleteUser(user: TAUser) {
    return this.db.delete("candidate/" + user.UserId);
  }
  updateCourseDetails(course: TACourse) {


    return this.db.partialUpdate("courses/" + course.id, {
      CourseName: course.CourseName ? course.CourseName : '',
      Description: course.Description ? course.Description : ''
    })
  }
  updateExpiryDate(course){
    return this.db.partialUpdate("studentmanagement/" + course.id, {expiryDate: course.expiryDate})
  }
  getSelectedViewedResource(resourseId: number): Observable<TAResources[]> {
    return this.db.colWithIds$("videowatchlist/" + resourseId + "/userlist");
  }
  getSelectedViewedResourceForUser(user: TAUser, course: TACourse, program: TAProgram): Observable<TAResources[]> {
    return this.db.colWithIds$("videowatchlistuser/" + user.UserId + "/" + course.CourseId + "/program/" + program.ProgramId);
  }
  // GetResourceForProgram<T>(colName: string,filterkey:string): Observable<T[]> {
  //   return this.db.colWithIds$(colName, res=>res.where("CourseName","==",filterkey)
  //   );
  // }
  // updateProgramDetails(program: TAProgram) {
  //   return this.db.partialUpdate("programs/" + program.id, {
  //     description: program.description,
  //     programname: program.ProgramName,
  //     course: program.CourseName
  //   });
  // }
  getuservideostatus(resource: TAResources, user: string) {
    return this.db.docA("videowatchlist/" + resource.ResourceId + "/userlist/" + user);
  }
  updateUserVideoStatus(resource: TAResources, user: TAUser, value: number) {

    return this.db.partialUpdate("videowatchlist/" + resource.ResourceId + "/userlist/" + user.UserId, {
      UserId: user.UserId ? user.UserId : null, UserName: user.UserName ? user.UserName : null, average: value,
      // VideoCompleted: value
    });

  }
  updateUserVideoStatusforUser(resource: TAResources, user: TAUser, value: number) {

    return this.db.partialUpdate("videowatchlistuser/" + user.UserId + "/" + resource.CourseId + "/program/" + resource.ProgramId + "/" + resource.ResourceId, {
      UserId: user.UserId ? user.UserId : null, UserName: user.UserName ? user.UserName : null, average: value, Heading: resource.Heading ? resource.Heading : null
      // VideoCompleted: value
    });

  }
  updateInActive(user: TAUser) {
    return this.db.partialUpdate("candidate/" + user.UserId, {
      InActive: user.InActive ? user.InActive : false
    })
  }

  // getcourse(element: TAUser): Observable<any[]> {
  //   return this.db.colWithIds$("studentmanagement", res => res.where("UserId", "==", element.UserId))
  // }
  // getCandidateByDate(startdate: Date, enddate: Date) {
  //   if (startdate && enddate)
  //     return this.db.colWithIds$("studentmanagement", res => res
  //       .where("createdAt", ">=", new Date(startdate.setHours(0, 0, 0, 0)))
  //       .where("createdAt", "<=", new Date(enddate.setHours(23, 59, 59, 999)))
  //       //  .orderBy("UserId")
  //     )
  // }
  async updateUserstatus(user: TAUser) {
    await this.db.partialUpdate("candidate/" + user.UserId, { InActive: false }).then(() => console.log('Active Updates'))
  }
  getUserCourse(element: TAStudentManagement): Observable<any[]> {
    console.log('getUserCourse',"+","getUserbyCourse for email");
    
    return this.db.colWithIds$("studentmanagement", res => res.where("CourseName", "==", element.CourseName));
  }
  getUserCourseUsingId(courseId: number): Observable<any[]> {
    console.log("getUserCourseUsingId","+",'get user list from selected course from studentmanagement col');
    
    return this.db.colWithIds$("studentmanagement", res => res.where("CourseId", "==", courseId).orderBy("UserName"));
  }
  getActiveUsers(): Observable<any[]> {
    return this.db.colWithIds$("candidate", res => res.where("InActive", "==", true));
  }
  saveHeading(heading: string) {
    this.db.add("questions", {
      heading: heading
    })
    return this.db.partialUpdate("questions" + "/questionlists/" + heading + "/" + heading, {
      heading: heading
    });
  }
  getQuestionsAndAns(heading: string) {
    return this.db.colWithIds$("questions" + "/questionlists/" + heading);
  }
  addQuestionForUsers(questionDatas: TAResources, question: string, id: string, qno: number, ino: number) {
   console.log('add new que in question col');
   
    const timestamp = this.timestamp;
    return this.db.partialUpdate("questions/" + id, {
      Question: question,
      id: id,
      HeadingId: questionDatas.id,
      Heading: questionDatas.Heading,
      CourseId: questionDatas.CourseId,
      // ProgramId: questionDatas.ProgramId,
      ResourceId: questionDatas.ResourceId,
      ResourceTypeId: questionDatas.ResourceTypeId,
      ResourceTypeName: questionDatas.ResourceTypeName,
      visible: true,
      createdAt: timestamp,

      // QuestionNo: qno,
      IndexNo: ino
    })
  }
  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }
  addinterviewQuestionForUsers(questionDatas: TAResources, question: string, id: string, qno: number, ino: number) {
// console.log(ino,questionDatas.id);

    const timestamp = this.timestamp;
    return this.db.partialUpdate("interviewquestions/" + id, {
      Question: question,
      id: id,
      HeadingId: questionDatas.id,
      Heading: questionDatas.Heading,
      visible: true,
      CourseId: questionDatas.CourseId,
      // ProgramId: questionDatas.ProgramId,
      ResourceId: questionDatas.ResourceId,
      ResourceTypeId: questionDatas.ResourceTypeId,
      ResourceTypeName: questionDatas.ResourceTypeName,
      createdAt: timestamp,
      // QuestionNo: qno,
      IndexNo: ino
    })
  }
  addinterviewQuestionForusersIP(questionDatas: TAResources,userid:string, question: string, id: string, qno: number, ino: number) {
    var usersUpdate = {};
    var b=userid
    var key=b.replace(/\./g,"_");
    usersUpdate[`${key}`] =true;
    const timestamp = this.timestamp;
    return Promise.all([ this.db.partialUpdate("userquestions/" + id, {
      Question: question,
      id: id,
      HeadingId: questionDatas.id,
      Heading: questionDatas.Heading,
      // visible: true,
      CourseId: questionDatas.CourseId,
      // ProgramId: questionDatas.ProgramId,
      // ResourceId: questionDatas.ResourceId,
      // ResourceTypeId: questionDatas.ResourceTypeId,
      ResourceTypeName: questionDatas.ResourceTypeName,
      createdAt: timestamp,
      // QuestionNo: qno,
      IndexNo: ino
    }),
    this.afs.collection("userquestions").doc(id).update(usersUpdate)
  ])
  }
  addinterviewQuestionForusers(questionDatas: TAResources,userid:string, question: string, id: string, qno: number, ino: number) {

    const timestamp = this.timestamp;
    return this.db.partialUpdate("userquestions/" +userid+"/que/"+ id, {
      Question: question,
      id: id,
      HeadingId: questionDatas.id,
      Heading: questionDatas.Heading,
      visible: true,
      CourseId: questionDatas.CourseId,
      // ProgramId: questionDatas.ProgramId,
      ResourceId: questionDatas.ResourceId,
      ResourceTypeId: questionDatas.ResourceTypeId,
      ResourceTypeName: questionDatas.ResourceTypeName,
      createdAt: timestamp,
      // QuestionNo: qno,
      IndexNo: ino
    })
  }
  addAssignmentForUsers(questionDatas: TAResources, question: string, id: string, qno: number, ino: number) {
  console.log('creat new assignment');
  
    return this.db.partialUpdate("assignment/" + id, {
      Assignment: question,
      id: id,
      HeadingId: questionDatas.id,
      Heading: questionDatas.Heading,
      CourseId: questionDatas.CourseId,
      // ProgramId: questionDatas.ProgramId,
      ResourceId: questionDatas.ResourceId,
      ResourceTypeId: questionDatas.ResourceTypeId,
      ResourceTypeName: questionDatas.ResourceTypeName,
      AssignmentNo: qno,
      IndexNo: ino
    })
  }
  addinterviewQuestions(questionDatas: TAResources, question: string, type: any, ans: any, id: string, qno: number) {

    return this.db.partialUpdate("interviewquestions/" + id, {
      Question: question,
      Type: type.name,
      Options: ans,
      id: id,
      Heading: questionDatas.Heading,
      CourseId: questionDatas.CourseId,
      ProgramId: questionDatas.ProgramId,
      ResourceId: questionDatas.ResourceId,
      ResourceTypeId: questionDatas.ResourceTypeId,
      ResourceTypeName: questionDatas.ResourceTypeName,
      // QuestionNo: qno
    })

  }
  addInterviewQuestions(questionDatas: TAResources, question: string, type: any, ans: any, id: string, qno: number) {

    return this.db.partialUpdate("interviewquestions/" + id, {
      Question: question,
      Type: type.name,
      Options: ans,
      id: id,
      Heading: questionDatas.Heading,
      CourseId: questionDatas.CourseId,
      ProgramId: questionDatas.ProgramId,
      ResourceId: questionDatas.ResourceId,
      ResourceTypeId: questionDatas.ResourceTypeId,
      ResourceTypeName: questionDatas.ResourceTypeName,
      // QuestionNo: qno
    })

  }
  addQuestions(questionDatas: TAResources, question: string, type: any, ans: any, id: string, qno: number) {
    if (type.name == 'Radio Button') {
      return this.db.partialUpdate("questions/" + id, {
        Question: question,
        Type: type.name,
        Options: ans,
        id: id,
        Heading: questionDatas.Heading,
        CourseId: questionDatas.CourseId,
        ProgramId: questionDatas.ProgramId,
        ResourceId: questionDatas.ResourceId,
        ResourceTypeId: questionDatas.ResourceTypeId,
        ResourceTypeName: questionDatas.ResourceTypeName,
        // QuestionNo: qno
      })
    } else if (type.name == 'Paragraph') {
      return this.db.partialUpdate("questions/" + id, {
        Question: question,
        Type: type.name,
        Options: ans ? ans : null,
        id: id,
        Heading: questionDatas.Heading,
        CourseId: questionDatas.CourseId,
        ProgramId: questionDatas.ProgramId,
        ResourceId: questionDatas.ResourceId,
        ResourceTypeId: questionDatas.ResourceTypeId,
        ResourceTypeName: questionDatas.ResourceTypeName,
        // QuestionNo: qno
      })
    }
  }
  addAnsQuestions(field: any, user: TAUser, selectedans: any, heading: string) {
    return this.db.partialUpdate("questions" + "/questionlists/" + heading + "/" + heading + "/Users/" + user.UserId + "/" + heading + "/" + field.id, {
      ans: field.ans,
      question: field.question,
      type: field.type,
      selectedans: selectedans,
      UserId: user.UserId,
      UserName: user.UserName
    })
  }
  getUserAnswer(heading: string) {
    return this.db.colWithIds$("questions" + "/questionlists/" + heading + "/" + heading + "/Users/")
  }
  getUserQuestions<T>(colName: string, courseId: number, programId: number): Observable<T[]> {
    return this.db.colWithIds$(colName, res => res.where("CourseId", "==", courseId).where("ProgramId", "==", programId).where("ParentResource", "==", null).orderBy("Heading"))
  }
  getHeading<T>(colName: string, courseid: number, programid: number): Observable<T[]> {
    return this.db.colWithIds$(colName, res => res.where("CourseId", "==", courseid).where("ProgramId", "==", programid).where("ResourceTypeName", "==", "Question"));
  }
  getHeadingList<T>(colName: string, courseid: number): Observable<T[]> {
    return this.db.colWithIds$(colName, res => res.where("CourseId", "==", courseid).where("ResourceTypeName", "==", "Question"));
  }
  getQuestions<T>(colName: string, courseid: number, heading: string): Observable<T[]> {
    return this.db.colWithIds$(colName, res => res.where("CourseId", "==", courseid).where("Heading", "==", heading).where("ResourceTypeName", "==", "Question"));
  }
  deleteQuestion(questions: TAResources) {
    console.log('delete assignment question from questions col');
    return this.db.delete("questions/" + questions.id)
  }
  deleteinterviewQuestion(questions: TAResources) {
    return this.db.delete("interviewquestions/" + questions.id)
  }
  deleteinterviewQuestionIP(questions: TAResources) {
    return this.db.delete("userquestions/" + questions.id)
  }
  getQuestionsList<T>(colName: string): Observable<T[]> {
    return this.db.colWithIds$(colName, res => res.orderBy("updatedAt"));
  }
  getcourse(element: TAUser): Observable<any[]> {
    return this.db.colWithIds$("studentmanagement", res => res.where("UserId", "==", element.UserId))
  }
  getCandidateByDate(startdate: Date, enddate: Date) {
    if (startdate && enddate)
      return this.db.colWithIds$("studentmanagement", res => res
        .where("createdAt", ">=", new Date(startdate.setHours(0, 0, 0, 0)))
        .where("createdAt", "<=", new Date(enddate.setHours(23, 59, 59, 999)))
        //  .orderBy("UserId")
      )
  }
  profilephotoUpdate(resource: TAResources, photourl: any) {
    return this.db.partialUpdate("assignment/" + resource.id,{ downloadUrl: photourl });
  }
  AudioUpdate(question:TAResources, url: any,user:string) {
    // console.log(question);
    
    return this.db.partialUpdate("userquestions/"+ question.id,{ AudioUrl: url });
  }
  InterviewAudioUpdate(question:TAResources, url: any) {
    // console.log(question);
    
    return this.db.partialUpdate("interviewquestions/" + question.id,{ AudioUrl: url });
  }
  PredefinedInterviewAudioUpdate(question:TAResources, url: any,heading:string) {
    // console.log(question);
    
    return this.db.partialUpdate("InterviewQuestionList/"+heading+"/questions/" + question.id,{ AudioUrl: url });
  }
  profilephotoUpdatebyCandidate(user: TAUser, resource: TAResources, photourl: any, path: string, status: string) {
   console.log('upload student assignment answer image ');
   
    this.db.partialUpdate("AssignmentAnswer/" + user.UserId,{});
    return this.db.partialUpdate(
      "AssignmentAnswer/" + user.UserId + "/userans/" + resource.id,
      { downloadUrl: photourl, Assignment: resource.Assignment, CourseId: resource.CourseId, Heading: resource.Heading, Assignmentid: resource.id, Assignmentstatus: status, Path: path }
    );
  }
  getCourse<T>(colName: string, coursename: string): Observable<T[]> {
    console.log('get selected course data');
    
    return this.db.colWithIds$(colName, res => res.where("CourseName", "==", coursename));
  }
  getCourseInvite<T>(colName: string, coursename: any): Observable<T[]> {
    console.log('ssssssssssssssss');
    
    let date=new Date()
    let date1=new Date()
    console.log(date);
    let duedate=new Date()
    let a= new Date(date.setHours( date.getHours() +4 ))
    let b=new Date(date1.setMinutes( date1.getMinutes() - 4 ))
    console.log(a);
    // console.log(24>date);
    // if(12<=date&&24>=date){
    return this.db.colWithIds$(colName+"/"+coursename.id+"/webinar_sessions", res => res.orderBy("starttime","desc")
    .where("starttime", "<=", a)
    .where("starttime", ">=", b)
    );
    
   
//   } else { return this.db.colWithIds$(colName+"/"+coursename.id+"/invite", res => res.orderBy("CreatedAt","desc"))
// }
}
  getQuestionResource<T>(colName: string, courseId: number): Observable<T[]> {
    console.log("getInterviewQuestionResource","+",'get Question data list from resource');
    return this.db.colWithIds$(colName, res => res.where("CourseId", "==", courseId).where("ResourceTypeName", "==", "Question").orderBy("updatedAt","desc"));
  }
  addinvite(selectedCourseData,s,t,l,vtime){
 
    let duedate=t
    let a= new Date(duedate.setHours( duedate.getHours() -12 ))
    let date=new Date(duedate.setMinutes( duedate.getMinutes() - 30 ))
    console.log(date);
    return this.db.add(
      "courses/" + selectedCourseData.id + "/invite", 
      { session: s, time: date, link: l,visibletime:vtime }
    );
  }
  getInterviewQuestionResource<T>(colName: string, courseId: number): Observable<T[]> {
    console.log("getInterviewQuestionResource","+",'get InterviewQuestion data list from resource');
    return this.db.colWithIds$(colName, res => res.where("CourseId", "==", courseId).where("ResourceTypeName", "==", "InterviewQuestion").orderBy("updatedAt","desc"));
  }
  getInterviewQuestionResourceuser<T>(colName: string,userid: string, courseId: number): Observable<T[]> {
    // console.log(colName,userid,courseId);
    
    return this.db.colWithIds$(colName, res => res.where("CourseId", "==", courseId).orderBy("CreatedAt"));
  }
  getAssignmentResource<T>(colName: string, courseId: number): Observable<T[]> {
    console.log("getAssignmentResource","+",'get assignment data list from resource');
    
    return this.db.colWithIds$(colName, res => res.where("CourseId", "==", courseId).where("ResourceTypeName", "==", "Assignment").orderBy("updatedAt","desc"));
  }
  updateAssignmentQue(e: TAResources, Assign: string) {
    console.log('update assignment que ');
    
    this.db.partialUpdate("assignment/" + e.id, { Question: e.Question ? e.Question : null, Assignment: Assign })
  }
  getQuestionbyHeading(colName: string, heading: string): Observable<any[]> {
    console.log("getQuestionbyHeading","=",'get question based on  heading  in que col for admin');
    return this.db.colWithIds$(colName, res => res.where("HeadingId", "==", heading).orderBy("IndexNo","desc"));
  }
  getQuestionbyHeadinguser(colName: string,userid:string, heading: string): Observable<any[]> {
    return this.db.colWithIds$(colName, res => res.where("HeadingId", "==", heading).orderBy("IndexNo","desc"));
  }
  getQuestionbyHeadingForStudent<T>(colName: string, heading: string): Observable<T[]> {
    console.log("getQuestionbyHeadingForStudent","+",'get question based on  heading on question col  which visible true  sor student');
    
    return this.db.colWithIds$(colName, res => res.where("HeadingId", "==", heading).where("visible", "==", true).orderBy("IndexNo","desc"));
  }
  getQuestionbyHeadinginterview(colName: string, heading: string): Observable<any[]> {
   
    return this.db.colWithIds$(colName, res => res.where("HeadingId", "==", heading).orderBy("IndexNo","desc"));
  }
  getQuestionbyHeadinginterviewuser(colName: string,user:string, heading: string): Observable<any[]> {
    return this.db.colWithIds$(colName, res => res.where("HeadingId", "==", heading).orderBy("IndexNo","desc"));
  }
  getQuestionbyHeadingForStudentinterview<T>(colName: string,user:string, heading: string): Observable<T[]> {
   let a="dgowda3@gmail_com"
    
    return this.db.colWithIds$(colName, res => res.where("HeadingId", "==", heading).where(a, "==", true).orderBy("IndexNo","desc"));
  }
  getQuestionbyHeadingForStudentinterviewuser<T>(colName: string,user:string, heading: string): Observable<T[]> {
    let a="dgowda3@gmail_com"
    return this.db.colWithIds$(colName, res => res.where("HeadingId", "==", heading).orderBy("IndexNo","desc"));
  
    //  return this.db.colWithIds$(colName+"/"+user+"/"+"que", res => res.where("HeadingId", "==", heading).orderBy("IndexNo","desc"));
   }
  getAssignmentbyHeading<T>(colName: string, heading: string): Observable<T[]> {
    console.log("getAssignmentbyHeading","+",'get assignment data from resurces');
    
    return this.db.colWithIds$(colName, res => res.where("HeadingId", "==", heading).orderBy("AssignmentNo"));
  }
  deleterecore(que:TAResources){
   return this.db.partialUpdate("questions/"+que.id,{AudioUrl:null})
  }
  deleteInterviewAudio(que:TAResources){
    return this.db.partialUpdate("interviewquestions/"+que.id,{AudioUrl:null})
   }
   deletepredefineInterviewAudio(que:TAResources,heading:string){
    return this.db.partialUpdate("InterviewQuestionList/"+heading+"/questions/"+que.id,{AudioUrl:null})
   }
  addAnswer(user: TAUser, answerDetail: any, selectedAns: string) {
    this.db.partialUpdate("answer/" + user.UserId, {
      UserId: user.UserId,
      UserName: user.UserName,
    })
    return this.db.partialUpdate("answer/" + user.UserId + "/userans/" + answerDetail.id, {
      UserId: user.UserId,
      UserName: user.UserName,
      CourseId: answerDetail.CourseId,
      Heading: answerDetail.Heading,
      // ProgramId: answerDetail.ProgramId,
      Question: answerDetail.Question,
      ResourceId: answerDetail.ResourceId,
      ResourceTypeId: answerDetail.ResourceTypeId,
      ResourceTypeName: answerDetail.ResourceTypeName,
      SelectedAnswer: selectedAns,
      // QuestionNo: answerDetail.QuestionNo
    })
  }
  adduserAns(user: TAUser, answerDetail: any, selectedAns: string) {
    console.log('add user answer for question');
    
    // this.db.partialUpdate("questionandanswer/" + answerDetail.id + "/user/" + user.UserId, {
    //   UserId: user.UserId,
    //   UserName: user.UserName,
    //   CourseId: answerDetail.CourseId,
    //   Heading: answerDetail.Heading,
    //   Options: answerDetail.Options,
    //   ProgramId: answerDetail.ProgramId,
    //   Question: answerDetail.Question,
    //   ResourceId: answerDetail.ResourceId,
    //   ResourceTypeId: answerDetail.ResourceTypeId,
    //   ResourceTypeName: answerDetail.ResourceTypeName,
    //   Type: answerDetail.Type,
    //   SelectedAnswer: selectedAns,
    //   // QuestionNo: answerDetail.QuestionNo
    // });
    this.db.partialUpdate("answer/" + user.UserId, {
      UserId: user.UserId,
      UserName: user.UserName
    })
    return this.db.partialUpdate("answer/" + user.UserId + "/userans/" + answerDetail.id, {
      UserId: user.UserId,
      // UserName: user.UserName,
      // CourseId: answerDetail.CourseId,
      // Heading: answerDetail.Heading,
      // Options: answerDetail.Options,
      // ProgramId: answerDetail.ProgramId,
      // Question: answerDetail.Question,
      // ResourceId: answerDetail.ResourceId,
      // ResourceTypeId: answerDetail.ResourceTypeId,
      // ResourceTypeName: answerDetail.ResourceTypeName,
      // Type: answerDetail.Type,
      SelectedAnswer: selectedAns,
      // QuestionNo: answerDetail.QuestionNo
    })
  }
  updatevisiblestatususer(colname: string,userid:string, val: any, que: TAResources,checked:boolean) {
    // console.log(val);
    
    this.db.partialUpdate(colname +  que.id, {val, "visible":checked}  )
  }
  updatevisiblestatus(colname: string, val: boolean, que: TAResources) {
    this.db.partialUpdate(colname + "/" + que.id, { visible: val })
  }
  adduserinterviewAns(user: TAUser, answerDetail: any, selectedAns: string) {
    // this.db.partialUpdate("questionandanswer/" + answerDetail.id + "/user/" + user.UserId, {
    //   UserId: user.UserId,
    //   UserName: user.UserName,
    //   CourseId: answerDetail.CourseId,
    //   Heading: answerDetail.Heading,
    //   Options: answerDetail.Options,
    //   ProgramId: answerDetail.ProgramId,
    //   Question: answerDetail.Question,
    //   ResourceId: answerDetail.ResourceId,
    //   ResourceTypeId: answerDetail.ResourceTypeId,
    //   ResourceTypeName: answerDetail.ResourceTypeName,
    //   Type: answerDetail.Type,
    //   SelectedAnswer: selectedAns,
    //   QuestionNo: answerDetail.QuestionNo
    // });
    this.db.partialUpdate("interviewanswer/" + user.UserId, {
      UserId: user.UserId,
      UserName: user.UserName
    })
    return this.db.partialUpdate("interviewanswer/" + user.UserId + "/userans/" + answerDetail.id, {
      UserId: user.UserId,
      // UserName: user.UserName,
      // CourseId: answerDetail.CourseId,
      // Heading: answerDetail.Heading,
      // ProgramId: answerDetail.ProgramId,
      // Question: answerDetail.Question,
      // ResourceId: answerDetail.ResourceId,
      // ResourceTypeId: answerDetail.ResourceTypeId,
      // ResourceTypeName: answerDetail.ResourceTypeName,
      SelectedAnswer: selectedAns
      // QuestionNo: answerDetail.QuestionNo
    })
  }
  getUser() {
    return this.db.colWithIds$("answer");
  }
  getIntervHeading(colname: string) {
    console.log('get question heading for dropdown');
    
    return this.db.colWithIds$(colname);
  }
  getheadingQueList<T>(colname: string, heasing: string) {
    console.log('get heading list for question , iq, ass');
    
    return this.db.colWithIds$(colname + "/" + heasing + "/questions");
  }
  getUserAnswers(user: TAUser, Que: TAResources) {
    console.log("getUserAnswers","+",'get user assignment answer');
    
    return this.db.doc$("answer/" + user.UserId + "/userans/" + Que.id)
  }
  getUserinterviewAnswers(user: TAUser, Que: TAResources): Observable<any> {
    return this.db.doc$("interviewanswer/" + user.UserId + "/userans/" + Que.id)
  }
  getuserAns(user: TAUser, courseid: number) {
    return this.db.colWithIds$("answer/" + user.UserId + "/userans/", res => res.where("CourseId", "==", courseid))
  }
  getuserHeadingQuestions(user: TAUser, field: any) {
    return this.db.colWithIds$("answer/" + user.UserId + "/userans/", res => res.where("Heading", "==", field.Heading).where("CourseId", "==", field.CourseId))
  }
  getuserbyquestions(field: any) {
    return this.db.colWithIds$("questionandanswer/" + field.id + "/user/", res => res.where("CourseId", "==", field.CourseId).where("Heading", "==", field.Heading).where("Question", "==", field.Question))
  }
  getColl() {
    return this.db.colWithIds$("courses")
 
    // return this.http.get<any>('../../assets/data/cars-large.json')
    //   .toPromise()
    //   .then(res => <any[]>res.data)
    //   .then(data => data);
  }
  getCourseQuestions(courseid: number, heading: string) {
    console.log("getCourseQuestions","+",'get question list based on heading for question dashboard');
    
    return this.db.colWithIds$("questions/", res => res.where("CourseId", "==", courseid).where("HeadingId", "==", heading).orderBy("IndexNo","desc"))
  }
  getCourseinterviewQuestions(courseid: number, heading: string) {
    return this.db.colWithIds$("interviewquestions/", res => res.where("CourseId", "==", courseid).where("HeadingId", "==", heading).where("visible", "==", true).orderBy("IndexNo","desc"))
  }
  getAllCourseQuestions(courseid: number) {
    console.log('get all questionlist by selected course in question col ');
    
    return this.db.colWithIds$("questions/", res => res.where("CourseId", "==", courseid).where("visible", "==", true).orderBy("IndexNo","desc"))
  }
  getAllCourseInterviewQuestions(courseid: number) {
    console.log('get all questionlist by selected course in interviewquestions col ');
    return this.db.colWithIds$("interviewquestions/", res => res.where("CourseId", "==", courseid).where("visible", "==", true).orderBy("IndexNo","desc"))
  }
  getCourseAssignment(courseid: number, heading: string) {
    console.log(' get assignment heading list');
    
    return this.db.colWithIds$("assignment/", res => res.where("CourseId", "==", courseid).where("HeadingId", "==", heading).orderBy("AssignmentNo"))
  }
  getdd<T>(colName: string): Observable<T[]> {
    return this.db.colWithIds$(colName);
  }
  getUserAnswerDATA(userId: string, questionId: string): Observable<any> {
    console.log("getUserAnswerDATA","+,",'get candidate answer for question dashboard ');
    
    return this.db.doc$("answer/" + userId + "/userans/" + questionId)
  }
  getUserinterviewAnswerDATA(userId: string, questionId: string): Observable<any> {
    console.log('get candidate answer for interviewquestion dashboard ');
    return this.db.doc$("interviewanswer/" + userId + "/userans/" + questionId)
  }
  getUserAssignAnswertotal(userId: string, courseid: number,heading:string,val:string): Observable<any> {
    console.log("getUserAssignAnswertotal","+",'  assignment dashboard total calculation for Completed, Completed with Bugs, INComplete');
    
    return this.db.colWithIds$("AssignmentAnswer/" + userId + "/userans/",res=>res.where("CourseId", "==", courseid).where("Heading", "==", heading).where("Assignmentstatus", "==", val))
  }
  getUserAssignAnswer(userId: string, questionId: string): Observable<any> {
    console.log('get student assignment answer for dashboard');
    
    return this.db.doc$("AssignmentAnswer/" + userId + "/userans/" + questionId)
  }
  getCourseQuestionsValue(courseid: number, heading: string) {
    return this.db.colWithIds$("questions/", res => res.where("CourseId", "==", courseid).where("Heading", "==", heading))
  }
  getCourseinterviewQuestionsValue(courseid: number, heading: string) {
    return this.db.colWithIds$("interviewquestions/", res => res.where("CourseId", "==", courseid).where("Heading", "==", heading))
  }
  getCourseAssignmentValue(courseid: number, heading: string) {
    console.log(' get sort by assignment no from assignment col');
    
    return this.db.colWithIds$("assignment/", res => res.where("CourseId", "==", courseid).where("Heading", "==", heading).orderBy("AssignmentNo", "desc"))
  }
  getCourseQuestionsIndexValue(courseid: number, heading: string) {
    console.log(' get sort by index no from questions col');
    return this.db.colWithIds$("questions/", res => res.where("CourseId", "==", courseid).where("Heading", "==", heading).orderBy("IndexNo", "desc"))
  }
  getCourseAssignmentIndexValue(courseid: number, heading: string) {
    return this.db.colWithIds$("assignment/", res => res.where("CourseId", "==", courseid).where("Heading", "==", heading).orderBy("IndexNo", "desc"))
  }
  addUserAnsParagraph(user: TAUser, answerDetail: any, ans: string) {
    this.db.partialUpdate("answer/" + user.UserId, {
      UserId: user.UserId,
      UserName: user.UserName
    })
    return this.db.partialUpdate("answer/" + user.UserId + "/userans/" + answerDetail.id, {
      UserId: user.UserId,
      UserName: user.UserName,
      CourseId: answerDetail.CourseId,
      Heading: answerDetail.Heading,
      Options: answerDetail.Options,
      ProgramId: answerDetail.ProgramId,
      Question: answerDetail.Question,
      ResourceId: answerDetail.ResourceId,
      ResourceTypeId: answerDetail.ResourceTypeId,
      ResourceTypeName: answerDetail.ResourceTypeName,
      Type: answerDetail.Type,
      SelectedAnswer: ans,
      // QuestionNo: answerDetail.QuestionNo
    })
  }
  async updateQuestions(questionDatas: any, ans: string, user: TAUser) {
    return this.db.existsDataUpdate("answer/" + user.UserId + "/userans/" + questionDatas.id, {
      Question: ans,
      id: questionDatas.id,
      Heading: questionDatas.Heading,
      CourseId: questionDatas.CourseId,
      // ProgramId: questionDatas.ProgramId,
      ResourceId: questionDatas.ResourceId,
      ResourceTypeId: questionDatas.ResourceTypeId,
      ResourceTypeName: questionDatas.ResourceTypeName,
      // QuestionNo: questionDatas.QuestionNo
    })
  }
  async updateInterviewQuestionsuser(questionDatas: any,userid:string, ans: string, user: TAUser) {
    return this.db.existsDataUpdate("userquestions/" +  questionDatas.id, {
      Question: ans,
      id: questionDatas.id,
      Heading: questionDatas.Heading,
      CourseId: questionDatas.CourseId,
      // ProgramId: questionDatas.ProgramId,
      // ResourceId: questionDatas.ResourceId,
      // ResourceTypeId: questionDatas.ResourceTypeId,
      ResourceTypeName: questionDatas.ResourceTypeName,
      // QuestionNo: questionDatas.QuestionNo
    })
  }
  async updateInterviewQuestions(questionDatas: any, ans: string, user: TAUser) {
    return this.db.existsDataUpdate("interviewanswer/" + user.UserId + "/userans/" + questionDatas.id, {
      Question: ans,
      id: questionDatas.id,
      Heading: questionDatas.Heading,
      CourseId: questionDatas.CourseId,
      // ProgramId: questionDatas.ProgramId,
      ResourceId: questionDatas.ResourceId,
      ResourceTypeId: questionDatas.ResourceTypeId,
      ResourceTypeName: questionDatas.ResourceTypeName,
      // QuestionNo: questionDatas.QuestionNo
    })
  }
  async updateHeadings(questionDatas: any, user: TAUser, ans: string) {
    return this.db.existsDataUpdate("answer/" + user.UserId + "/userans/" + questionDatas.id, {
      id: questionDatas.id,
      Heading: ans,
      CourseId: questionDatas.CourseId,
      ResourceId: questionDatas.ResourceId,
      ResourceTypeId: questionDatas.ResourceTypeId,
      ResourceTypeName: questionDatas.ResourceTypeName,
      // QuestionNo: questionDatas.QuestionNo
    })
  }
  questionUpdate(questionDatas: any, ans: string) {
    return this.db.partialUpdate("questions/" + questionDatas.id, {
      Question: ans,
      id: questionDatas.id,
      Heading: questionDatas.Heading,
      CourseId: questionDatas.CourseId,
      // ProgramId: questionDatas.ProgramId,
      ResourceId: questionDatas.ResourceId,
      ResourceTypeId: questionDatas.ResourceTypeId,
      ResourceTypeName: questionDatas.ResourceTypeName,
      // QuestionNo: questionDatas.QuestionNo
    })
  }
  interviewquestionUpdate(questionDatas: any, ans: string) {
    return this.db.partialUpdate("interviewquestions/" + questionDatas.id, {
      Question: ans,
      id: questionDatas.id,
      Heading: questionDatas.Heading,
      CourseId: questionDatas.CourseId,
      // ProgramId: questionDatas.ProgramId,
      ResourceId: questionDatas.ResourceId,
      ResourceTypeId: questionDatas.ResourceTypeId,
      ResourceTypeName: questionDatas.ResourceTypeName,
      // QuestionNo: questionDatas.QuestionNo
    })
  }
  async QuestionColHeadingUpdate(questionDatas: any, ans: string) {
    await this.db.partialUpdate("questions/" + questionDatas.id, {
      id: questionDatas.id,
      Heading: ans,
      CourseId: questionDatas.CourseId,
      ResourceId: questionDatas.ResourceId,
      ResourceTypeId: questionDatas.ResourceTypeId,
      ResourceTypeName: questionDatas.ResourceTypeName,
      // QuestionNo: questionDatas.QuestionNo
    })
  }
  async InterviewQuestionColHeadingUpdate(questionDatas: any, ans: string) {
    await this.db.partialUpdate("interviewquestions/" + questionDatas.id, {
      id: questionDatas.id,
      Heading: ans,
      CourseId: questionDatas.CourseId,
      ResourceId: questionDatas.ResourceId,
      ResourceTypeId: questionDatas.ResourceTypeId,
      ResourceTypeName: questionDatas.ResourceTypeName,
      // QuestionNo: questionDatas.QuestionNo
    })
  }
  async InterviewQuestionColHeadingUpdateIP(questionDatas: any, ans: string) {
    await this.db.partialUpdate("interviewquestions/" + questionDatas.id, {
      id: questionDatas.id,
      Heading: ans,
      CourseId: questionDatas.CourseId,
      ResourceId: questionDatas.ResourceId,
      ResourceTypeId: questionDatas.ResourceTypeId,
      ResourceTypeName: questionDatas.ResourceTypeName,
      // QuestionNo: questionDatas.QuestionNo
    })
  }
  getUserCourseId(element: TAStudentManagement): Observable<any[]> {
    console.log('get course id to update assignment heading');
    
    return this.db.colWithIds$("resources", res => res.where("CourseId", "==", element.CourseId));
  }
  
  getUserCourseIdIP(element: TAStudentManagement): Observable<any[]> {
    return this.db.colWithIds$("usertopic", res => res.where("CourseId", "==", element.CourseId));
  }
  deleteQuestions(questionDatas: any, user: TAUser) {
    console.log('delete assignment  from answer col');
    
    return this.db.delete("answer/" + user.UserId + "/userans/" + questionDatas.id);
  }
  deleteinterviewQuestions(questionDatas: any, user: TAUser) {
    return this.db.delete("interviewanswer/" + user.UserId + "/userans/" + questionDatas.id);
  }
  deleteinterviewQuestionsuser(questionDatas: any,userid:string, user: TAUser) {
    return this.db.delete("userquestions/" +  questionDatas.id);
  }
  deleteinterviewQuestionsanswer(questionDatas: any, user: TAUser) {
    return this.db.delete("interviewanswer/" + user.UserId + "/userans/" + questionDatas.id);
  }
  deleteQuestionHeading(questionDatas: any) {
    console.log('delete assignment question from resource');
    return this.db.delete("resources/" + questionDatas.id);
  }
  deleteInterviewQuestionHeading(questionDatas: any) {
    return this.db.delete("resources/" + questionDatas.id);
  }
  deleteInterviewQuestionHeadingIP(questionDatas: any) {
    return this.db.delete("usertopic/" + questionDatas.id);
  }
  updateHeadingresources(headingDatas: any, ans: string) {
    return this.db.partialUpdate("resources/" + headingDatas.id, {
      Heading: ans
    })
  }
  updateHeadingresourcesip(headingDatas: any, ans: string) {
    return this.db.partialUpdate("usertopic/" + headingDatas.id, {
      Heading: ans
    })
  }
  updateInterviewHeadingresources(headingDatas: any, ans: string) {
    return this.db.partialUpdate("resources/" + headingDatas.id, {
      Heading: ans
    })
  }
  updateProgramresources(programDatas: any, ans: string) {
    return this.db.partialUpdate("programs/" + programDatas.id, {
      ProgramName: ans
    })
  }
  getAllAdmins() {
    console.log('getAllAdmins',"+","get all admin list");
    
    return this.db.colWithIds$("candidate", res => res.where("RoleName", "==", "Admin"));
  }
  getAssignmentAnswers(id: string, user: TAUser) {
    console.log("getAssignmentAnswers","+",'get student assignment answer from AssignmentAnswer col');
    
    return this.db.colWithIds$("AssignmentAnswer/" + user.UserId + "/userans", res => res.where("Assignmentid", "==", id))
  }
  editAssignmentHeadings(assData: any, ans: string) {
    return this.db.partialUpdate("assignment/" + assData.id, {
      Assignment: ans,
      AssignmentNo: assData.AssignmentNo,
      CourseId: assData.CourseId,
      Heading: assData.Heading,
      IndexNo: assData.IndexNo,
      Question: assData.Question ? assData.Question : '',
      ResourceId: assData.ResourceId,
      ResourceTypeId: assData.ResourceTypeId,
      ResourceTypeName: assData.ResourceTypeName,
      id: assData.id
    })
  }
  editAssignmentHeadingsUserAns(assData: any, ans: string, userid: string) {
    console.log('update assignment heading');
    
    return this.db.existsDataUpdate("AssignmentAnswer/" + userid + "/userans/" + assData.id, {
      Assignment: ans,
      Assignmentid: assData.id
    })
  }
  editAssignmentResHeading(assData: any, ans: string) {
    return this.db.partialUpdate("assignment/" + assData.id, {
      Heading: ans,
      id: assData.id
    })
  }
  deleteAssignmentHeadingsUserAns(assData: any, user: TAUser) {
    console.log("delete assignment answer");
    
    return this.db.delete("AssignmentAnswer/" + user.UserId + "/userans/" + assData.id);
  }
  deleteAssignmentHeadings(assData: any) {
    return this.db.delete("assignment/" + assData.id)
  }
  deleteAssignmentAns(user: TAUser, Assignment: TAResources) {
    return this.db.delete("AssignmentAnswer/" + user.UserId + "/userans/" + Assignment.id)
  }
  getAdminLists() {
    console.log('getAdminLists',"+","get admin user list");
    
    return this.db.colWithIds$("adminusers");
  }
  getCourseNameByCourseId(courseId: number) {
    return this.db.colWithIds$("courses", res => res.where("CourseId", "==", courseId))
  }
  getProgramNameByProgramId(programId: number) {
    return this.db.colWithIds$("programs", res => res.where("ProgramId", "==", programId))
  }
  getCarsMedium() {
    return this.http.get<any>('assets/data/cars-medium.json')
      .toPromise()
      .then(res => <any[]>res.data)
      .then(data => data);
  }
  getInterviewQuestionList(colname: string, orderByField: string) {
    console.log('get  questionlist based on index no in question col');
    
    return this.db.colWithIds$(colname, res => res.orderBy(orderByField, "desc"));
  }
  getInterviewQuestionListuser(colname: string,userid:string, orderByField: string) {
    return this.db.colWithIds$(colname, res => res.orderBy(orderByField, "desc"));
  }
  getAssignmentuserlist(userid:string){
    return this.db.colWithIds$("answer" + "/" + userid + "/userans");
  }
  getpredefinedQuestionList(){
    return this.db.colWithIds$("QuestionList");
  }
  getpredefinedInterviewQuestionList(){
    return this.db.colWithIds$("InterviewQuestionList");
  }
  getselectedpredefinedquestions(headingid: string){
    return this.db.colWithIds$("QuestionList/" + headingid + "/questions");
  }
  getselectedpredefinedInterviewquestions(headingid: string,){
    return this.db.colWithIds$("InterviewQuestionList/" + headingid + "/questions");
  }
  updatePredefinedques(headingid: string,question:string,id:string){
    return this.db.partialUpdate("QuestionList/" + headingid + "/questions/" + id,{
    Question:question
    })
  }
  updatePredefinedInterviewques(headingid: string,question:string,id:string){
    return this.db.partialUpdate("InterviewQuestionList/" + headingid + "/questions/" + id, {
    Question:question
    })
  }
  deletePredefinedques(headingid: string,id:string){
    return this.db.delete("QuestionList/" + headingid + "/questions/" + id);
  }
  deletePredefinedInterviewques(headingid: string,id:string){
    return this.db.delete("InterviewQuestionList/" + headingid + "/questions/" + id);
  }
  addpredefinedQuestion(headingid: string,id:string,question:string){
    return this.db.partialUpdate("QuestionList/" + headingid + "/questions/" + id, {
      Question:question
    })
  }
  addpredefinedInterviewQuestion(headingid: string,id:string,question:string){
    return this.db.partialUpdate("InterviewQuestionList/" + headingid + "/questions/" + id, {
      Question:question
    })
  }

  deletepredefinedHeading(headingid: string){
    return this.db.delete("QuestionList/" + headingid)
  }
  deleteInterviewpredefinedHeading(headingid: string){
    return this.db.delete("InterviewQuestionList/" + headingid);
  }
  addpredefinedHeading(heading:string){
    return this.db.partialUpdate("QuestionList/" + heading ,{
      name:heading
    });
  }
  addpredefineInterviewdHeading(heading:string){
    return this.db.partialUpdate("InterviewQuestionList/" + heading ,{
      name:heading
    });
  }
  getAudiosuserstrue(id:string): Observable<any>{
    console.log('getAudiosuserstrue');
    
    return this.db.doc$("resources/" + id )
  }
  getAudioAccessusers(id:string){
    console.log("getAudioAccessusers","+",'get users having audio access');
    
    return this.db.colWithIds$("resources/" + id + "/audio/")
  }
  addAudiousers(id:string,data:any){
    this.db.partialUpdate("resources/" + id + "/audio/"+data,{})
    return this.db.partialUpdate("resources/" + id + "/audio/"+data,{UserId:data}
      )
  }
  deleteAudiousers(id:string,data:any){
    return this.db.delete("resources/" + id + "/audio/"+data)
  }
  addInterviewPreperation(e,heading){
    return this.db.partialUpdate("usertopic/" + heading.id,{
      interviewpreparationaudioaccess: e 
    })
  }
    addIPheadingaccess(e,heading,userid){
      var usersUpdate = {};
      var b=userid
      var key=b.replace(/\./g,"_");
      usersUpdate[`${key}`] = e;
     
      this.afs.collection("usertopic").doc(heading.id).update(usersUpdate)
  }

  getHeadingId<T>(headingid): Observable<T> {
    return this.db.doc$("resources/" + headingid);
  }
  addwebinar(webinarid:string,account:webacc,heading:string,course:TACourse,timezone:string,str1:any,str2:any,val1:string,date:Date,enddte:Date){
return this.db.add("courses/"+course.id+"/webinar_sessions/",{
  course_name:course.CourseName,
  course_id:course.id,
  end:str2,
  start:str1,
session_type:val1,
  webinar_account:account.email,
  webinar_id:webinarid,
  name:heading,
  attendance_tracked:false,
  starttime:date,
  endtime:enddte
})
  }

  addexistingwebinar(webinarid:string,account:ColDD,heading:string,course:TACourse,str1:Date,str2:Date,val1:string){
    return this.db.add("courses/"+course.id+"/webinar_sessions/",{
      course_name:course.CourseName,
      course_id:course.id,
      end:str2,
      start:str1,
      session_type:val1,
      webinar_account:account.email,
      webinar_id:webinarid,
      name:heading,
      attendance_tracked:false
    })
      }
    getwebinarlist<T>(courseid): Observable<T[]> {
    console.log(courseid);
    
    return this.db.colWithIds$("courses/"+courseid+"/webinar_sessions");
  }
  getfuturewebinarlist<T>(courseid): Observable<T[]> {
    console.log(courseid);
     let e=new Date()
    let e1=new Date(e.setHours(e.getHours() - 12));
    let b=new Date(e1.setMinutes(e1.getMinutes()-30))
    console.log(b);
    let endDATE =this.datepipe.transform(b, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")  
    return this.db.colWithIds$("courses/"+courseid+"/webinar_sessions",res=>res.where("start",">=",endDATE));
  }
  getfilteredwebinarlist<T>(courseid,e): Observable<T[]> {
    console.log(courseid);
    
    return this.db.colWithIds$("courses/"+courseid+"/webinar_sessions",res=>res.where("session_type","==",e));
  }
  getattendancelist<T>(courseid): Observable<T[]> {
    console.log(courseid);
    
    return this.db.col$("courses/"+courseid+"/attendees");
  }
  updatewebinar(webinarid:any,courselist:string,heading:string,val1:string){
    console.log(webinarid+""+courselist);
    
    return this.db.partialUpdate("courses/"+courselist+"/webinar_sessions/"+webinarid.id,{
      session_type:val1,
      name:heading,
      attendance_tracked:false
    })
  }
  deetewebinar(colname:string,id:string,col:string,webid:string){
    console.log(colname,id,col,webid);
    
return this.db.delete("courses/"+id+"/webinar_sessions/"+webid)
  }

  addinterviewQA(id: string, que: string){
    console.log(id,que)
    // const doc = this.db.col("resources").doc(id);
    // return doc.update({ questions: firestore.FieldValue.arrayUnion(que) });
    let uid=this.afs.createId();
    return this.db.partialUpdate("interviewQA/" +uid,{
      question:que,
      id:id,
      uid:uid
    })
  }
  getinterviewQA<T>(id: string): Observable<T[]> {
    return this.db.colWithIds$("interviewQA" ,res=>res.where("id","==",id))
  }
  updateinterviewQA(id,question){
    return this.db.partialUpdate("interviewQA/"+id,{
      question:question,
    })
  }
  deleteinterviewQA(id: string){
    return this.db.delete("interviewQA/" + id)
  }
  addDescription(id: string, des: string){
    return this.db.partialUpdate("resources/" + id,{description: des })
  }
  getResourcebyid(id: string){
    return this.db.doc$("resources/" + id)
  }
}
