import { Component, OnInit, Input } from '@angular/core';
import { AudioRecordingService } from 'src/app/service/audio-recording.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TAResources } from 'src/app/Interface/resources';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TAUser } from 'src/app/Interface/ta-user';

@Component({
  selector: 'app-audio-recoder',
  templateUrl: './audio-recoder.component.html',
  styleUrls: ['./audio-recoder.component.css']
})
export class AudioRecoderComponent implements OnInit {
  isRecording = false;
  starRrec:boolean=false
  recordedTime;
  blobUrl;
  question: TAResources;
  deleteicon: boolean;
  audioLink: string;
  _selecteduser: TAUser;
  @Input()
  public set Question(v : TAResources) {
    this.question = v;
    // console.log(this.question);
    this.audioLink = this.question.AudioUrl; 
  }
  
  @Input()
  public set SelectedUser(v : TAUser) {
    this._selecteduser = v;
  }
  constructor(private tafbs: TekarchFbService,private audioRecordingService: AudioRecordingService,private sanitizer: DomSanitizer) {
    // this.currentuserRole = this.tds.getCurrentUser().RoleName;
    this.audioRecordingService.recordingFailed().subscribe(() => {
      this.isRecording = false;
    });

    this.audioRecordingService.getRecordedTime().subscribe((time) => {
      this.recordedTime = time;
      // console.log(this.recordedTime);
      
    });

    this.audioRecordingService.getRecordedBlob().subscribe((data) => {
     
      
      this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.blob));
      // console.log( this.blobUrl);
    });
   }

  ngOnInit() {
  }
  startRecording() {
    this.starRrec=true
    if (!this.isRecording) {
      this.isRecording = true;
      this.audioRecordingService.startRecording();
    }
  }

  abortRecording() {
    if (this.isRecording) {
      this.isRecording = false;
      this.audioRecordingService.abortRecording();
    }
  }

  stopRecording() {
    this.starRrec=false
    if (this.isRecording) {
      this.audioRecordingService.stopRecording(this.question,this._selecteduser.UserId);
      this.isRecording = false;
    }
  }
  deleterec(){
    // console.log('sccccccccc');
    this.deleteicon=true
  }
  deleterec1(){
    this.deleteicon=false
  }
  deleteRecording(){
    this.tafbs.deleterecore(this.question)
  }
}
