import { Component, OnInit, Input } from '@angular/core';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-assignment-answer-sheet',
  templateUrl: './assignment-answer-sheet.component.html',
  styleUrls: ['./assignment-answer-sheet.component.css']
})
export class AssignmentAnswerSheetComponent implements OnInit {

  user: any;
  question: any;
  data: any;
  @Input()
  public set test(v: any) {
    this.user = v
  }
  @Input()
  public set test2(v: any) {
    this.question = v
    this.tafbs.getUserAssignAnswer(this.user.UserId, this.question.id).pipe(take(1)).subscribe(d => {
      if (d) {
        this.data = d     
      } else {
        this.data = null
      }

    

    })

  }
  constructor(private tafbs: TekarchFbService) { }

  ngOnInit() {
  }

}
