import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-interview-qa',
  templateUrl: './interview-qa.component.html',
  styleUrls: ['./interview-qa.component.css']
})
export class InterviewQaComponent implements OnInit {
  SelectedCourse: any;
  multiple:boolean=true
  click=true
  selecteduserlist: any;
  constructor() { }

  ngOnInit() {
  }
  SelectedCourseDD(e){
    // console.log(e);
    this.SelectedCourse=e
  }
  Selecteduser(e){
    // console.log(e);
  }
  SelectedStudentDD(e){
    this.selecteduserlist=e
// console.log(e)
  }
}
