import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { TAUser } from '../../Interface/ta-user';
import { TekarchFbService } from '../../service/tekarchfbservice';
import { Roles } from '../../Interface/roles';
import { TACourse } from 'src/app/Interface/course';
import { TekarchDataService } from 'src/app/service/tekarchdataservice';
import { take } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.css']
})

export class ManageUsersComponent implements OnInit {
  cols: { field: string; header: string; }[];
  userroles: Roles[];
  selecteduserrole: string;
  userdetail: TAUser;
  editselectedUser: TAUser;
  editdialogVisible: boolean = false;
  TAUser_List: TAUser[] = [];
  deleteConfimation: boolean = false
  InActive: boolean = false;
  courseList: TACourse[];
  userlist: TAUser[];
  TAUsers: any;
  selectedcourse: any;
  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      // Do things
      try {
        this.CancelUserUpdate()
      } catch (error) {

      }


    }
  }
  constructor(private ultfs: TekarchFbService, private tds: TekarchDataService, public afs: AngularFirestore) {

    this.ultfs.getUserList<TAUser>("candidate").subscribe(data => {
      if (this.selectedcourse) {
        this.onChangeDD(this.selectedcourse, "dt")
      }
      else if (data.length > 1) {
        this.TAUser_List = data;
      }
    });

    this.ultfs.getdd<TACourse>("courses").subscribe(data => {
      this.courseList = data;
    })
    this.ultfs.getRoles().subscribe(data => {
      this.userroles = data;
    });

  }

  ngOnInit() {
    this.cols = [
      { field: "UserId", header: "User Id" },
      { field: "UserName", header: "User Name" },
      { field: "RoleName", header: "Role" },
    ];
  }


  selectedUserEdit(e) {
    this.editselectedUser = e;
    this.editdialogVisible = true;
  }
  checked(val){
    if(val)
    return val.every(data=>{
       return  data.InActive==true
     })
  }
  SelectAllVisible(e,val){
    let batch = this.afs.firestore.batch();
    if(val&&val.length>=1){
    val.forEach(data=>{ 
      this.afs.collection("candidate").doc(data.id).update({InActive:e.checked})
    }) 
    batch.commit().catch(err => console.error(err));
  }

  }
  async UpdateUser(t) {
    this.userdetail = t;


    if (this.userdetail.RoleName == 'None') {
      this.userdetail.InActive = false;
    }

    if (isNaN(t.UserPhoneNo) && t.UserPhoneNo) {
      t.UserPhoneNo = t.UserPhoneNo.replace(/[^\w\s]/gi, '')
      t.UserPhoneNo = t.UserPhoneNo.replace(/\s/g, "")
      this.userdetail.UserPhoneNo = Number(t.UserPhoneNo)
    }
    this.editdialogVisible = false;
    // console.log(this.userdetail);
    await this.ultfs.updateUserDetails(this.userdetail);
  }
  CancelUserUpdate() {
    this.ultfs.getUserList<TAUser>("candidate").subscribe(data => {
      if (this.selectedcourse) {
        this.onChangeDD(this.selectedcourse, "dt")
      }
      else {
        this.TAUser_List = data;
      }
    });
    this.editselectedUser = null;
    this.editdialogVisible = false;
  }
  SelectedUserRole(e) {

    this.editselectedUser.RoleName = e.RoleName;
  }
  DeleteUser(e) {

    this.editdialogVisible = false;
    this.deleteConfimation = true
  }
  Canceldeleteuser() {
    this.deleteConfimation = false
    this.editdialogVisible = false;
  }
  deleteuser(e) {
    this.ultfs.deleteUser(e);
    this.deleteConfimation = false
    this.editdialogVisible = false;

  }
  candidateInActiveChanges(e) {
    this.ultfs.updateInActive(e);
  }
  onChangeDD(event, dt) {
    this.selectedcourse = event
    if (event) {
      this.ultfs.getUserList<TAUser>("candidate").pipe(take(1)).subscribe(data => {
        this.userlist = data;
        this.ultfs.getUserCourseUsingId(event.CourseId).pipe(take(1)).subscribe(d => {
          this.TAUser_List = [];
          for (var i = this.userlist.length - 1; i >= 0; i--) {
            for (var j = 0; j < d.length; j++) {
              if (
                this.userlist[i] &&
                this.userlist[i].UserId ===
                d[j].UserId
              ) {
                this.TAUser_List.push(this.userlist[i]);
              }
            }
          }
        })
      });
    }

  }
  allusers() {
    this.ultfs.getUserList<TAUser>("candidate").subscribe(data => {
      if (data.length > 1) {
        this.TAUser_List = data;
        this.userlist = data
      }
    });
  }
  onKeyPress(event) {
    this.ultfs.getdd<TACourse>("courses").subscribe(data => {
      this.courseList = this.filterAction(event.query, data);
    })
  }

  filterAction(query, data: TACourse[]): TACourse[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: TACourse[] = [];
    for (let i = 0; i < data.length; i++) {
      let selectedCourseName = data[i];
      if (selectedCourseName.CourseName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(selectedCourseName);
      }
    }
    return filtered;
  }
}
