import { Component, OnInit, Input } from '@angular/core';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TekarchDataService } from 'src/app/service/tekarchdataservice';
import * as _ from 'lodash';
@Component({
  selector: 'app-assignment-answer',
  templateUrl: './assignment-answer.component.html',
  styleUrls: ['./assignment-answer.component.css']
})
export class AssignmentAnswerComponent implements OnInit {
  _courseId: number;
  selectedUser: any[];
  _heading: string;
  selectedHeadingQuestions: any[];

  @Input()
  public set courseId(v: any) {
    this._courseId = v.CourseId;
    let unique_array1 = [];
    let unique_array2 = [];
    this.tafbs.getUserCourseUsingId(this._courseId).subscribe(async data => {
      for (let i = 0; i < data.length; i++) {
        if (unique_array1.indexOf(data[i].UserId) == -1) {
          unique_array1.push(data[i].UserId);
          unique_array2.push(data[i]);
        }
      } 
      let unique_array3 = [];
      unique_array2.forEach(element => {
        this.tafbs.getUserProfile(element.UserId).subscribe(d=>{
    if(d && d.RoleName=="Student"){
      unique_array3.push(d)
      }   
this.selectedUser = _.sortBy(unique_array3, "UserName");
this.selectedUser.forEach(d=>{
  // console.log(d);
  // console.log(v); 
  this.tafbs.getUserAssignAnswertotal(d.UserId,this._courseId,v.Heading,"Completed").subscribe(a=>{
    // console.log(a);
d.complete=a.length
  })
  this.tafbs.getUserAssignAnswertotal(d.UserId,this._courseId,v.Heading,"Completed with Bugs").subscribe(a=>{
    d.bugs=a.length
      })
      this.tafbs.getUserAssignAnswertotal(d.UserId,this._courseId,v.Heading,"INComplete").subscribe(a=>{
        // d.bugs=a.length
        // console.log(this.selectedHeadingQuestions.length,d.complete,d.bugs);
        
        d.incomplete=  this.selectedHeadingQuestions.length-(d.complete?d.complete:0+d.bugs?d.bugs:0)
          })
})
// console.log(this.selectedUser);
        })      
      });
      // this.selectedUser = await  unique_array3.sort((a,b)=>{if (a.UserName < b.UserName) return -1;
      //   if (a.UserName > b.UserName) return 1;
      //   return 0;});   
    })  
  }
  
  
  @Input()
  public set heading(v: string) {
    this._heading = v;
    // console.log(v);
    this.tafbs.getCourseAssignment(this._courseId, this._heading).subscribe(async d => {
      this.selectedHeadingQuestions = d;
      await this.selectedHeadingQuestions;
      return this.selectedHeadingQuestions;
    });
  }

  constructor(private tafbs: TekarchFbService, private tds: TekarchDataService) { }

  ngOnInit() {}

}
