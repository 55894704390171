import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TAResources } from 'src/app/Interface/resources';
@Component({
  selector: 'app-heading-dd',
  templateUrl: './heading-dd.component.html',
  styleUrls: ['./heading-dd.component.css']
})
export class HeadingDdComponent implements OnInit {
  selectedCourse: number;
  ddSize: number = 40;
  SelectedQuestionHeading: TAResources;
  @Output()
  SelectedHeading = new EventEmitter();
  headinglist: TAResources[];
  @Input()
  public set SelectedCourse(v: number) {

    this.selectedCourse = v;
    this.tafbs.getHeadingList<TAResources>("resources", this.selectedCourse).subscribe(data => {
      this.headinglist = data;
    });
  }
  constructor(private tafbs: TekarchFbService) { }

  ngOnInit() {
  }
  onKeyPress(event) {
    this.tafbs.getHeadingList<TAResources>("resources", this.selectedCourse).subscribe(data => {
      this.headinglist = this.filterAction(event.query, data);
    });
  }

  filterAction(query, data: TAResources[]): TAResources[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: TAResources[] = [];
    for (let i = 0; i < data.length; i++) {
      let selectedHeading = data[i];
      if (selectedHeading.Heading.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(selectedHeading);
      }
    }
    return filtered;
  }

  //#endregion

  onDropDownSelect(e) {
    this.SelectedHeading.emit(e);
  }
}
