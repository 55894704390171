import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { TAProgram } from 'src/app/Interface/program';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TAResources } from 'src/app/Interface/resources';

@Component({
  selector: 'app-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.css']
})
export class HeadingComponent implements OnInit {
  inputValue: string;
  programlist: TAProgram[];
  SelectedProgramName: TAProgram;
  _disabled: boolean = false;
  selectedCourse: number;
  selectedProgram: string;
  selectedprg: number;
  headinglist: TAResources[];
  SelectedQuestionHeading: TAResources;
  @Output()
  SelectedHeading = new EventEmitter();

  @Input()
  ddSize: number;


  @Input()
  set ddObj(value: TAResources) {
    this.SelectedQuestionHeading = value
  }


  @Input()
  public set disabled(v: boolean) {
    this._disabled = v;
  }

  @Input()
  public set SelectedCourse(v: number) {
    this.selectedCourse = v;
    this.SelectedProgramName = null
  }

  @Input()
  public set SelectedProgram(v: number) {
    this.selectedprg = v;
  }


  // @Input()
  // set ddObj(value: TAProgram) {
  //   this.SelectedProgramName = value
  // }

  @Input()
  public set ddReset(v: string) {

    if (v == null || v == undefined) {
      this.SelectedProgramName = null;
    }
  }
  constructor(private tafbs: TekarchFbService) {

  }

  ngOnInit() {
    if (this.selectedCourse && this.selectedprg) {
      this.tafbs.getHeading<TAResources>("resources", this.selectedCourse, this.selectedprg).subscribe(data => {
        this.headinglist = data;
      });
    }
  }
  //#region keypress filter 

  onKeyPress(event) {
    this.tafbs.getHeading<TAResources>("resources", this.selectedCourse, this.selectedprg).subscribe(data => {
      this.headinglist = this.filterAction(event.query, data);
    });
  }

  filterAction(query, data: TAResources[]): TAResources[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: TAResources[] = [];
    for (let i = 0; i < data.length; i++) {
      let selectedHeading = data[i];
      if (selectedHeading.Heading.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(selectedHeading);
      }
    }
    return filtered;
  }

  //#endregion

  onDropDownSelect(e) {
    this.SelectedHeading.emit(e);
  }

}
