// accordian panel for programe 
// pass list of program from parent to child

import { Component, OnInit,AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TekarchFbService } from '../../service/tekarchfbservice';
import { TAProgram } from '../../Interface/program';
import { TekarchDataService } from '../../service/tekarchdataservice';
import { TAUser } from 'src/app/Interface/ta-user';
import { TACourse } from 'src/app/Interface/course';
import { TAResources } from 'src/app/Interface/resources';
import { element } from '@angular/core/src/render3/instructions';
import { SelectItem } from 'primeng/api';
import { take, filter } from 'rxjs/operators';
import { Email } from 'src/app/Interface/email';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from "firebase/app";
import { ProgramViewComponent } from '../program-view/program-view.component';
import { p } from '@angular/core/src/render3';
import { webacc } from 'src/app/Interface/col-dd';

@Component({
  selector: 'app-ta-program',
  templateUrl: './ta-program.component.html',
  styleUrls: ['./ta-program.component.css']
})
export class TaProgramComponent implements OnInit {

  resourcelist: any;
  clicktime: number;
  accordion: string;
  programlist: any = [];
  click: boolean = false;
  currentuser: TAUser;
  selectedvalue: string = "Programs";
  filterData: string;
  selectedCourse: TACourse;
  questionList: any[];
  selectedTypes: string[] = ['Programs'];
  types: SelectItem[];
  selectedButtonValue: string = 'Programs';
  ResourceId: number;
  resourceUrl: string;
  description: string;
  resourseType: { ResourceTypeId: number; ResourceTypeName: string; };
  headingList: TAResources[];
  FilteredHeadingList: any[];
  currentUserRole: string;
  heading: string;
  sendEmailDialog: boolean = false;
  activatedUsers: any[];
  userCourseData: any[];
  selectedCourseData: TACourse;
  endpoint = 'https://us-central1-bluelinecertication-dev.cloudfunctions.net/httpEmail';
  sbendpoint = 'https://us-central1-tekarchwebportal.cloudfunctions.net/sendInBlue';
  Emaildata: Email;
  mail: any;
  subject: string;
  content: string;
  to: string;
  from: string;
  adminUsers: any[];
  adminusersList: any[];
  testEmail: { email: string; }[];
  cc: any;
  currentUser: TAUser;
  assignment: string;
  assignmentlist: any[];
  selectedCourseId: number;
  AsslistData: TAProgram[];
  interviewheading: string;
  adminUserList: any[];
  program: string;
  ProgramId: number;
  programData: { ProgramName: any; };
  programListData: TAProgram[];
  interviewQuelist: TAProgram[];
  IHList: any[];
  myarray: any[];
  Questionarray: any[];
  QHList: any[];
  courseId: number;
  tableDialog: boolean;
  selectedUser: any[];
  selectedHeadingQuestions: any[];
  candidatetypes:any = [];
  session:string
  time:string
  link:string
  vtime:number
  invitevisible:boolean
  @ViewChild('primaryColorSample')
  programviewComponent: ProgramViewComponent;

  invitedialog: boolean;
  selectedCourseinvite: webacc;
  starttime: Date;
  attendancedialog: boolean;
  webinar: webacc[];
  attendance: any;
  classtype: string;
  val1:string="classroom"
  webinarheading: string;


  constructor(private route: ActivatedRoute, private router: Router, private db: AngularFirestore, private tafbs: TekarchFbService, private tads: TekarchDataService,
    private http: HttpClient) {
    this.currentUser = this.tads.getCurrentUser()
    this.types = [
      { label: 'Programs', value: 'Programs' },
      { label: 'Assignments', value: 'Assignments' },
      { label: 'Questions', value: 'Questions' },
      { label: 'InterviewQuestions', value: 'InterviewQuestions' }
    ];
    this.tafbs.getActiveUsers().subscribe(async data => {
      this.activatedUsers = data;
    });

  }

  ngOnInit() {
    this.currentuser = this.tads.getCurrentUser();
    this.currentUserRole = this.tads.getCurrentUser().RoleName;
    this.route.params.subscribe(p => this.UpdateSelectedCourse(p["id"]));
  }

  ngAfterViewInit() {
    // console.log('ng afterv view init');
    
  }

  saveinterviewHeading(heading) {
    if (heading.id) {
      let id = this.db.createId();
      if (this.isNullOrEmpty(heading.id)) {
        return;
      } else {
        this.tafbs.getResourseID<TAResources>("resources").pipe(take(1)).subscribe(async data => {
          this.ResourceId = data[0].ResourceId + 1;
          await this.ResourceId;
          this.resourseType = {
            ResourceTypeId: 4,
            ResourceTypeName: "InterviewQuestion"
          }
          this.resourceUrl = ''
          this.description = ''
          // this.selectedCourseId = this.selectedCourse.CourseId;
          if (this.ResourceId != data[0].ResourceId) {
            this.tafbs.updateQuestionHeading(id, heading.id, this.description, this.resourceUrl, this.selectedCourseId, this.resourseType, this.ResourceId);
            // const userCollectionRef = this.db.collection('QuestionList/'+heading.id+"/questions")
            this.tafbs.getheadingQueList<TAResources>("InterviewQuestionList", heading.id).pipe(take(1)).subscribe(d => {
              let batch = this.db.firestore.batch();
              d.forEach((userDocRef, index) => {
                const timestamp = this.timestamp;
                this.db.collection("interviewquestions").add({
                  Question: userDocRef.Question,
                  HeadingId: id,
                  visible: true,
                  Heading: heading.id,
                  CourseId: this.selectedCourseId,
                  ResourceId: this.ResourceId,
                  ResourceTypeId: this.resourseType.ResourceTypeId,
                  ResourceTypeName: this.resourseType.ResourceTypeName,
                  createdAt: timestamp,
                  IndexNo: index + 1,
                  AudioUrl:userDocRef.AudioUrl?userDocRef.AudioUrl:null
                })
              }); batch.commit().catch(err => console.error(err));
            })
          }
        });
        this.interviewheading = '';
      }
    }
    else if (heading) {
      heading = heading.charAt(0).toUpperCase() + heading.slice(1);
      if (this.isNullOrEmpty(heading)) {
        return;
      } else {
        this.tafbs.getResourseID<TAResources>("resources").pipe(take(1)).subscribe(async data => {
          this.ResourceId = data[0].ResourceId + 1;
          await this.ResourceId;
          this.resourseType = {
            ResourceTypeId: 4,
            ResourceTypeName: "InterviewQuestion"
          }
          this.resourceUrl = ''
          this.description = ''
          this.selectedCourseId = this.selectedCourse.CourseId;
          if (this.ResourceId != data[0].ResourceId) {
            this.tafbs.addQuestionHeading(heading, this.description, this.resourceUrl, this.selectedCourseId, this.resourseType, this.ResourceId);
          }
        });
        this.interviewheading = '';
      }
    }
    else {
      alert("Please, enter all the required fields")
    }
  }
  saveAssignment(assignment) {
    if (assignment) {
      assignment = assignment.charAt(0).toUpperCase() + assignment.slice(1);
      if (this.isNullOrEmpty(assignment)) {
        return;
      } else {
        this.tafbs.getResourseID<TAResources>("resources").pipe(take(1)).subscribe(async data => {
          this.ResourceId = data[0].ResourceId + 1;
          await this.ResourceId;
          this.resourseType = {
            ResourceTypeId: 4,
            ResourceTypeName: "Assignment"
          }
          this.resourceUrl = ''
          this.description = ''
          this.selectedCourseId = this.selectedCourse.CourseId;
          if (this.ResourceId != data[0].ResourceId) {
            this.tafbs.addQuestionHeading(assignment, this.description, this.resourceUrl, this.selectedCourseId, this.resourseType, this.ResourceId);
          }
        });
        this.assignment = '';
      }
    }
    else {
      alert("Please, enter all the required fields")
    }
  }

 

  UpdateSelectedCourse(filterKey: string) {
    if (filterKey) {
      this.tafbs.getCourse<TACourse>("courses", filterKey).subscribe(data => {
        this.selectedCourseData = data[0];
        console.log(  this.selectedCourseData);
        
        this.tafbs.getCourseInvite<webacc>("courses",  this.selectedCourseData).subscribe(data => {
          if(data[0]){
            console.log(data[0].start);
           let  webinarlink="https://register.gotowebinar.com/register/"+data[0].webinar_id
           console.log(webinarlink);
           this.link=webinarlink
           this.starttime=new Date(data[0].start)
           this.classtype=data[0].session_type
           this.webinarheading=data[0].name
            // this.selectedCourseinvite.webinar_id="https://dashboard.gotowebinar.com/webinar/"+data[0].webinar_id
            // this.selectedCourseinvite.starttime=data[0].start
            this.invitevisible=true
          }
          else{
            // this.selectedCourseinvite=undefined
            this.invitevisible=false
          }

        })
      })
     
    }
    if (filterKey)
      this.filterData = filterKey
    if (this.currentuser.RoleName == "Admin") {
      this.tafbs.getProgrambyCourse<TAProgram>("programs", filterKey).pipe(take(1)).subscribe(data => {
        this.programlist = []
        data.forEach(element => {
          if (element.ProgramName != "Assignment" && element.ProgramName != "Question") {
            this.programlist.push(element)
          }
        });
        this.onChange('Programs');
      });
      // console.log('Admin');
      
    } else {

      // this.programlist = [];
      // console.log(filterKey);

    
      
      
      
      
      this.tafbs.getProgrambyCourse<TAProgram>("programs", filterKey).subscribe(data => {
        this.programlist = data;
        if(data.length != 0){
          // this.candidatetypes=[]
          this.candidatetypes.push({label: 'Programs', value: 'Programs'}); 
        }
        this.onChange('Programs');
      }); 
      this.tafbs.getCourse<TACourse>("courses", filterKey).subscribe(data => {
        this.selectedCourse = data[0];
        this.selectedCourseId = this.selectedCourse.CourseId;
        this.tafbs.getAssignmentResource<TAProgram>("resources", this.selectedCourse.CourseId).pipe(take(1)).subscribe(data => {
          this.AsslistData = data;
          // this.candidatetypes=[]
          if(data.length != 0){
            // this.candidatetypes=[]
            this.candidatetypes.push({ label: 'Assignments', value: 'Assignments' })
          }
          // console.log(this.AsslistData);
        });
        this.tafbs.getInterviewQuestionResource<TAProgram>("resources", this.selectedCourse.CourseId).pipe(take(1)).subscribe(data => {
          this.interviewQuelist = data;
          // this.candidatetypes=[]
          if(data.length != 0){
            // this.candidatetypes=[]
            this.candidatetypes.push({ label: 'InterviewQuestions', value: 'InterviewQuestions' })
          }
          // console.log(this.interviewQuelist);
        });
        this.tafbs.getQuestionResource<TAResources>("resources", this.selectedCourse.CourseId).pipe(take(1)).subscribe(d => {
          this.headingList = d;
          // this.candidatetypes=[]
          if(d.length != 0){
            
            this.candidatetypes.push({ label: 'Questions', value: 'Questions' })
          }
          
          // console.log(this.headingList);
        });
      });

      this.candidatetypes = [];
      
    }
  }





  onKeyPressQuestion(event) {

    this.tafbs.getIntervHeading("QuestionList").subscribe(data => {
      this.Questionarray = data

      if (this.headingList && this.headingList.length > 0) {
        for (var i = this.Questionarray.length - 1; i >= 0; i--) {
          for (var j = 0; j < this.headingList.length; j++) {
            if (this.Questionarray[i] && (this.Questionarray[i].id == this.headingList[j].Heading)) {

              this.Questionarray.splice(i, 1);
              this.QHList = this.filterAction(event.query, this.Questionarray);

            } else {
              this.QHList = this.filterAction(event.query, this.Questionarray);
            }
          }
        }
      }
      else {
        this.QHList =
          this.filterAction(event.query, this.Questionarray);
      }
    })
  }
  onKeyPress(event) {

    this.tafbs.getIntervHeading("InterviewQuestionList").subscribe(data => {

      this.myarray = data

      if (this.interviewQuelist && this.interviewQuelist.length > 0) {
        for (var i = this.myarray.length - 1; i >= 0; i--) {
          for (var j = 0; j < this.interviewQuelist.length; j++) {
            if (this.myarray[i] && (this.myarray[i].id == this.interviewQuelist[j].Heading)) {
              this.myarray.splice(i, 1);
              this.IHList = this.filterAction(event.query, this.myarray);
            } else {
              this.IHList = this.filterAction(event.query, this.myarray);
            }
          }
        }
      }
      else {
        this.IHList =
          this.filterAction(event.query, this.myarray);
      }
    })
  }

  filterAction(query, data: any[]): any[] {
    let filtered: any[] = [];
    for (let i = 0; i < data.length; i++) {
      let interviewheading = data[i];
      if (interviewheading.id.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(interviewheading);
      }
    }
    return filtered;
  }
  Accordian(e) {
    const clicktime = new Date().getTime()
    if (clicktime - this.tads.getAccordiantime() < 1000) {
      return
    } else {
      if (e) {
        if (e == this.tads.getviewprogram()) {
          this.tads.setviewprogram("null");
          this.accordion = "null"
        }
        else {
          this.accordion = e
          this.tads.setviewprogram(e);
        }
      }

    }
  }
 
  onChange(e) {
 
    
    if (e.value != undefined) {
      this.selectedButtonValue = e.value;
    } else {
      this.selectedButtonValue = e;
    }

    if (this.selectedButtonValue == "Programs") {
      this.programlist = []
      this.tafbs.getCourse<TACourse>("courses", this.filterData).subscribe(data => {
        this.selectedCourse = data[0];
      });
      this.tafbs.getProgrambyCourse<TAProgram>("programs", this.filterData).subscribe(data => {
        this.programListData = data;
      });
    } else if (this.selectedButtonValue == "Assignments") {
      this.assignmentlist = []
      this.tafbs.getCourse<TACourse>("courses", this.filterData).subscribe(data => {
        this.selectedCourse = data[0];
        this.selectedCourseId = this.selectedCourse.CourseId;
        this.tafbs.getAssignmentResource<TAProgram>("resources", this.selectedCourse.CourseId).subscribe(data => {
          this.AsslistData = data;
        });
      })
    } else if (this.selectedButtonValue == "InterviewQuestions") {
      this.interviewQuelist = []
      this.tafbs.getCourse<TACourse>("courses", this.filterData).subscribe(data => {
        this.selectedCourse = data[0];
        this.selectedCourseId = this.selectedCourse.CourseId;
        this.tafbs.getInterviewQuestionResource<TAProgram>("resources", this.selectedCourse.CourseId).subscribe(data => {
          this.interviewQuelist = data;
          // console.log(this.interviewQuelist);
          
        });
      })

    } else if (this.selectedButtonValue == "Questions") {
      this.headingList = [];
      this.tafbs.getCourse<TACourse>("courses", this.filterData).subscribe(data => {
        this.selectedCourse = data[0];
        this.tafbs.getQuestionResource<TAResources>("resources", this.selectedCourse.CourseId).subscribe(d => {
          this.headingList = d;
          // console.log(d);
          
        })
      })

    }
  }
  isNullOrEmpty(str) {
    return !str || !str.trim();
  }
  saveHeading(heading) {
    if (heading.id) {
      let id = this.db.createId();
      if (this.isNullOrEmpty(heading.id)) {
        return;
      } else {
        this.tafbs.getResourseID<TAResources>("resources").pipe(take(1)).subscribe(async data => {
          this.ResourceId = data[0].ResourceId + 1;
          await this.ResourceId;
          this.resourseType = {
            ResourceTypeId: 4,
            ResourceTypeName: "Question"
          }
          this.resourceUrl = ''
          this.description = ''

          this.selectedCourseId = this.selectedCourse.CourseId;

          if (this.ResourceId != data[0].ResourceId) {

            this.tafbs.updateQuestionHeading(id, heading.id, this.description, this.resourceUrl, this.selectedCourseId, this.resourseType, this.ResourceId);
            // const userCollectionRef = this.db.collection('QuestionList/'+heading.id+"/questions")
            this.tafbs.getheadingQueList<TAResources>("QuestionList", heading.id).pipe(take(1)).subscribe(d => {
              let batch = this.db.firestore.batch();
              d.forEach((userDocRef, index) => {
                const timestamp = this.timestamp;
                this.db.collection("questions").add({
                  Question: userDocRef.Question,
                  HeadingId: id,
                  visible: true,
                  Heading: heading.id,
                  CourseId: this.selectedCourseId,
                  // ProgramId: questionDatas.ProgramId,
                  ResourceId: this.ResourceId,
                  ResourceTypeId: this.resourseType.ResourceTypeId,
                  ResourceTypeName: this.resourseType.ResourceTypeName,
                  createdAt: timestamp,
                  // QuestionNo: qno,
                  IndexNo: index + 1
                })

              }); batch.commit().catch(err => console.error(err));
            })

          }
        });

        this.interviewheading = '';
      }
    }
    else if (heading) {
      heading = heading.charAt(0).toUpperCase() + heading.slice(1);
      if (this.isNullOrEmpty(heading)) {
        return;
      } else {
        this.tafbs.getResourseID<TAResources>("resources").pipe(take(1)).subscribe(async data => {
          this.ResourceId = data[0].ResourceId + 1;
          await this.ResourceId;
          this.resourseType = {
            ResourceTypeId: 4,
            ResourceTypeName: "Question"
          }
          this.resourceUrl = ''
          this.description = ''
          this.selectedCourseId = this.selectedCourse.CourseId;
          if (this.ResourceId != data[0].ResourceId) {
            this.tafbs.addQuestionHeading(heading, this.description, this.resourceUrl, this.selectedCourseId, this.resourseType, this.ResourceId);
          }
        });
        this.interviewheading = '';
      }
    }
    else {
      alert("Please, enter all the required fields")
    }
  }
  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }
  sendEmailData(e) {
    this.sendEmailDialog = true;
    this.from = this.currentUser.UserId;
    this.tafbs.getUserCourse(e).subscribe(async data => {
      this.userCourseData = data;
      let newData = [];
      for (var i = this.activatedUsers.length - 1; i >= 0; i--) {
        for (var j = 0; j < this.userCourseData.length; j++) {
          if (this.activatedUsers[i] && (this.activatedUsers[i].UserId === this.userCourseData[j].UserId)) {
            newData.push(this.activatedUsers[i].UserId);
            // this._userlist = newData.join(",")
            var arrayObject = newData.map(function (item) {
              return { email: item };
            });
            this.tads.setUserList(arrayObject);
          }
        }
      }
    })
  }
  sendEmail(f, s, c) {
    if (f && s && c) {
      this.mail = this.tads.getUserList();
      this.Emaildata = {
        fromEmail: f,
        toEmail: 'ashwin.softwiz@gmail.com',
        toName: `Tekarch`,
        toCc: this.adminUserList ? this.adminUserList : null,
        // toCc: this.adminusersList ? this.adminusersList : null,
        toBcc: this.mail,
        toSubject: s,
        toContent: c
      }

      this.http.post(this.sbendpoint, this.Emaildata).subscribe();
      // this.http.post(this.endpoint, this.Emaildata).subscribe();
      this.sendEmailDialog = false;
      this.content = '';
      this.subject = '';
      this.from = '';
      this.cc = ''
    } else {
      alert('please enter require fields');
    }
  }
  cancelEmail() {
    this.sendEmailDialog = false;
    this.content = '';
    this.subject = '';
    this.from = '';
    this.cc = ''
  }
  addAdminsEmail(e) {
    this.testEmail = [{
      email: "ashwinraj0010@yahoo.com"
    }, {
      email: "ashwin.messier@gmail.com"
    }]
    let adminUsersArrayList = [];
    if (e == true) {
      this.tafbs.getAdminLists().subscribe(d => {
        this.adminUserList = d;
      })
      this.tafbs.getAllAdmins().subscribe(d => {
        this.adminUsers = d;
        d.forEach(data => {
          adminUsersArrayList.push(data.UserId);
          var arrayObject1 = adminUsersArrayList.map(function (item) {
            return { email: item };
          });

          var result1 = arrayObject1.reduce((unique, o) => {
            if (!unique.some(obj => obj.email === o.email)) {
              unique.push(o);
            }
            return unique;
          }, []);
          this.adminusersList = result1;
        })
      })
    }
  }
  trackbyfunction(index, item) {
    return index

  }
  saveProgram(pgm) {
    if (pgm) {
      pgm = pgm.charAt(0).toUpperCase() + pgm.slice(1);
      if (this.isNullOrEmpty(pgm)) {
        return;
      } else {
        this.tafbs.programId<TAProgram>("programs").pipe(take(1)).subscribe(async data => {
          this.ProgramId = data[0].ProgramId + 1;
          await this.ProgramId;

          this.programData = {
            ProgramName: pgm,
            // ResourceTypeName: "Question"
          }

          this.description = ''
          this.selectedCourseId = this.selectedCourse.CourseId;

          if (this.ProgramId != data[0].ProgramId) {
            this.tafbs.addProgram(this.programData, this.selectedCourse, this.ProgramId);
            // this.tafbs.addQuestionHeading(pgm, this.description, this.resourceUrl, this.selectedCourseId, this.resourseType, this.ResourceId);
          }
        });
        this.program = '';
      }
    }
    else {
      alert("Please, enter all the required fields")
    }
  }
  CancelNewResourse(){
    this.tableDialog = false;
    
  }
  clickinvite(){
    this.invitedialog=true
  }
  questionListDialogFunction() {
    this.tableDialog = true;
    if (this.filterData) {
      this.tafbs.getCourse<TACourse>("courses", this.filterData).subscribe(data => {
        this.selectedCourse = data[0];
        this.courseId = this.selectedCourse.CourseId;
        this.tafbs.getQuestionResource<TAResources>("resources", this.courseId).subscribe(d => {
          this.headingList = d;
        })
      });
    }
  }
  Saveinvitedata(s,t,l,vtime){
    this.invitedialog=false
    // console.log(this.selectedCourseData);
    this.tafbs.addinvite(this.selectedCourseData,s,t,l,vtime)
    
  }
   cancelinvite(){
    this.invitedialog=false
   }
   attendanceFunction(){
    this.val1="classroom"
     console.log(this.courseId);
     if (this.filterData) {
      this.tafbs.getCourse<TACourse>("courses", this.filterData).subscribe(data => {
        this.selectedCourse = data[0];
        this.courseId = this.selectedCourse.CourseId;
        console.log( this.selectedCourse);
        
      
      });
      this.tafbs.getattendancelist<webacc>( this.selectedCourse.id).subscribe(d => {
        this.attendance = d;
        console.log(d);
        
      })
      this.tafbs.getfilteredwebinarlist<webacc>( this.selectedCourse.id,"classroom").subscribe(d => {
        this.webinar = d;
        console.log(d);
        
      })
    }
     this.attendancedialog=true

   }
   CancelattendanceFunction(){
    this.attendancedialog = false;
    
  }
  selectedbutton(e){
    console.log(e);
    if(e=="all"){
      this.tafbs.getwebinarlist<webacc>( this.selectedCourse.id).subscribe(d => {
        this.webinar = d;
        console.log(d);
        
      })
    }else{
    this.tafbs.getfilteredwebinarlist<webacc>( this.selectedCourse.id,e).subscribe(d => {
      this.webinar = d;
      console.log(d);
      
    })
  }
      }
}
