import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TAResources } from 'src/app/Interface/resources';

@Component({
  selector: 'app-question-dd',
  templateUrl: './question-dd.component.html',
  styleUrls: ['./question-dd.component.css']
})
export class QuestionDdComponent implements OnInit {

  questionslist: TAResources[];
  _selectedCourse: number;
  _selectedHeading: string;
  ddSize: number = 40;
  SelectedQuestion: TAResources;
  @Output()
  SelectedUsers = new EventEmitter();
  @Input()
  public set SelectedCourse(v: number) {
    this._selectedCourse = v;
  }


  @Input()
  public set SelectedHeading(v: string) {
    this._selectedHeading = v;

  }
  constructor(private tafbs: TekarchFbService) { }

  ngOnInit() {
  }
  onKeyPress(event) {
    this.tafbs.getQuestions<TAResources>("questions", this._selectedCourse, this._selectedHeading).subscribe(data => {
      this.questionslist = this.filterAction(event.query, data);
    });
  }

  filterAction(query, data: TAResources[]): TAResources[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: TAResources[] = [];
    for (let i = 0; i < data.length; i++) {
      let selectedHeading = data[i];
      if (selectedHeading.Heading.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(selectedHeading);
      }
    }
    return filtered;
  }

  //#endregion

  onDropDownSelect(e) {
    this.SelectedUsers.emit(e);
  }
}
