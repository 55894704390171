import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { AuthService } from '../../Auth/auth.service';
import * as firebase from 'firebase';
import { Router } from '@angular/router';
import { TAProgram } from '../../Interface/program';
import { TekarchFbService } from '../../service/tekarchfbservice';
import { TekarchDataService } from 'src/app/service/tekarchdataservice';
import { take } from 'rxjs/operators';
import { TACourse } from 'src/app/Interface/course';
import { TAUser } from 'src/app/Interface/ta-user';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css'],
  animations: [
    trigger('scrollAnimation', [
      state('show', style({
        opacity: 1,
        transform: "translateY(0)"
      })),
      state('hide', style({
        opacity: 0, top: 0,
        transform: "translatey(-100%)"
      })),
      transition('show => hide', animate('300ms ease-out')),
      transition('hide => show', animate('300ms ease-in'))
    ])
  ]
})
export class TopBarComponent implements OnInit {

  showmenu: boolean = false
  profilepic: boolean = true;
  courselist: any;
  text: { name: string; }[];
  state = 'hide'
  click: boolean = false
  LocalUserId: any;
  currentuserRoleName: string;
  private active: boolean = false;
  activemanage: boolean = false
  activecourse: boolean = false
  constructor(public el: ElementRef, public auth: AuthService, public router: Router, private tafbs: TekarchFbService, private tadbs: TekarchDataService) {
    this.getUser()
  }
  getUser() {
    this.tadbs.getLocalUser().subscribe(user => {
      this.currentuserRoleName = user.RoleName;
      this.LocalUserId = user.UserId
      if (user.RoleName == 'Admin' || user.RoleName == 'Trainer') {
        this.tafbs.getCourseList<TACourse>("courses").subscribe(data => {
          this.courselist = data;
        })
      } else {
        this.tafbs.getStudentManagementList<TAProgram>("studentmanagement", this.LocalUserId).subscribe((data:any) => {
          let unique_array = [];
          let unique_array1 = [];
          console.log(data);
          if (data && data.length > 0) {
            for (let index = 0; index < data.length; index++) {
              // console.log('data',data[index].expiryDate.seconds);
              // console.log(new Date(data[index].expiryDate));

              var seconds = new Date().getTime() / 1000
              // console.log(seconds);

              if (data[index] && data[index].expiryDate) {
                console.log('data1', data[index]);
                let currentDate = new Date();
                if (seconds <= data[index].expiryDate.seconds) {
                  if (unique_array1.indexOf(data[index].CourseName) == -1) {
                    unique_array1.push(data[index].CourseName)
                    unique_array.push(data[index])
                  }
                }
              } else {
                console.log('data2', data[index]);
                if (unique_array1.indexOf(data[index].CourseName) == -1) {
                  unique_array1.push(data[index].CourseName)
                  unique_array.push(data[index])
                }
              }

            }

          } else {
            this.router.navigate(["/noaccess"])

          }

          this.courselist = unique_array
        });
      }

    });
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const componentPosition = this.el.nativeElement.offsetTop
    const scrollPosition = window.pageYOffset

    if (scrollPosition > 55) {
      this.state = 'show'
    } else {
      this.state = 'hide'
    }

  }
  ngOnInit() {
    this.text = [{ name: "angular" }, { name: "SQL" }]
    if (window.screen.width <= 768) { // 768px portrait
      this.profilepic = false;
    } else {

    }
  }

}