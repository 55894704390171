// list of programs will be diaplayed 
//  select the program to view the video list


import { Component, OnInit, Input } from '@angular/core';
import { TAProgram } from '../../Interface/program';
import { TekarchFbService } from '../../service/tekarchfbservice';
import { TekarchDataService } from '../../service/tekarchdataservice';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { TAResources } from 'src/app/Interface/resources';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-program-view',
  templateUrl: './program-view.component.html',
  styleUrls: ['./program-view.component.css']
})
export class ProgramViewComponent implements OnInit {

  resource: any;
  resourcelist: any;
  _program: any;
  click: boolean = false;
  showModel: boolean = false
  resourceList: TAResources[];
  currentuserRole: string;
  addResourseDialog: boolean;
  SelectedCourseName: any;
  selectedProgram: any;
  disabled: boolean = true;
  resourseType: any;
  resoursetypename: any;
  ResourceId: number;
  newresourseHeading: string;
  newresourseDescription: string;
  newresourseUrl: string;
  ddReset: any;
  editprogramDialog: boolean;
  ProgramData: TAProgram;
  EditProgramName: string;
  @Input()
  public set Click(v: boolean) {
    if (v) {
      this.click = v;
    }
  }
  @Input()
  public set program(v: TAProgram) {
    this._program = v;
    
    this.tafbs.getResourceProgramList<TAResources>("resources", this._program).pipe(take(1)).subscribe(data => {
      this.UpdateResourceList(data)
    }
    );

  }
  constructor(private tafbs: TekarchFbService, private tads: TekarchDataService, private sanitizer: DomSanitizer, ) {
    this.currentuserRole = this.tads.getCurrentUser().RoleName;

  }

  ngOnInit() {
  }
  openvideo(r) {
    this.resource = r;
    this.showModel = true;

  }
  OpenVideo() {
    const clicktime = new Date().getTime()
    this.tads.setAccordiantime(clicktime)
  }
  UpdateResourceList(_r: TAProgram[]) {
    this.resourcelist = _r;
    for (var n = 0; n < this.resourcelist.length; n++) {
      if (this.resourcelist[n].ResourceUrl !== null && this.resourcelist[n].ResourceUrl !== undefined) {
        this.resourcelist[n].SafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.resourcelist[n].ResourceUrl.replace('watch?v=', 'embed/'));
      }
    }

  }
  addProgramResource(program) {
    const clicktime = new Date().getTime();
    this.tads.setAccordiantime(clicktime);
    this.addResourseDialog = true;
    this.SelectedCourseName = this._program.CourseName;
    this.selectedProgram = this._program;
  }
  AddResoursetypeDD(e) {
    this.resourseType = e;
    this.resoursetypename = e.ResourceTypeName;
  }
  addNewResourse(heading, description, resourceUrl) {
    if (this.resourseType.ResourceTypeName == 'Question') {
      if (heading) {
        this.tafbs.getResourseID<TAResources>("resources").pipe(take(1)).subscribe(async data => {
          this.ResourceId = data[0].ResourceId + 1;
          await this.ResourceId;
          if (this.ResourceId != data[0].ResourceId) {
            await this.tafbs.addResourses(heading, description, resourceUrl, this._program.CourseId, this._program.ProgramId, this.resourseType, this.ResourceId);
          }
        });
      }
      else {
        alert("Please, enter all the required fields")
      }

    } else {
      if (heading && resourceUrl) {
        this.tafbs.getResourseID<TAResources>("resources").pipe(take(1)).subscribe(async data => {
          // if (data.length > 1) {
          this.ResourceId = data[0].ResourceId + 1;
          await this.ResourceId;

          if (this.ResourceId != data[0].ResourceId) {


            this.tafbs.addResourses(heading, description, resourceUrl, this._program.CourseId, this._program.ProgramId, this.resourseType, this.ResourceId);
          }
          // }
        });

      } else {
        alert("Please, enter all the required fields")
      }
    }
    this.newresourseHeading = '';
    this.newresourseDescription = '';
    this.newresourseUrl = '';
    this.ddReset = undefined;
    this.addResourseDialog = false;
  }

  CancelNewResourse() {
    this.newresourseHeading = '';
    this.newresourseDescription = '';
    this.newresourseUrl = '';
    this.ddReset = undefined;
    this.addResourseDialog = false;
  }
  editProgramDialog(e) {

    const clicktime = new Date().getTime();
    this.tads.setAccordiantime(clicktime);
    this.editprogramDialog = true;
    this.ProgramData = e;
    this.EditProgramName = e.ProgramName;
  }
  UpdateProgram(program, editedData) {
    this.tafbs.updateProgramresources(program, editedData);
    this.editprogramDialog = false;
  }
  cancelProgram() {
    this.tafbs.getResourceProgramList<TAResources>("resources", this._program).subscribe(data => {
      this.UpdateResourceList(data)
    });
    this.editprogramDialog = false;
  }
  deleteProgram() {

    this.tafbs.getResoursesList<TAResources>("resources", this._program.CourseId, this._program.ProgramId).pipe(take(1)).subscribe(data => {

      data.forEach(d => {
        this.tafbs.deleteProgramResourses(d);
      })
    });

    this.tafbs.deleteProgram(this._program);
    this.editprogramDialog = false;
  }
  SelectedCourseDD(e) { }
  selectedprogramDD(e) { }
}
