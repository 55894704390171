import { Component, OnInit, Input } from '@angular/core';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-questions-dashboard-answer',
  templateUrl: './questions-dashboard-answer.component.html',
  styleUrls: ['./questions-dashboard-answer.component.css']
})
export class QuestionsDashboardAnswerComponent implements OnInit {

  user: any;
  question: any;
  data: any;
  @Input()
  public set rowData(v: any) {
    this.user = v
  }
  @Input()
  public set colData(v: any) {
    this.question = v
    this.tafbs.getUserAnswerDATA(this.user.UserId, this.question.id).pipe(take(1)).subscribe(d => {
      if (d) {
        this.data = d
      } else {
        this.data = null
      }
    })
  }
  constructor(private tafbs: TekarchFbService) { }

  ngOnInit() {
  }

}
