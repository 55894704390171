import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({name: 'replace'})
export class ReplacePipe implements PipeTransform {
    transform(items: any[], args: string): any {
       
          if (items)
            return items.filter(item => {
    
                var b=item.UserId
                var key=b.replace(/\./g,"_");
               
          item.SearchId=key
          return   item
             
            });
        }
      
}