import { Component, OnInit, Input } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { TACourse } from 'src/app/Interface/course';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TAUser } from 'src/app/Interface/ta-user';
import { Observable } from 'rxjs';
import { TAResources } from 'src/app/Interface/resources';
import { TekarchDataService } from 'src/app/service/tekarchdataservice';
import * as _ from 'lodash';

@Component({
  selector: 'app-interview-que-topic-dashboard',
  templateUrl: './interview-que-topic-dashboard.component.html',
  styleUrls: ['./interview-que-topic-dashboard.component.css']
})
export class InterviewQueTopicDashboardComponent implements OnInit {

  selectedQustion: any;

  types: SelectItem[];
  selectedValue: any = 'FilterByQuestions';
  SelectedCourse: TACourse;
  SelectedCourseId: number;
  SelectedHeading: any;
  SelectedHeadingName: any;
  UserList: any[];
  selecteduser: any;
  selectedUserData: any[];
  // selectedType: string = 'FilterByQuestions';
  cols: any[];
  cars: any[];
  selectedUser: TAUser[];
  selectedHeadingQuestions: any[];
  userListData: TAUser;
  TableData: any[];
  frozenCols: any[];
  scrollableCols: any[];
  induser: any;
  test: any[];
  testing: any;
  tasks: Observable<any[]>;
  aaaa: any;
  frozenCollection: { UserId: any; header: string; }[];
  _courseId: number;
  _heading: string;
  _headingList: TAResources;
  _width: number;

  @Input()
  public set width(v: number) {
    this._width = v;
  }
  @Input()
  public set courseId(v: number) {
    this._courseId = v;
    let unique_array1 = [];
    let unique_array2 = [];
    this.tafbs.getUserCourseUsingId(this._courseId).subscribe(async data => {
      for (let i = 0; i < data.length; i++) {
        if (unique_array1.indexOf(data[i].UserId) == -1) {
          data.map(item=>{
            var b=item.UserId
            var key=b.replace(/\./g,"_");
            item.SearchId=key
              return   item
          })
          unique_array1.push(data[i].UserId);
          unique_array2.push(data[i]);
        }
      }
      let unique_array3 = [];
      unique_array2.forEach(element => {
        this.tafbs.getUserProfile(element.UserId).subscribe(d=>{
    if(d.RoleName=="Student"){
      unique_array3.push(d)
      }   
this.selectedUser = _.sortBy(unique_array3, "UserName");

        })  
     
      });
    })
  }

  @Input()
  public set heading(v: string) {
    this._heading = v;
    this.tafbs.getCourseinterviewQuestions(this._courseId, this._heading).subscribe(async d => {
      this.selectedHeadingQuestions = d;
      await this.selectedHeadingQuestions;
      return this.selectedHeadingQuestions;
    });
  }
  constructor(private tafbs: TekarchFbService, private tds: TekarchDataService) {
    this.types = [
      { label: 'Filter By Questions', value: 'FilterByQuestions' },
      { label: 'Filtered By User', value: 'FilteredByUser' },
    ];
  }

  ngOnInit() {
  }

}

