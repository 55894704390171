import { Component, OnInit, Input } from '@angular/core';
import { TekarchDataService } from 'src/app/service/tekarchdataservice';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TAUser } from 'src/app/Interface/ta-user';
import { TAResources } from 'src/app/Interface/resources';
import { TAProgram } from 'src/app/Interface/program';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-candidate-question-list',
  templateUrl: './candidate-question-list.component.html',
  styleUrls: ['./candidate-question-list.component.css']
})
export class CandidateQuestionListComponent implements OnInit {

  _questionList: TAResources[];
  currentUser: TAUser;
  userAns: any;
  selectedRadioButton: string;
  val2: string = '';
  answerList: any[];
  _questionList1: any;
  aaaa: any;
  _heading: TAResources;
  selectedinputType: any = {};
  rad = [];
  arrayObject: { radiobuttonData: any; }[];
  questionNo: any;
  question: any;
  inputType: { Question: string; id: number }[];
  radiobuttonname: {};
  currentUserRole: string;
  paraAns: string;
  radiButtonData: { label: string; value: string; }[];
  display: boolean;
  EditUserData: TAResources;
  EditUserQuestion: string;
  UserQuestion: any;
  docRef: AngularFirestoreDocument;
  indexNo: any;
  @Input()
  public set questionlist(v: any[]) {
    this._questionList = v;
    this.getAnswer(this._questionList)
  }

  @Input()
  public set heading(v: TAResources) {
    this._heading = v;
  }


  constructor(private tfs: TekarchFbService, private tds: TekarchDataService, public afs: AngularFirestore) {
    this.currentUser = this.tds.getCurrentUser();
    this.currentUserRole = this.tds.getCurrentUser().RoleName;
  }
  getAnswer(Ans) {
    if (Ans)
      Ans.forEach(element => {
        this.tfs.getUserAnswers(this.currentUser, element).subscribe(d => {
          // if (element.Type == 'Radio Button') {
          if (d) {
            this.aaaa = d
            element.selectedAns = this.aaaa.SelectedAnswer
          }
          // }
          // if (element.Type == 'Paragraph') {
          //   if (d) {
          //     this.aaaa = d
          //     element.selectedAns = this.aaaa.SelectedAnswer;
          //     this.paraAns = this.aaaa.SelectedAnswer;
          //   }

          // }

        })
        this._questionList1 = Ans;
      });
  }
  ngOnInit() {

    this.radiButtonData = [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" }
    ]

    this.inputType = [
      { Question: 'input', id: 1 },
      { Question: 'Radio Button', id: 2 },
      { Question: 'calendar', id: 3 },
      { Question: 'Paragraph', id: 4 },
    ];
  }

  trackbyfunction(index, item) {
    return index
  }
  ansRadioButtonClick(ans, selectedAns) {
    this.userAns = ans;
    this.tds.setQuestionDetails(ans);
    this.tfs.adduserAns(this.currentUser, ans, selectedAns);
  }
  onClick(ans, selectedAns) {
    this.tfs.addAnswer(this.currentUser, ans, selectedAns);
  }
  handleChange(e, que) {
    this.tfs.updatevisiblestatus("questions", e.checked, que)
  }

  ddChange(e, n) {
    this.selectedinputType = e;
  }
  deleteRadioButton(e) {
    for (var i = 0; i < this.arrayObject.length; i++) {
      if (this.arrayObject[i].radiobuttonData === e) {
        this.arrayObject.splice(i, 1);
        this.rad.splice(i, 1);
      }
    }
  }
  saveRadioButton(q, t, a) {
    let id = this.afs.createId();
    this.tfs.getCourseQuestionsValue(this._heading.CourseId, this._heading.Heading).pipe(take(1)).subscribe(async d => {
      if (d[0] == undefined) {
        this.questionNo = 1;
      } else {
        this.questionNo = d[0].QuestionNo + 1;
      }
      await this.questionNo;
      await this.tfs.addQuestions(this._heading, q, t, a, id, this.questionNo);
    })

    this.arrayObject = null;
    this.rad = [];
    this.question = null;
  }
  isNullOrEmpty(str) {
    return !str || !str.trim();
  }
  saveQuestions(q) {
    let id = this.afs.createId();
    if (q) {
      q = q.charAt(0).toUpperCase() + q.slice(1);
      if (this.isNullOrEmpty(q)) {
        return;
      } else {
        // this.tfs.getCourseQuestionsValue(this._heading.CourseId, this._heading.Heading).pipe(take(1)).subscribe(async d => {
        //   if (d[0] == undefined) {
        //     this.questionNo = 1;
        //   } else {
        //     this.questionNo = d[0].QuestionNo + 1;
        //   }
        //   this.tfs.getCourseQuestionsIndexValue(this._heading.CourseId, this._heading.Heading).pipe(take(1)).subscribe(async d => {
        //     if (d[0] == undefined) {
        //       this.indexNo = 1;
        //     } else {
        //       this.indexNo = d[0].IndexNo + 1;
        //     }
        //     await this.questionNo;
        this.tfs.addQuestionForUsers(this._heading, q, id, this.questionNo, this.indexNo);
        //   })
        // })
      }
    }

    this.question = null;
  }
  addRadioButton(e) {

    this.rad.push(e);
    this.arrayObject = this.rad.map(function (item) {
      return { radiobuttonData: item };
    });
    this.radiobuttonname = null;
    // localStorage.setItem('key', JSON.stringify(this.arrayObject));
    // this.arrayObject = JSON.parse(localStorage.getItem('services_assigned'));
    // this.localStorageData = localStorage.getItem('key');

  }
  DeleteQuestion(e) {
    this.tfs.deleteQuestion(e);
  }
  savetextArea(q, t) {
    let id = this.afs.createId();
    this.tfs.getCourseQuestionsValue(this._heading.CourseId, this._heading.Heading).pipe(take(1)).subscribe(async d => {
      if (d[0] == undefined) {
        this.questionNo = 1;
      } else {
        this.questionNo = d[0].QuestionNo + 1;
      }
      await this.questionNo;
      let a = null
      await this.tfs.addQuestions(this._heading, q, t, a, id, this.questionNo);
    })
  }
  saveParagraphAns(f, ans) {
    this.tfs.addUserAnsParagraph(this.currentUser, f, ans);

  }
  EditiconClick(field) {
    this.EditUserData = field;
    this.EditUserQuestion = this.EditUserData.Question;
    this.display = true;
  }
  UpdateQuestion(field, qus) {
    this.tfs.getUserCourseId(this._heading).subscribe(d => {
      var result = d.reduce((unique, o) => {
        if (!unique.some(obj => obj.UserId === o.UserId)) {
          unique.push(o);
        }
        return unique;
      }, []);
      result.forEach(data => {

        this.tfs.updateQuestions(field, qus, data);
      })

      // this.tfs.questionUpdate(field, qus);
    })


    this.tfs.questionUpdate(field, qus);
    this.display = false;
  }
  cancelQuestion(field) {
    this.tfs.getCourseQuestions(field.CourseId, field.Heading).subscribe(d => {
      this._questionList = d;
    })
    this.display = false;
  }
  deleteQuestion(field) {
    this.tfs.getUserCourseId(this._heading).subscribe(d => {
      var result = d.reduce((unique, o) => {
        if (!unique.some(obj => obj.UserId === o.UserId)) {
          unique.push(o);
        }
        return unique;
      }, []);
      result.forEach(data => {
        this.tfs.deleteQuestions(field, data);
      })
    })

    this.tfs.deleteQuestion(field);
    this.display = false;
  }

}
