import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TAProgram } from 'src/app/Interface/program';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TACourse } from 'src/app/Interface/course';
import { TAUser } from 'src/app/Interface/ta-user';
import { TAStudentManagement } from 'src/app/Interface/studentmanagement';

@Component({
  selector: 'app-course-user-dd',
  templateUrl: './course-user-dd.component.html',
  styleUrls: ['./course-user-dd.component.css']
})
export class CourseUserDdComponent implements OnInit {

  inputValue: string;
  userlist: TAStudentManagement[];
  SelectedUser: TAStudentManagement;
  _disabled: boolean = false;
  selectedCourse: TACourse;
  ddsize: number =20;
  @Output()
  Selecteduser = new EventEmitter();


  @Input()
  public set ddSize(v : number) {
    this.ddsize = v;
    // console.log(v);  
  }
  multiple: boolean=true;

  empList: Array<any> = [];
  @Input()
  public set Multiple(v: boolean) {
    this.multiple=v;
    // console.log(v);
    
  }

  @Input()
  public set disabled(v: boolean) {
    this._disabled = v;
  }

  @Input()
  public set SelectedCourse(v: TACourse) {
    this.selectedCourse = v;
    // console.log(v);
    
    if(this.selectedCourse){
      this.tafbs.getUserbyCourse<TAStudentManagement>("studentmanagement", this.selectedCourse.CourseName).subscribe(data => {
        this.userlist = data;
        // console.log(data);
        
      });
    }
    this.SelectedUser = null;
  }

 

  @Input()
  public set ddReset(v: string) {

    if (v == null || v == undefined) {
      this.SelectedUser = null;
    }
  }

  constructor(private tafbs: TekarchFbService) {
    // if (this.selectedCourse) {
    //   this.tafbs.getProgrambyCourse<TAStudentManagement>("studentmanagement", this.selectedCourse.CourseName).subscribe(data => {
    //     setTimeout(() => {
    //       this.userlist = data;
    //     }, 100)
    //   });
    // }
  }

  ngOnInit() {

  }
  //#region keypress filter 

  onKeyPress(event) {
    this.tafbs.getProgrambyCourse<TAStudentManagement>("studentmanagement", this.selectedCourse.CourseName).subscribe(data => {
      this.userlist = this.filterAction(event.query, data);
    });
  }

  filterAction(query, data: TAStudentManagement[]): TAStudentManagement[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: TAStudentManagement[] = [];
    for (let i = 0; i < data.length; i++) {
      let SelectedUser = data[i];
      if (SelectedUser.UserId.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(SelectedUser);
      }
    }
    return filtered;
  }

  //#endregion

  onDropDownSelect(e) {
    if(this.multiple){
      if (this.empList.indexOf(e) === -1) {
        this.empList.push(e);
        this.Selecteduser.emit(this.empList);
      }
    }else{
      // console.log(e);
      this.tafbs.getUserProfile(e.UserId).subscribe(d=>{
        // console.log(d);
        this.Selecteduser.emit(d);
      })
    }
    
    
  }
  onDropDownUnSelect(e) {
    if(this.multiple){
    const index = this.empList.findIndex(data => data.UserId === e.UserId);
    this.empList.splice(index, 1); 
    this.Selecteduser.emit(this.empList);
    }
  }
  onDropdownClick(e) {
    this.tafbs.getProgrambyCourse<TAProgram>("studentmanagement", this.selectedCourse.CourseName).subscribe(data => {
      this.userlist = data;
    });
  }


}
