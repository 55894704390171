import { Component, OnInit } from '@angular/core';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TekarchDataService } from 'src/app/service/tekarchdataservice';
import { TAUser } from 'src/app/Interface/ta-user';
import { TACourse } from 'src/app/Interface/course';
import { TAProgram } from 'src/app/Interface/program';
import { Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { TAResources } from 'src/app/Interface/resources';
import { take } from 'rxjs/operators';
import * as firebase from "firebase/app";

@Component({
  selector: 'app-interview-preperation',
  templateUrl: './interview-preperation.component.html',
  styleUrls: ['./interview-preperation.component.css']
})
export class InterviewPreperationComponent implements OnInit {
  tableDialog: boolean;
  SelectedStudent: any;
  SelectedCourse: any;
  currentUser:TAUser;
  interviewQuelist: any[];
  selectedCourse: any;
  selectedCourseId: any;
  accordion: string;
  routeurl: NavigationStart;
  myarray: any[];
  IHList: any[];
  ResourceId: number;
  resourseType: { ResourceTypeId: number; ResourceTypeName: string; };
  resourceUrl: string;
  description: string;
  interviewheading: string;
  currentUserrole: string;

  constructor(private route: ActivatedRoute, private tafbs: TekarchFbService, private db: AngularFirestore, private tads: TekarchDataService, ) {
    this.currentUser = this.tads.getCurrentUser()
    this.currentUserrole=this.tads.getCurrentUser().RoleName
    this.route.params.subscribe(p => console.log(p));
   }
  
  ngOnInit() {
    if( this.currentUserrole=="Student"){
      this.getuserdata()
    }
 
  }
  
  Accordian(e) {
    const clicktime = new Date().getTime()
    if (clicktime - this.tads.getAccordiantime() < 1000) {
      return
    } else {
      if (e) {
        if (e == this.tads.getviewprogram()) {
          this.tads.setviewprogram("null");
          this.accordion = "null"
        }
        else {
          this.accordion = e
          this.tads.setviewprogram(e);
        }
      }

    }
  }
  onKeyPress(event) {

    this.tafbs.getIntervHeading("InterviewQuestionList").subscribe(data => {

      this.myarray = data

      if (this.interviewQuelist && this.interviewQuelist.length > 0) {
        for (var i = this.myarray.length - 1; i >= 0; i--) {
          for (var j = 0; j < this.interviewQuelist.length; j++) {
            if (this.myarray[i] && (this.myarray[i].id == this.interviewQuelist[j].Heading)) {
              this.myarray.splice(i, 1);
              this.IHList = this.filterAction(event.query, this.myarray);
            } else {
              this.IHList = this.filterAction(event.query, this.myarray);
            }
          }
        }
      }
      else {
        this.IHList =
          this.filterAction(event.query, this.myarray);
      }
    })
  }

  filterAction(query, data: any[]): any[] {
    let filtered: any[] = [];
    for (let i = 0; i < data.length; i++) {
      let interviewheading = data[i];
      if (interviewheading.id.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(interviewheading);
      }
    }
    return filtered;
  }
  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }
  saveinterviewHeading(heading) {
    if (heading.id) {
      let id = this.db.createId();
      if (this.isNullOrEmpty(heading.id)) {
        return;
      } else {
        
          // this.selectedCourseId = this.selectedCourse.CourseId;
        
            this.tafbs.updateQuestionHeadinguser(id,this.SelectedStudent.UserId, heading.id, this.description, this.resourceUrl, this.selectedCourseId, this.resourseType, this.ResourceId);
            // const userCollectionRef = this.db.collection('QuestionList/'+heading.id+"/questions")
            this.tafbs.getheadingQueList<TAResources>("InterviewQuestionList", heading.id).pipe(take(1)).subscribe(d => {
              let batch = this.db.firestore.batch();
              d.forEach((userDocRef, index) => {
                const timestamp = this.timestamp;
                this.db.collection("userquestions").doc(this.SelectedStudent.UserId).collection("que").add({
                  Question: userDocRef.Question,
                  HeadingId: id,
                  visible: true,
                  Heading: heading.id,
                  CourseId: this.selectedCourseId,
                  ResourceTypeName: "UserQuestion",
                  createdAt: timestamp,
                  IndexNo: index + 1
                })
              }); batch.commit().catch(err => console.error(err));
            })
     
        
        this.interviewheading = '';
      }
    }
    else if (heading) {
      heading = heading.charAt(0).toUpperCase() + heading.slice(1);
      if (this.isNullOrEmpty(heading)) {
        return;
      } else {
       
          this.selectedCourseId = this.SelectedCourse.CourseId;
        
            this.tafbs.addQuestionHeadinguser(heading,this.SelectedStudent.UserId, this.description, this.resourceUrl, this.selectedCourseId, this.resourseType, this.ResourceId);
        
     
        this.interviewheading = '';
      }
    }
    else {
      alert("Please, enter all the required fields")
    }
  }
  isNullOrEmpty(str) {
    return !str || !str.trim();
  }
  handleClick(){
    this.tableDialog=true
  }
  getUserDetails(){

  }
  SelectedStudentDD(e) {
    // console.log(e);
   
    this.SelectedStudent = e;
    this.interviewQuelist = []
    var b=this.SelectedStudent.UserId
    var key=b.replace(/\./g,"_");
    // this.tafbs.getCourse<TACourse>("courses", this.SelectedCourse.CourseName).subscribe(data => {
    //   this.selectedCourse = data[0];
    //   this.selectedCourseId = this.selectedCourse.CourseId;
      this.tafbs.getInterviewQuestionResourceuser<TAProgram>("usertopic",this.SelectedStudent.UserId, this.selectedCourse).subscribe(data => {
        if (data) {
          var arrarlist=[]
          for (let index = 0; index < data.length; index++) {
            var element:any = data[index];
            element.ipheadingaccess=element[key]?element[key]:false
            arrarlist.push(element)
          }
      
          
          this.interviewQuelist = arrarlist;
           }
        // this.interviewQuelist = data;
        // console.log(data);
        
      });
    // })  
  }
  SelectedCourseDD(e){
    this.SelectedCourse = e; 
    // console.log(e);
    //  console.log(this.routeurl);
     if(this.currentUser.RoleName=="Student"){
      this.SelectedStudent=this.currentUser
     this.interviewQuelist = [];
     var arrarlist=[]
     var b=this.SelectedStudent.UserId
     var key=b.replace(/\./g,"_");
     this.tafbs.getCourse<TACourse>("courses", this.SelectedCourse.CourseName).subscribe(data => {
       this.selectedCourse = data[0];
       this.selectedCourseId = this.selectedCourse.CourseId;
       this.tafbs.getInterviewQuestionResourceuser<TAProgram>("usertopic",this.SelectedStudent.UserId, this.selectedCourse.CourseId).subscribe(data => {
        if (data) {
          data.forEach(element => {if(element[key])
           arrarlist.push(element);
          });
          this.interviewQuelist=arrarlist
            }
      
          
     
        //  console.log(data);
         
       });
     })  
    }
  }

  filterData<T>(arg0: string, filterData: any) {
    throw new Error("Method not implemented.");
  }
  getuserdata(){
  
    this.interviewQuelist = [];
  //  console.log(this.currentUser);
   
    var b=this.currentUser.UserId
    var key=b.replace(/\./g,"_");
   
      this.tafbs.getInterviewQuestionResourceuser<TAProgram>("usertopic",this.currentUser.UserId, this.selectedCourse).subscribe(data => {
       if (data) {
        var arrarlist=[]
         data.forEach(element => {if(element[key])
          arrarlist.push(element);
         });
         this.interviewQuelist=arrarlist
           }
     
         
    
        // console.log(data);
        
      });
  }

}
