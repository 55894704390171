import { Component, OnInit } from '@angular/core';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TAResources } from 'src/app/Interface/resources';

@Component({
  selector: 'app-assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.css']
})
export class AssignmentComponent implements OnInit {
  SelectedCourse: any;
  SelectedCourseId: any;
  SelectedCourseName: any;
  selectedProgram: any;
  selectedProgramName: any;
  resourseslist: TAResources[];
  selectedHeading:string
  filteredBrands: any[];
  SelectedQueno:number
  SelectedStudent: any;
  studentAssignment: TAResources[];
  constructor(private tafs:TekarchFbService) { }

  ngOnInit() {
  }
//   SelectedCourseDD(e){    
// this.tafs.getResourceProgramListforAss("resources",e.CourseId,"Assignment")
//   }
  SelectedCourseDD(e) {
    this.SelectedCourse = e;
    this.SelectedCourseId = e.CourseId;
    this.SelectedCourseName = e.CourseName;
  }
  selectedprogramDD(e) {
    this.selectedProgram = e;
    this.selectedProgramName = e.ProgramName;

    if (e) {
      this.tafs.getUserProgress<TAResources>("resources", e.CourseId, e.ProgramId).subscribe(data => {
        this.resourseslist = data;
        // this.UpdateResouresList(this.resourseslist);
      })
    }
  }

  filterBrands(event) {
    this.filteredBrands = [];
    for(let i = 0; i < this.resourseslist.length; i++) {
        let brand = this.resourseslist[i].Heading;
        if(brand.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
            this.filteredBrands.push(brand);
        }
    }
}
SelectedStudentDD(e) {
  this.SelectedStudent = e;  
}
getAssignment(){
  this.tafs.getAssValue<TAResources>("Assignment",this.SelectedCourseId,this.selectedProgram.ProgramId,this.selectedHeading,this.SelectedQueno,this.SelectedStudent.UserId).subscribe(d=>{
    this.studentAssignment=d;
  })
}
}
