import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TAResources } from 'src/app/Interface/resources';

@Component({
  selector: 'app-user-filter-dd',
  templateUrl: './user-filter-dd.component.html',
  styleUrls: ['./user-filter-dd.component.css']
})
export class UserFilterDdComponent implements OnInit {
  userlist: any[];
  ddSize: number = 40;
  SelectedUser: any;
  @Output()
  SelectedUsers = new EventEmitter();

  constructor(private tafbs: TekarchFbService) { }

  ngOnInit() {
  }
  onKeyPress(event) {
    this.tafbs.getUser().subscribe(data => {
      this.userlist = this.filterAction(event.query, data);
    });
  }

  filterAction(query, data: any[]): any[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    for (let i = 0; i < data.length; i++) {
      let selecteduser = data[i];
      if (selecteduser.UserId.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(selecteduser);
      }
    }
    return filtered;
  }

  //#endregion

  onDropDownSelect(e) {
    this.SelectedUsers.emit(e);
  }
}
