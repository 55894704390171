import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TAUser } from 'src/app/Interface/ta-user';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TekarchDataService } from 'src/app/service/tekarchdataservice';
import { TAStudentManagement } from 'src/app/Interface/studentmanagement';

@Component({
  selector: 'app-user-course-dd',
  templateUrl: './user-course-dd.component.html',
  styleUrls: ['./user-course-dd.component.css']
})
export class UserCourseDdComponent implements OnInit {

  courseList: TAStudentManagement[];
  selectedStudent: TAStudentManagement[];
  inputValue: string;
  empList: Array<TAStudentManagement> = [];
  _autocom: boolean;
  ddsize: number = 30;
  multiple:boolean=true
  @Output()
  displayChange= new EventEmitter();

  currentUser: TAUser;

  @Input()
  public set Multiple(v: boolean) {
    this.multiple=v;
    
  }
  @Input()
  public set ddSize(v : number) {
    this.ddsize = v;
  }
  



  constructor(private tafbs: TekarchFbService, private tads: TekarchDataService) {
    this.currentUser = this.tads.getCurrentUser()
  }

  ngOnInit() {
    this.tafbs.getCourselistIP<TAStudentManagement>("studentmanagement",this.currentUser.UserId).subscribe(data => {
     this.courseList = this.filterAction('', data);
    })

  }
  onKeyPress(event) {

    this.tafbs.getCourselistIP<TAStudentManagement>("studentmanagement",this.currentUser.UserId).subscribe(data => {
      this.courseList = this.filterAction(event.query, data);
     })
  }

  filterAction(query, data: TAStudentManagement[]): TAStudentManagement[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: TAStudentManagement[] = [];
    for (let i = 0; i < data.length; i++) {
      let selectedStudent = data[i];
      if (selectedStudent.CourseName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(selectedStudent);
      }
    }
    return filtered;
  }

  onDropDownSelect(e) {

    
    this.displayChange.emit(e);
  
  }

 

}
