import { Component, OnInit, Input, HostListener } from '@angular/core';
import { TAResources, TAResourseType } from 'src/app/Interface/resources';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { TAProgram } from 'src/app/Interface/program';
import { TAUser } from 'src/app/Interface/ta-user';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TekarchDataService } from 'src/app/service/tekarchdataservice';
import Player from "@vimeo/player";
import { TACourse } from 'src/app/Interface/course';
import { AudioRecordingService } from 'src/app/service/audio-recording.service';
@Component({
  selector: 'app-program-heading',
  templateUrl: './program-heading.component.html',
  styleUrls: ['./program-heading.component.css']
})
export class ProgramHeadingComponent implements OnInit {
  resource: TAResources;

  currentUrl: SafeResourceUrl;
  resourcelist: TAResources;
  _program: TAProgram;
  showModel: boolean = false
  currentuser: TAUser;
  getuserstatusvalue: TAResources = {};
  startInterval: any;
  playtime: number
  uploaddialog: boolean;
  editresourseDialog: boolean;
  EditData: TAResources;
  courselist: TACourse[];
  resourseTypelist: TAResourseType[];
  programlist: TAProgram[];
  selectedCourse: TACourse;
  editSelectedCourseName: string;
  selectedProgram: TAProgram;
  editselectedProgram: string;
  editSelectedresourseTypeName: any;
  disabled: boolean = true;
  editSelectedHeading: any;
  editSelectedDescription: any;
  editSelectedResourceUrl: any;
  SelectedResource: any;
  deleteResouceConfimation: boolean;
  editResourseDialog: boolean;
  currentUserRole: string;
  editSelectedData: any;
  tableDialog: boolean;
  resourceViewedlist: any;
  data: any
  now: any;
  questionDialog: boolean;
  descriptionDialog: boolean;
  description:any;
  interviewQuestion:string;
  questionList: any;
  editquestionDialog: boolean;
  editSelectedQuestion: any={};
 
  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {

    if (event.key === "Escape") {
      // Do things
      try {
        this.closableIconClick()
      } catch (error) { }
    }
  }
  @Input()
  public set Resource(v: TAResources) {

    let upload1 = v.Heading.toLowerCase().includes("assignment") ? true : false
    v.upload = upload1
    // if( this.currentUserRole=="Student"){
    let date=new Date().getTime()
    console.log(v);
    this.resource = v;
         this.now = v.createdAt.toDate()
// console.log('Today: ' + this.now)
let after180days = new Date(this.now.setDate(this.now.getDate() + 180)).getTime()
// console.log('Previous: ' + after180days.toUTCString())
if( this.currentUserRole=="Student")
if(date<after180days ){
  // console.log(date);
  // console.log(after180days);
  
  
  this.resource.visible=true
  // console.log(this.resource.visible);
  
}
else{
  this.resource.visible=true
}
else{
  this.resource.visible=true
}
  
    // console.log(v);
// } 
  }

  constructor(private tafbs: TekarchFbService, private tads: TekarchDataService) {
      this.currentuser = this.tads.getCurrentUser()
    this.currentUserRole = this.tads.getCurrentUser().RoleName;
    // this.tafbs.getDetailList<TACourse>("courses").subscribe(data => {
    //   this.courselist = data;
    // });
    // this.tafbs.getDetailList<TAResourseType>("resourcetype").subscribe(data => {
    //   this.resourseTypelist = data
    // })
    // this.tafbs.getDetailList<TAProgram>("programs").subscribe(data => {
    //   this.programlist = data
    // })
    
  }

  ngOnInit() {
  
  }
  trackbyfunction(index, item) {
    if (!item) return index

    return item
  }
  UploadDialog() {


    // this.tads.setAccordiantime(clicktime);

    this.uploaddialog = true
  }
  async openvideo(r) {
    // let a= r.Heading.toLowerCase().includes("assignment")?true:false
    // if(a==true){
    //   this.uploaddialog=true
    //   return;
    // } 
    const clicktime = new Date().getTime();
    if (clicktime - this.tads.getAccordiantime() < 1000) {
      return;
    } else {
      this.resource = r;
      this.showModel = true;
      let videoplaytime: number = 0;


      this.getuserstatusvalue = await this.tafbs.getuservideostatus(r, this.currentuser.UserId);
      this.startInterval = setInterval(data => {
        try {
          var iframe = document.querySelector('iframe');
          var player = new Player(iframe);
          this.addTimer(videoplaytime)
          player.getCurrentTime().then(function (seconds) {
            if (videoplaytime <= seconds) {
              videoplaytime = seconds;
            }
          });
        } catch (error) {
          // console.log('this is not a vimeo video');
        }

      }, 1000);
    }


  }
  addTimer(a) {
    this.playtime = a
  }
  async closableIconClick() {
    this.showModel = false;
    this.uploaddialog = false
    clearInterval(this.startInterval);
    var iframe = document.querySelector('iframe');
    try {
      var player = new Player(iframe);
      let videoduration: number = 0;
      let completetiming: number = 0;
      let halfvideoduration: number = 0;
      let average: number = 0
      player.getEnded().then(function (ended) {
        // `ended` indicates whether the video has ended
      });
      await player.getDuration().then(function (duration) {
        // `ended` indicates whether the video has ended
        videoduration = duration
      });
      await player.getCurrentTime().then(function (seconds) {
        // `seconds` indicates the current playback position of the video
        completetiming = seconds
        halfvideoduration = videoduration / 2;
        average = completetiming / videoduration * 100
      });
      // player.getLoop().then(function(loop) {
      //   // `loop` indicates whether the loop behavior is active

      // });
      // player.getVideoTitle().then(function (title) {
      // });
      // if (this.currentuser.RoleName == "Student" && !this.getuserstatusvalue || this.getuserstatusvalue && this.getuserstatusvalue.VideoCompleted == "No") {
      this.updatestatusforvideo(videoduration)
      // }
    } catch (error) {
      console.log('this is not a vimeo video');

    }


  }

  updatestatusforvideo(videoduration) {
    let average: number = 0

    average = (this.playtime / videoduration) * 100

    let avg = this.getuserstatusvalue ? this.getuserstatusvalue.average : 0

    if (avg < average && this.currentuser.RoleName == "Student") {
      this.tafbs.updateUserVideoStatus(this.resource, this.currentuser, average);
      this.tafbs.updateUserVideoStatusforUser(this.resource, this.currentuser, average);
    }
  }
  imgUrl(e) {
  }
  downloadUrl(res) {

  }
  editquestion(res){
    this.editquestionDialog=true
    this.interviewQuestion=res.question
    this.editSelectedQuestion=res
  }
  Updatequestion(que,res){
    console.log(que,res)
this.tafbs.updateinterviewQA(res.uid,que)
this.editquestionDialog=false
this.interviewQuestion==''
// this.tafbs.getinterviewQA(res.id).subscribe((d:any)=>{
//   this.questionList=d
// })
  }
  cancelquestion(){
    this.interviewQuestion==''
    this.editquestionDialog=false
  }
  deletequestion(res){
  if(confirm("Are you sure want to Delete?")){

    console.log(res)
this.tafbs.deleteinterviewQA(res.uid)
  }
  }
  openDesc(res){
    const clicktime = new Date().getTime();
    this.tads.setAccordiantime(clicktime);
    console.log("openDesc")
    this.tafbs.getResourcebyid(res.id).subscribe((d:any)=>{
      console.log(d)
      this.description =d.description
      this.resource.description=d.description
    })
    // this.description =this.resource.description
    this.descriptionDialog=true
  }
  cancelDesc(res){
    this.tafbs.getResourcebyid(res.id).subscribe((d:any)=>{
      console.log(d)
      this.description =d.description
      this.resource.description=d.description
    })
  }
  openQA(res){
    const clicktime = new Date().getTime();
    this.tads.setAccordiantime(clicktime);
    console.log("openQA")
    this.tafbs.getinterviewQA(res.id).subscribe((d:any)=>{
      this.questionList=d
    })
    this.questionDialog=true
  }
  closedescriptionDialog(){
    this.descriptionDialog=false
  }
  closequestionDialog(){
    this.questionDialog=false
  }
  saveInterviewQuestion(res,e){
    if(e&&e.trim()&&e.trim()!=''){
    this.tafbs.addinterviewQA(res.id,e)
    this.tafbs.getResourcebyid(res.id).subscribe((d:any)=>{
      console.log(d)
      this.interviewQuestion=''
      this.resource.questions=d.questions
      this.resource.description =d.description
    })
  }else{
    alert("Please enter Something")
  }
  }
  saveDescription(res,e){
    this.tafbs.addDescription(res.id,e)
    this.tafbs.getResourcebyid(res.id).subscribe((d:any)=>{
      console.log(d)
      this.resource.description =d.description
      this.description=d.description
    })
  }
  viewgraph(e){
    const clicktime = new Date().getTime();
    this.tads.setAccordiantime(clicktime);
    this.tafbs.getSelectedViewedResource(e.ResourceId).subscribe(d => {
      this.resourceViewedlist = d;
      var normalizedArray = this.resourceViewedlist.map(obj=> {
                  return obj.UserName;
                });
                var normalizedvalueArray = this.resourceViewedlist.map(obj=> {
                  return obj.average;
                });
                this.data={labels:normalizedArray,
                datasets: [ 
                          {
                              label: e.Heading,
                              backgroundColor: [
                                "#42A5F5","#9CCC65","#42A5F5","#9CCC65","#42A5F5","#9CCC65","#42A5F5","#9CCC65","#42A5F5","#9CCC65","#42A5F5","#9CCC65","#42A5F5","#9CCC65","#42A5F5","#9CCC65","#42A5F5","#9CCC65","#42A5F5","#9CCC65","#42A5F5","#9CCC65","#42A5F5","#9CCC65","#42A5F5","#9CCC65","#42A5F5","#9CCC65","#42A5F5","#9CCC65","#42A5F5","#9CCC65","#42A5F5","#9CCC65","#42A5F5","#9CCC65","#42A5F5","#9CCC65","#42A5F5","#9CCC65","#42A5F5","#9CCC65","#42A5F5","#9CCC65","#42A5F5","#9CCC65","#42A5F5","#9CCC65","#42A5F5","#9CCC65","#42A5F5","#9CCC65","#42A5F5","#9CCC65","#42A5F5","#9CCC65", ],
                              borderColor: '#1E88E5',
                              data: normalizedvalueArray,
                              
                          }
                      ]}
    })

    
    this.tableDialog=true
  }
  EditResourse(e) {
    const clicktime = new Date().getTime();
    this.tads.setAccordiantime(clicktime);
    this.editresourseDialog = true;

    this.EditData = e;
    this.tafbs.getCourseNameByCourseId(e.CourseId).subscribe(d => {
      this.selectedCourse = d[0];

      this.editSelectedCourseName = this.selectedCourse.CourseName;
    })
    this.tafbs.getProgramNameByProgramId(e.ProgramId).subscribe(d => {
      this.selectedProgram = d[0];

      this.editselectedProgram = this.selectedProgram.ProgramName;
    })
    this.editSelectedresourseTypeName = e.ResourceTypeName;
    this.editSelectedHeading = e.Heading;
    this.editSelectedDescription = e.Description;
    this.editSelectedResourceUrl = e.ResourceUrl;
    // this.UpdateResouresList(this.EditData);
  }

  UpdateResourse(e, heading, description, resourseurl) {

    this.tafbs.updateResourse(e, heading, description, resourseurl);
    this.editresourseDialog = false;

  }
  CancelEditResourse(e) {
    // this.tafbs.getUserProgress<TAResources>("resources", e.CourseId, e.ProgramId).subscribe(data => {
    //   this.resourseslist = data;
    //   this.UpdateResouresList(this.resourseslist);
    // })
    this.EditData = e;
    this.editresourseDialog = false;
  }
  DeleteResourse(e) {
    this.SelectedResource = e;
    // this.tafbs.deleteResourse(e);
    this.deleteResouceConfimation = true;

  }
  deleteResourceConfirmation() {
    this.tafbs.deleteResourse(this.SelectedResource);
    this.editResourseDialog = false;
    this.deleteResouceConfimation = false;
  }
  CancelResourceConfirmation() {
    this.editResourseDialog = false;
    this.deleteResouceConfimation = false;
  }
  selectedprogramDD(e) { }
  SelectedResoursetypeDD(e) { }

 
}
