import { Component, OnInit, Input, AfterViewInit, HostListener } from '@angular/core';
import { TAResources } from 'src/app/Interface/resources';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TekarchDataService } from 'src/app/service/tekarchdataservice';
import { TAUser } from 'src/app/Interface/ta-user';
import { AngularFireUploadTask, AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';


@Component({
  selector: 'app-assignment-heading',
  templateUrl: './assignment-heading.component.html',
  styleUrls: ['./assignment-heading.component.css']
})
export class AssignmentHeadingComponent implements OnInit, AfterViewInit {
  resource: TAResources;
  currentuser: TAUser;
  uploaddialog: boolean;
  showModel: boolean;
  OthersValue: string
  ResolveDetail: boolean;
  click: boolean
  Assignmentstatus: string;
  filteredBrands: any[];
  task: AngularFireUploadTask;
  editAssheadingDialog: boolean = false;
  imagedata:string;
  // Progress monitoring
  percentage: Observable<number>;

  snapshot: Observable<any>;

  // Download URL
  downloadURL: Observable<string>;
  brands: string[] = ['Completed', 'INComplete', 'Completed with Bugs', 'Others'];
  path: string;
  photourl: string;
  resourcedata: TAResources;
  editAssheading: any;
  deleteassign: boolean
  downloarurl: string;
  editimg:any
  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {

    if (event.key === "Escape") {
      // Do things
      try {
        this.closableIconClick()
      } catch (error) { }
    }
  }
  @Input()
  public set Resource(v: TAResources) {

    // let upload1=v.Heading.toLowerCase().includes("assignment")?true:false
    // v.upload=upload1
    this.resourcedata = v;
    // this.tafbs.getAssignmentAnswers(this.resource.id)
  }
  constructor(private tafbs: TekarchFbService, private tads: TekarchDataService, private storage: AngularFireStorage) { }
  
  ngOnInit() {
    
    this.currentuser = this.tads.getCurrentUser()
    this.tafbs.getAssignmentAnswers(this.resourcedata.id, this.currentuser).subscribe(d => {
      this.resource = this.resourcedata;
      this.resource.downloadUrl = d[0] ? d[0].downloadUrl : null;
      this.resource.Assignmentstatus = d[0] ? d[0].Assignmentstatus : null
      this.resource.Path = d[0] ? d[0].Path : null
    })
  }
  ngAfterViewInit() {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

  }
  closableIconClick(){
    this.Assignmentstatus=""
  }
  filterBrands(event) {
    this.filteredBrands = [];
    for (let i = 0; i < this.brands.length; i++) {
      let Assignmentstatus = this.brands[i];
      if (Assignmentstatus.toLowerCase().indexOf(event.query.toLowerCase()) == 0) {
        this.filteredBrands.push(Assignmentstatus);
      }
    }
  }
  UploadDialog() {

    this.uploaddialog = true
  }
  imgUrl(e) {
  }
  opendialog(e) {
    if(this.downloarurl){
      this.resource.downloadUrl=this.downloarurl
    }
    this.editAssheading = e.Assignment;
    // if (e.Question) {
    // let a=document.getElementById("aa")
    // a.innerHTML=e.Question
    // }
    this.click=false
    this.showModel = true;
  }
  SaveQuestion(e, assign) {
    this.tafbs.updateAssignmentQue(e, assign)
  }
  onBasicUpload(e) {
  }
  SaveImages(img,head){
    if(!img){
      return
    }
    var str = img;
    this.imagedata=null
var last = str.substring(str.lastIndexOf("=") + 1, str.length);
var imgurl ="<img src=\"https://drive.google.com/uc?id="+last+"\">"
    this.resource.Question=head+imgurl
  }
  startUpload(event: FileList, status) {
    const file = event.item(0);
    // Client-side validation example
    if (file.type.split("/")[0] !== "image") {
      console.error("unsupported file type :( ");
      // return;
    }

    // The storage path
    if (this.currentuser.RoleName == "Admin") {
      this.path = `Assignment/${this.resource.CourseId}/${this.resource.Heading}/${this.resource.Assignment}/Assignment`;
    } else {
      this.path = `Assignment/${this.resource.CourseId}/${this.resource.Heading}/${this.resource.Assignment}/${this.currentuser.UserId}/Assignment`;
    }


    // Totally optional metadata
    const customMetadata = { app: "My AngularFire-powered PWA!" };

    // The main task
    this.task = this.storage.upload(this.path, file, { customMetadata });
    // setTimeout(() => {
    //   this.storage.ref(this.path).delete();
    // }, 10000);

    // Progress monitoring
    this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges().pipe(
      tap(snap => {
        if (snap.bytesTransferred === snap.totalBytes) {
          // Update firestore on completion
          this.downloadURL = this.storage.ref(this.path).getDownloadURL()
          this.adddata(this.path, snap, this.downloadURL, this.resource.Assignment, status);
        }
      }),
      finalize(

        () =>
          (this.downloadURL = this.storage.ref(this.path).getDownloadURL()),
      )
    );

  }
  async adddata(path, snap, downloadlink, que, status) {
    this.downloadURL = this.storage.ref(path).getDownloadURL()
    this.downloadURL.forEach(data => {

      this.photourl = data;
      // update the photo to the "user" collection
      if (this.currentuser.RoleName == "Admin") {
        // this.tafbs.profilephotoUpdate(this.resource, this.photourl)
        this.tafbs.profilephotoUpdatebyCandidate(this.currentuser, this.resource, this.photourl, this.path, status)
      }
      else {
        if (que) {
          this.tafbs.profilephotoUpdatebyCandidate(this.currentuser, this.resource, this.photourl, this.path, status)
        } else {
          alert("Enter Question number")
        }
      }      
      this.click=false
      this.ResolveDetail=false
    })
  }
  resolve(e){
    // this.Assignmentstatus=e.Assignmentstatus
//     this.downloarurl=e.downloadUrl
//  e.downloadUrl=null
 
  }
  editAssignmentHeading(e) {
    this.editAssheading = e.Assignment;
    this.editAssheadingDialog = true;
    const clicktime = new Date().getTime()
    this.tads.setAssignmentClick(clicktime);
  }
  UpdateAssHeading(h, ed) {
    this.tafbs.getUserCourseId(h).subscribe(d => {
      var result = d.reduce((unique, o) => {
        if (!unique.some(obj => obj.UserId === o.UserId)) {
          unique.push(o);
        }
        return unique;
      }, []);
      result.forEach(data => {
        this.tafbs.editAssignmentHeadingsUserAns(h, ed, data.UserId);
      })
    })
    this.tafbs.editAssignmentHeadings(h, ed);
    this.editAssheadingDialog = false;
  }
  cancelAssHeading() {
    this.showModel = false;
    this.tafbs.getAssignmentAnswers(this.resourcedata.id, this.currentuser).subscribe(d => {
      this.resource = this.resourcedata;
      this.resource.downloadUrl = d[0] ? d[0].downloadUrl : null;
      this.resource.Assignmentstatus = d[0] ? d[0].Assignmentstatus : null

    })
  }
  deleteAssHeading(r) {
    this.tafbs.getUserCourseId(r).subscribe(d => {
      var result = d.reduce((unique, o) => {
        if (!unique.some(obj => obj.UserId === o.UserId)) {
          unique.push(o);
        }
        return unique;
      }, []);
      result.forEach(data => {
        this.tafbs.deleteAssignmentHeadingsUserAns(r, data);
        // this.tafbs.editAssignmentHeadingsUserAns(h, ed, data.UserId);
      })
    })
    this.tafbs.deleteAssignmentHeadings(r)
    // this.tafbs.editAssignmentHeadings(h, ed);
    this.showModel = false;
  }
  deleteAssignment(e) {
    this.tafbs.deleteAssignmentAns(this.currentuser, e)
    this.storage.ref(e.Path).delete();
  }
}
