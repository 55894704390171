import { Component, OnInit, Input, ViewChild, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TekarchDataService } from 'src/app/service/tekarchdataservice';
import { TAResources } from 'src/app/Interface/resources';
import { TreeNode } from 'primeng/api';
import * as _ from 'lodash';

@Component({
  selector: 'app-candidate-dashboard',
  templateUrl: './candidate-dashboard.component.html',
  styleUrls: ['./candidate-dashboard.component.css']
})
export class CandidateDashboardComponent implements OnInit,OnDestroy,AfterViewInit {
  count: number = 0;
  _courseId: number;
  selectedUser: any[];
  _heading: string;
  selectedHeadingQuestions: any[];
  _headingList: TAResources[];
  headings: string;
  headingId: string;
  headingName: string;
  newAnswer: TreeNode[];
  cols: any[];
  qus: any;
  childQuestions: any;

  cars: any[];
  rowGroupMetadata: any;
  allSelectedCourseQuestions: any[];
  _selectedButtonValue: string;
  task: Promise<void>;
  userlist: any[];


  @Input()
  public set courseId(v: number) {
    this._courseId = v;

    let unique_array1 = [];
    let unique_array2 = [];
    this.tafbs.getUserCourseUsingId(this._courseId).subscribe(async data => {
      for (let i = 0; i < data.length; i++) {
        if (unique_array1.indexOf(data[i].UserId) == -1) {
          unique_array1.push(data[i].UserId);
          unique_array2.push(data[i]);
        }
      }
      let unique_array3 = [];
      unique_array2.forEach(element => {
        this.tafbs.getUserProfile(element.UserId).subscribe(d=>{
    if(d.RoleName=="Student"){
      unique_array3.push(d)
      }   
this.selectedUser = _.sortBy(unique_array3, "UserName");

        })  
      // this.selectedUser = unique_array2;
      // console.log(this.selectedUser);

      // return this.selectedUser

    })
  })
  }

  @Input()
  public set heading(v: string) {
    this._heading = v;


    this.tafbs.getCourseQuestions(this._courseId, this._heading).subscribe(async d => {
      this.selectedHeadingQuestions = d;
      await this.selectedHeadingQuestions;
      return this.selectedHeadingQuestions;
    });
  }

  @Input()
  public set headingList(v: TAResources[]) {
    this._headingList = v;
  }


  @Input()
  public set selectedButtonValue(v: string) {
    this._selectedButtonValue = v;
    if (this._selectedButtonValue == 'Questions') {
      this.tafbs.getAllCourseQuestions(this._courseId).subscribe(data => {
        this.allSelectedCourseQuestions = data;
        // console.log(data);
        
        this.updateRowGroupMetaData();
      })
    } else if (this._selectedButtonValue == 'InterviewQuestions') {
      this.tafbs.getAllCourseInterviewQuestions(this._courseId).subscribe(data => {
        this.allSelectedCourseQuestions = data;
        // console.log(data);
        
        this.updateRowGroupMetaData();
      })
    }
  }

  constructor(private tafbs: TekarchFbService, private tds: TekarchDataService,private cdr: ChangeDetectorRef) {

  }

  ngAfterViewInit() {
   this.userlist=this.selectedUser
    this.cdr.detectChanges();
  }
  ngOnInit() {
    this.cols = [
      { field: 'Question', header: 'Question' },
      // { field: 'arnish.chakma@gmail.com', header: 'Year' },
      // { field: 'bhakti2211@gmail.com', header: 'Brand' },
      // { field: 'bmounikareddy223@gmail.com', header: 'Color' },
      // { field: 'arnish.chakma@gmail.com', header: 'Year' },
      // { field: 'bhakti2211@gmail.com', header: 'Brand' },
      // { field: 'bmounikareddy223@gmail.com', header: 'Color' },
      // { field: 'arnish.chakma@gmail.com', header: 'Year' },
      // { field: 'bhakti2211@gmail.com', header: 'Brand' },
      // { field: 'bmounikareddy223@gmail.com', header: 'Color' },
      // { field: 'arnish.chakma@gmail.com', header: 'Year' },
      // { field: 'bhakti2211@gmail.com', header: 'Brand' },
      // { field: 'bmounikareddy223@gmail.com', header: 'Color' },
      // { field: 'arnish.chakma@gmail.com', header: 'Year' },
      // { field: 'bhakti2211@gmail.com', header: 'Brand' },
      { field: 'bmounikareddy223@gmail.com', header: 'Color' },
      { field: 'arnish.chakma@gmail.com', header: 'Year' },
      { field: 'bhakti2211@gmail.com', header: 'Brand' },
      { field: 'bmounikareddy223@gmail.com', header: 'Color' },
      { field: 'arnish.chakma@gmail.com', header: 'Year' },
      { field: 'bhakti2211@gmail.com', header: 'Brand' },
      { field: 'bmounikareddy223@gmail.com', header: 'Color' },
      { field: 'arnish.chakma@gmail.com', header: 'Year' },
      { field: 'bhakti2211@gmail.com', header: 'Brand' },
      { field: 'bmounikareddy223@gmail.com', header: 'Color' },
      { field: 'arnish.chakma@gmail.com', header: 'Year' },
      { field: 'bhakti2211@gmail.com', header: 'Brand' },
      { field: 'bmounikareddy223@gmail.com', header: 'Color' },
      { field: 'arnish.chakma@gmail.com', header: 'Year' },
      { field: 'bhakti2211@gmail.com', header: 'Brand' },
      { field: 'bmounikareddy223@gmail.com', header: 'Color' },
      { field: 'arnish.chakma@gmail.com', header: 'Year' },
      { field: 'bhakti2211@gmail.com', header: 'Brand' },
      { field: 'bmounikareddy223@gmail.com', header: 'Color' },
      { field: 'arnish.chakma@gmail.com', header: 'Year' },
      { field: 'bhakti2211@gmail.com', header: 'Brand' },
      { field: 'bmounikareddy223@gmail.com', header: 'Color' },
      { field: 'arnish.chakma@gmail.com', header: 'Year' },
      { field: 'bhakti2211@gmail.com', header: 'Brand' },
      { field: 'bmounikareddy223@gmail.com', header: 'Color' },
      { field: 'arnish.chakma@gmail.com', header: 'Year' },
      { field: 'bhakti2211@gmail.com', header: 'Brand' },
      { field: 'bmounikareddy223@gmail.com', header: 'Color' },
      { field: 'arnish.chakma@gmail.com', header: 'Year' },
      { field: 'bhakti2211@gmail.com', header: 'Brand' },
      { field: 'bmounikareddy223@gmail.com', header: 'Color' },
      { field: 'arnish.chakma@gmail.com', header: 'Year' },
      { field: 'bhakti2211@gmail.com', header: 'Brand' },
      { field: 'bmounikareddy223@gmail.com', header: 'Color' },
      { field: 'arnish.chakma@gmail.com', header: 'Year' },
      { field: 'bhakti2211@gmail.com', header: 'Brand' },
      { field: 'bmounikareddy223@gmail.com', header: 'Color' },
      { field: 'arnish.chakma@gmail.com', header: 'Year' },
      { field: 'bhakti2211@gmail.com', header: 'Brand' },
      { field: 'bmounikareddy223@gmail.com', header: 'Color' },
      { field: 'arnish.chakma@gmail.com', header: 'Year' },
      { field: 'bhakti2211@gmail.com', header: 'Brand' },
      { field: 'bmounikareddy223@gmail.com', header: 'Color' },
      { field: 'arnish.chakma@gmail.com', header: 'Year' },
      { field: 'bhakti2211@gmail.com', header: 'Brand' },
      { field: 'bmounikareddy223@gmail.com', header: 'Color' },
      { field: 'arnish.chakma@gmail.com', header: 'Year' },
      { field: 'bhakti2211@gmail.com', header: 'Brand' },
      { field: 'bmounikareddy223@gmail.com', header: 'Color' }
    ];
  this.task=  this.tafbs.getCarsMedium().then(cars => {
      this.cars = cars;
    });

  }

  onSort() {
    this.updateRowGroupMetaData();
  }

  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};
    if (this.allSelectedCourseQuestions) {
      for (let i = 0; i < this.allSelectedCourseQuestions.length; i++) {
        let rowData = this.allSelectedCourseQuestions[i];
        let Heading = rowData.Heading;
        // console.log(Heading);
        
        if (i == 0) {
          this.rowGroupMetadata[Heading] = { index: 0, size: 1 };
        }
        else {
          let previousRowData = this.allSelectedCourseQuestions[i - 1];
          let previousRowGroup = previousRowData.Heading;
          if (Heading === previousRowGroup)
            this.rowGroupMetadata[Heading].size++;
          else
            this.rowGroupMetadata[Heading] = { index: i, size: 1 };
        }
      }
    }
  }
ngOnDestroy(): void {
  //Called once, before the instance is destroyed.
  //Add 'implements OnDestroy' to the class.
  // this.task.unsubscribe()
}
}


