import { Component, OnInit, Input } from '@angular/core';
import { TAResources } from 'src/app/Interface/resources';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { AudioRecordingService } from 'src/app/service/audio-recording.service';
import { DomSanitizer } from '@angular/platform-browser';
import { InterviewAudioRecordingService } from 'src/app/service/interview-audio-service';

@Component({
  selector: 'app-interview-audio-recoder',
  templateUrl: './interview-audio-recoder.component.html',
  styleUrls: ['./interview-audio-recoder.component.css']
})
export class InterviewAudioRecoderComponent implements OnInit {
  
  isRecording = false;
  starRrec:boolean=false
  recordedTime;
  blobUrl;
  question: TAResources;
  deleteicon: boolean;
  audioLink: string;
  @Input()
  public set Question(v : TAResources) {
    this.question = v;
    // console.log(this.question);
    this.audioLink = this.question.AudioUrl; 
  }
  constructor(private tafbs: TekarchFbService,private audioRecording: InterviewAudioRecordingService,private sanitizer: DomSanitizer) {
    // this.currentuserRole = this.tds.getCurrentUser().RoleName;
    this.audioRecording.recordingFailed().subscribe(() => {
      this.isRecording = false;
    });

    this.audioRecording.getRecordedTime().subscribe((time) => {
      this.recordedTime = time;
      // console.log(this.recordedTime);
      
    });

    this.audioRecording.getRecordedBlob().subscribe((data) => {
     
      
      this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.blob));
      // console.log( this.blobUrl);
    });
   }

  ngOnInit() {
  }
  startRecording() {
    this.starRrec=true
    if (!this.isRecording) {
      this.isRecording = true;
      this.audioRecording.startRecording();
    }
  }

  abortRecording() {
    if (this.isRecording) {
      this.isRecording = false;
      this.audioRecording.abortRecording();
    }
  }

  stopRecording() {
    this.starRrec=false
    if (this.isRecording) {
      this.audioRecording.stopRecording(this.question);
      this.isRecording = false;
    }
  }
  deleterec(){
    // console.log('sccccccccc');
    this.deleteicon=true
  }
  deleterec1(){
    this.deleteicon=false
  }
  deleteRecording(){
    this.tafbs.deleteInterviewAudio(this.question)
  }
}
