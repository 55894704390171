import { Injectable } from "@angular/core";
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router
} from "@angular/router";

import { AuthService } from "./auth.service";
import { Observable } from "rxjs";

@Injectable()
export class AdminGuard implements CanActivate {
    constructor(private auth: AuthService, private router: Router) {}

    canActivate(
        next : ActivatedRouteSnapshot,
        state: RouterStateSnapshot
        )    : Observable<boolean> | boolean {
        let retVal = this.auth.IsAdmin();
        if (!retVal) {
            this.router.navigate([""]);
        }
        return retVal;
    }
}
