import { Component, OnInit, HostListener } from '@angular/core';
import { TACourse } from '../../Interface/course';
import { TekarchFbService } from '../../service/tekarchfbservice';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-manage-course',
  templateUrl: './manage-course.component.html',
  styleUrls: ['./manage-course.component.css']
})
export class ManageCourseComponent implements OnInit {

  cols: { field: string; header: string; }[];
  editdialogVisible: boolean = false;
  courselist: TACourse[];
  editselectedCourse: TACourse;
  newcourse: TACourse = {};
  adddialogVisible: boolean = false;
  CourseId: number;
  deleteConfimationdialog: boolean;
  selectedCoursename: any;

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
        // Do things
        try {
          this.CancelEditCourse(this.editselectedCourse)
          this.deleteConfimationdialog = false;
          this.CancelCourse()
        } catch (error) {}
    }
}


  constructor(private tafbs: TekarchFbService) {
    // get course list
    this.tafbs.getDetailList<TACourse>("courses").subscribe(data => {
      this.courselist = data;
    });
  }

  ngOnInit() {
    // table header
    this.cols = [
      { field: "CourseName", header: "Course" },
      { field: "Description", header: "Description" }
    ];
  }
  SelectedRow(e) {
    this.editselectedCourse = e;
    this.editdialogVisible = true;
  }
  UpdateCourse(e) {
    this.tafbs.updateCourseDetails(e);
    this.editdialogVisible = false;
  }
  CancelEditCourse(e) {
    this.tafbs.getDetailList<TACourse>("courses").subscribe(data => {
      this.courselist = data;
    });
    this.editdialogVisible = false;
    this.editselectedCourse = {};
  }
  AddCourse() {
    this.adddialogVisible = true;
  }
  AddCourseDetail(e) {
    //  add course details
    // if course details is null it return
    if (e.CourseName) {
      this.tafbs.getCourseID<TACourse>("courses").pipe(take(1)).subscribe(data => {
        this.CourseId = data[0].CourseId + 1;
        if (this.CourseId != data[0].CourseId) {
          if(this.isNullOrEmpty(e.CourseName) ){
          alert("Please enter any character")
            return
          }else{
            this.tafbs.addCourse(e, this.CourseId);
            this.newcourse = {};
            this.adddialogVisible = false;
          }
         
        }
      });
    } else {
      alert("Please, enter all the required fields");
    }
  }
  CancelCourse() {
    this.newcourse = {};
    this.adddialogVisible = false;
  }
  deleteCourse(e) {
    this.selectedCoursename = e;
    this.deleteConfimationdialog = true;
  }
  deleteCourseConfirmation() {
    this.tafbs.deleteCourse(this.selectedCoursename);
    this.editdialogVisible = false;
    this.deleteConfimationdialog = false;
  }
  CancelCourseConfirmation() {
    this.editdialogVisible = false;
    this.deleteConfimationdialog = false;
  }
  isNullOrEmpty(str) {
    // check the data is empty or white spaces
    return !str || !str.trim();
}
}
