import { Injectable } from '@angular/core';
import { TAUser } from '../Interface/ta-user';
import { FirestoreService } from './firestoreservice';
import { TekarchDataService } from './tekarchdataservice';
import { AngularFirestore } from '@angular/fire/firestore';
import { TAResources } from '../Interface/resources';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InterviewQaService {

  currentuser: TAUser;

  constructor(private db: FirestoreService, private tads: TekarchDataService, private http: HttpClient, public afs: AngularFirestore) {
    this.currentuser = this.tads.getCurrentUser()
  }
  getInterviewQAList(){
    return this.db.colWithIds$("InterviewQAList",res=>res.orderBy("name"));
  }
  getuserInterviewQAList(userid:string){
    let now = new Date().getTime()
    // console.log('Today: ' + now.toUTCString())
// let last60days = new Date(now.setDate(now.getDate() - 60))
// console.log('Previous: ' + new Date(last60days.toUTCString()).getTime())
// console.log("90days"+new Date(now.setDate(now.getDate() - 90)).getTime());
// let date=new Date(last60days.toUTCString()).getTime();

    // return this.db.colWithIds$("dates/"+this.currentuser.UserId+"/date");
    return this.db.colWithIds$("visibledates/"+userid+"/date",res=>res.where("questiondate",">=",now));
  }
  updateIndexno(topic:string,id:string,id2:string,val:number){
    // console.log(topic);
    // console.log(id);
    // console.log(val);
    let val1=val+2
    return Promise.all([
 this.db.partialUpsert("InterviewQAList/" +topic + "/questions/" + id, {
  SortBy:val
}),
this.db.partialUpsert("InterviewQAList/" +topic + "/questions/" + id2, {
  SortBy:val1
})
    ])
  }
  getselectedInterviewQADates(id:string,userid:string){
    let now = new Date().getTime()
    // console.log('Today: ' + now.toUTCString())
// let last60days = new Date(now.setDate(now.getDate() - 60))
// console.log('Previous: ' + new Date(last60days.toUTCString()).getTime())
// console.log("90days"+new Date(now.setDate(now.getDate() - 90)).getTime());
// let date=new Date(last60days.toUTCString()).getTime();

    return this.db.colWithIds$("visibledates/" + userid + "/date",res=>res.where("id","==",id).where("questiondate",">=",now));
  }
  addInterviewQAHeading(heading:string){
    return this.db.add("InterviewQAList/" ,{
      name:heading
    });
  }
  getuserInterviewQAheading(headingid:string){
    return this.db.doc$("InterviewQAList/" + headingid);
  }
  getselectedInterviewQAquestions(headingid: string,){
    // return this.db.colWithIds$("InterviewQAList/" + headingid + "/questions",res=>res.orderBy("index").orderBy("updatedAt"));
    return this.db.colWithIds$("InterviewQAList/" + headingid + "/questions",res=>res.orderBy("SortBy"));
  }
  updateInterviewQAHeading(headinglist:any,heading:string){
    return this.db.partialUpdate("InterviewQAList/"+headinglist.id+"/" ,{
      name:heading
    });
  }
  updateInterviewQAques(headingid: string,question:string,id:string){
    return this.db.partialUpdate("InterviewQAList/" + headingid + "/questions/" + id, {
    Question:question
    })
  }
  updateInterviewQAAns(headingid: string,question:string,id:string){
    return this.db.partialUpdate("InterviewQAList/" + headingid + "/questions/" + id, {
    Answer:question
    })
  }
  deleteInterviewQAques(headingid: string,id:string){
    return this.db.delete("InterviewQAList/" + headingid + "/questions/" + id);
  }
  deleteInterviewQAHeading(headingid: string){
    return this.db.delete("InterviewQAList/" + headingid);
  }
  deleteInterviewQAAudio(que:TAResources,heading:string){
    return this.db.partialUpdate("InterviewQAList/"+heading+"/questions/"+que.id,{AudioUrl:null})
   }
   InterviewQAAudioUpdate(question:TAResources, url: any,heading:string) {
    // console.log(question);
    
    return this.db.partialUpdate("InterviewQAList/"+heading+"/questions/" + question.id,{ AudioUrl: url });
  }
  addInterviewQAQuestion(headingid: string,id:string,question:string){
    // console.log(new Date().getTime());
    
    return this.db.partialUpdate("InterviewQAList/" + headingid + "/questions/" + id, {
      Question:question,
      createdAt:new Date(),
      SortBy:new Date().getTime()
    })
  }
 
}
