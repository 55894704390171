import { Component, OnInit, HostListener } from '@angular/core';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TAResources, TAResourseType } from 'src/app/Interface/resources';
import { TACourse } from 'src/app/Interface/course';
import { TAProgram } from 'src/app/Interface/program';
import { TekarchDataService } from 'src/app/service/tekarchdataservice';
import { take } from 'rxjs/operators';
import { async } from 'q';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-manage-program-resource',
  templateUrl: './manage-program-resource.component.html',
  styleUrls: ['./manage-program-resource.component.css']
})
export class ManageProgramResourceComponent implements OnInit {
  resourseslist: TAResources[];
  cols: { field: string; header: string; }[];
  courselist: TACourse[];
  SelectedCourse: TACourse;
  selectedProgram: TAProgram;
  SelectedCourseName: string;
  editSelectedData: TAResources;
  coursename: string;
  editSelectedCourseName: string;
  editSelectedProgramName: string;
  resoursetypename: string;
  editSelectedresourseType: string;
  editSelectedresourseTypeName: string;
  resourseType: TAResourseType;
  SelectedResoursetypeDD: any;
  resourseTypelist: TAResourseType[];
  programlist: TAProgram[];
  editSelectedHeading: string;
  editSelectedResourceUrl: string;
  editSelectedDescription: string;
  disabled: boolean = true;
  addResourseDialog: boolean;
  editResourseDialog: boolean;
  newresourseHeading: string;
  newresourseDescription: string;
  newresourseUrl: string;
  _firebaseResourcesUrl: any;
  ddReset: any;
  selectedProgramName: any;
  SelectedCourseId: any;
  deleteResouceConfimation: boolean = false;
  SelectedResource: TAResources;
  ResourceId: number;

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      // Do things on escape key press
      try {
        this.CancelEditResourse(this.editSelectedData)
        this.CancelNewResourse();
        this.deleteResouceConfimation = false;
      } catch (error) {

      }

    }
  }

  constructor(private tafbs: TekarchFbService, private tads: TekarchDataService, private afs: AngularFirestore) {

    this.tafbs.getDetailList<TACourse>("courses").subscribe(data => {
      this.courselist = data;
    });
    this.tafbs.getDetailList<TAResourseType>("resourcetype").subscribe(data => {
      this.resourseTypelist = data
    })
    this.tafbs.getDetailList<TAProgram>("programs").subscribe(data => {
      this.programlist = data
    })
    this._firebaseResourcesUrl = this.afs.collection("resources").valueChanges()
  }

  ngOnInit() {
    this.cols = [
      { field: "ResourceTypeName", header: "ResourceTypeName" },
      { field: "Description", header: "Description" },
      { field: "Heading", header: "Heading" }
    ];
  }
  SelectedCourseDD(e) {
    this.SelectedCourse = e;
    this.SelectedCourseId = e.CourseId;
    this.SelectedCourseName = e.CourseName;
  }
  selectedprogramDD(e) {
    this.selectedProgram = e;
    this.selectedProgramName = e.ProgramName;

    if (e) {
      this.tafbs.getUserProgress<TAResources>("resources", e.CourseId, e.ProgramId).subscribe(data => {
        this.resourseslist = data;
        this.UpdateResouresList(this.resourseslist);
      })
    }
  }
  UpdateResouresList(resourceList: TAResources[]) {
    this.resourseslist = resourceList;

    for (let i = 0; i < this.resourseslist.length; i++) {
      for (let j = 0; j < this.courselist.length; j++) {
        if (this.resourseslist[i].CourseId === this.courselist[j].CourseId) {
          this.resourseslist[i].CourseName = this.courselist[j].CourseName;
        }
        for (let k = 0; k < this.programlist.length; k++) {
          if (this.resourseslist[i].ProgramId === this.programlist[k].ProgramId) {
            this.resourseslist[i].ProgramName = this.programlist[k].ProgramName;
          }
          for (let l = 0; l < this.resourseTypelist.length; l++) {
            if (this.resourseslist[i].ResourceTypeId === this.resourseTypelist[l].ResourceTypeId) {
              this.resourseslist[i].ResourceTypeName = this.resourseTypelist[l].ResourceTypeName; // filtered to display resourcetypename
            }
          }
        }
      }
    }
  }
  AddResourse() {
    this.tafbs.getUserProgress<TAResources>("resources", this.SelectedCourse.CourseId, this.selectedProgram.ProgramId).subscribe(data => {
      this.resourseslist = data;
      this.UpdateResouresList(this.resourseslist);
    })
    this.ddReset = null;
    this.addResourseDialog = true;
  }
  SelectedRow(e) {
    //assign value to set dropdown    
    this.editSelectedData = e;
    this.editSelectedCourseName = e.CourseName;
    this.editSelectedProgramName = e.ProgramName;
    this.editSelectedresourseType = e.ResourceTypeId;
    this.editSelectedresourseTypeName = e.ResourceTypeName;
    this.editSelectedHeading = e.Heading;
    this.editSelectedResourceUrl = e.ResourceUrl;
    this.editSelectedDescription = e.Description;
    this.editResourseDialog = true;
  }
  UpdateResourse(e, heading, description, resourseurl) {

    this.tafbs.updateResourse(e, heading, description, resourseurl);
    this.editResourseDialog = false;

  }
  CancelEditResourse(e) {
    this.tafbs.getUserProgress<TAResources>("resources", e.CourseId, e.ProgramId).subscribe(data => {
      this.resourseslist = data;
      this.UpdateResouresList(this.resourseslist);
    })
    this.editResourseDialog = false;
  }
  AddResoursetypeDD(e) {
    this.resourseType = e;
    this.resoursetypename = e.ResourceTypeName;
  }
  addNewResourse(heading, description, resourceUrl) {
    if (this.resourseType.ResourceTypeName == 'Question') {
      if (heading) {
        this.tafbs.getResourseID<TAResources>("resources").pipe(take(1)).subscribe(async data => {
          this.ResourceId = data[0].ResourceId + 1;
          await this.ResourceId;
          if (this.ResourceId != data[0].ResourceId) {
            await this.tafbs.addResourses(heading, description, resourceUrl, this.SelectedCourse.CourseId, this.selectedProgram.ProgramId, this.resourseType, this.ResourceId);
          }
        });
      }
      else {
        alert("Please, enter all the required fields")
      }

    } else {
      if (heading && resourceUrl) {
        this.tafbs.getResourseID<TAResources>("resources").pipe(take(1)).subscribe(data => {
          this.ResourceId = data[0].ResourceId + 1;
          if (this.ResourceId != data[0].ResourceId) {
            this.tafbs.addResourses(heading, description, resourceUrl, this.SelectedCourse.CourseId, this.selectedProgram.ProgramId, this.resourseType, this.ResourceId);
          }
        });
      } else {
        alert("Please, enter all the required fields")
      }
    }
    this.newresourseHeading = '';
    this.newresourseDescription = '';
    this.newresourseUrl = '';
    this.ddReset = undefined;
    this.addResourseDialog = false;
  }

  CancelNewResourse() {
    this.newresourseHeading = '';
    this.newresourseDescription = '';
    this.newresourseUrl = '';
    this.ddReset = undefined;
    this.addResourseDialog = false;
  }

  DeleteResourse(e) {
    this.SelectedResource = e;
    // this.tafbs.deleteResourse(e);
    this.deleteResouceConfimation = true;

  }
  deleteResourceConfirmation() {
    this.tafbs.deleteResourse(this.SelectedResource);
    this.editResourseDialog = false;
    this.deleteResouceConfimation = false;
  }
  CancelResourceConfirmation() {
    this.editResourseDialog = false;
    this.deleteResouceConfimation = false;
  }

}
