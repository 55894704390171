import { Component, OnInit, Input } from '@angular/core';
import { TAResources } from 'src/app/Interface/resources';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { PredefineInterviewAudioRecordingService } from 'src/app/service/predefine-interview-audio-service';
import { DomSanitizer } from '@angular/platform-browser';
import { InterviewQaService } from 'src/app/service/interview-qa.service';
import { InterviewQaAudioService } from 'src/app/service/interview-qa-audio.service';

@Component({
  selector: 'app-interview-qa-audio-record',
  templateUrl: './interview-qa-audio-record.component.html',
  styleUrls: ['./interview-qa-audio-record.component.css']
})
export class InterviewQaAudioRecordComponent implements OnInit {

  isRecording = false;
  starRrec:boolean=false
  recordedTime;
  blobUrl;
  question: TAResources;
  deleteicon: boolean;
  audioLink: string;
  heading: any;
  loader: boolean;
  deleteConfimationdialog: boolean;
  @Input()
  public set Question(v : TAResources) {
    this.question = v;
    // console.log(this.question);
    this.audioLink = this.question.AudioUrl; 
  }
  @Input()
  public set Heading(v : any) {
    this.heading = v;
  }
  constructor(private taiqs: InterviewQaService,private audioRecording: InterviewQaAudioService,private sanitizer: DomSanitizer) {
    // this.currentuserRole = this.tds.getCurrentUser().RoleName;
    this.audioRecording.recordingFailed().subscribe(() => {
      this.isRecording = false;
    });

    this.audioRecording.getRecordedTime().subscribe((time) => {
      this.recordedTime = time;
      // console.log(this.recordedTime);
      
    });

    this.audioRecording.getRecordedBlob().subscribe((data) => {
     
      
      this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.blob));
      // console.log( this.blobUrl);
    });
   }

  ngOnInit() {
  }
  startRecording() {
    this.starRrec=true
    if (!this.isRecording) {
      this.isRecording = true;
      this.audioRecording.startRecording();
    }
  }

  abortRecording() {
    if (this.isRecording) {
      this.isRecording = false;
      this.audioRecording.abortRecording();
    }
  }

  stopRecording() {
    this.loader=true
    this.starRrec=false
    if (this.isRecording) {
      // console.log(this.question);
      // console.log(this.heading.id);
      this.audioRecording.stopRecording(this.question,this.heading.id);
      this.isRecording = false;
    }
  }
  deleterec(){
    // console.log('sccccccccc');
    this.deleteicon=true
  }
  deleterec1(){
    this.deleteicon=false
  }
  deleteRecording(){
    this.deleteConfimationdialog=true
    // 
  }
  deleteConfirmation() {
    this.taiqs.deleteInterviewQAAudio(this.question,this.heading.id)
    this.deleteConfimationdialog = false;
  }
  CancelConfirmation() {
    this.deleteConfimationdialog = false;
  }
}
