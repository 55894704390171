import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TAProgram } from '../../Interface/program';
import { TekarchFbService } from '../../service/tekarchfbservice';
import { TACourse } from '../../Interface/course';

@Component({
  selector: 'app-ta-course',
  templateUrl: './ta-course.component.html',
  styleUrls: ['./ta-course.component.css']
})
export class TaCourseComponent implements OnInit {

  courselist: TACourse[];
  programlist: any;
  constructor(private route : ActivatedRoute,private router: Router,private tafbs: TekarchFbService) { 
    this.tafbs.getDetailList<TACourse>("courses").subscribe(data => {
      this.courselist = data;
    });
   
  }
  ngOnInit() {
   
  }



}
