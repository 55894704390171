import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Roles } from '../Interface/roles';
import { TekarchFbService } from '../service/tekarchfbservice';
import { ColDD } from '../Interface/col-dd';



@Component({
    selector: "app-dd-helper",
    template: ""
})
export class DropDownHelper<T> implements OnInit {
    dropdownList: T[];
    colName: string;
    inputValue: string;
    
    placeholder: string;
    ddSize = 10;      // default value
    field = "name";  // default is name - to display in html
    _ddReset: string;
    _disabled: boolean = false;
    selectedDD: any;

    @Input()
    set ddObj(value: string) {
        this.selectedDD
        this.inputValue = value;
        this.SetDDBasedOnInput(value);
    }

    @Input()
    public set ddReset(v: string) {
        this.selectedDD = null;
        if (v == null || v == undefined) {
            this.selectedDD = null;
        }
    }

    @Input()
    public set disabled(v: boolean) {
        this._disabled = v;
    }



    @Output()
    ddObjChange: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    displayChange: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    displayNewObj: EventEmitter<ColDD> = new EventEmitter<ColDD>();

    constructor(protected blfs: TekarchFbService) { }

    ngOnInit() {
        // the list needs to be initialized before the dropdown value is set in input

        this.blfs.getDDColList<T>(this.colName).subscribe(data => {
            this.dropdownList = data;
          
            if (this.inputValue) {
                this.SetDDBasedOnInput(this.inputValue);
            }
        });
    }

    //#region selected DD value 

    onDropDownSelect(e) {
        this.ddObjChange.emit(this.getKeyField(e));
        this.displayChange.emit(e);
        // this.displayNewObj.emit(this.getKeyField(e));
    }

    //#endregion

    protected getDisplayField<T>(T): string {
        return "";
    }

    protected getKeyField<T>(T): string {
        return "";
    }

    //#region set selected dropdown value 

    SetDDBasedOnInput(input: string) {
        if (input && this.dropdownList) {
            this.dropdownList.forEach(element => {
                if (this.getKeyField(element) == input)
                    this.selectedDD = element;
            });
        } else {
            this.selectedDD = undefined;
        }
    }

    //#endregion

    //#region keypress filter 

    onKeyPress(event) {
        this.blfs.getDDColList<T>(this.colName).subscribe(data => {
            this.dropdownList = this.filterAction(event.query, data);
            this.displayNewObj.emit(this.selectedDD);
        });
    }

    filterAction(query, data: T[]): T[] {
        //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
        let filtered: T[] = [];
        for (let i = 0; i < data.length; i++) {
            let obj = data[i];
            if (
                this.getDisplayField(obj)
                    .toLowerCase()
                    .indexOf(query.toLowerCase()) != -1
            ) {
                filtered.push(obj);
            }
        }
        return filtered;
    }

    //#endregion
}
