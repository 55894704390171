import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TekarchDataService } from 'src/app/service/tekarchdataservice';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TAResourseType } from 'src/app/Interface/resources';

@Component({
  selector: 'app-resource-type-dd',
  templateUrl: './resource-type-dd.component.html',
  styleUrls: ['./resource-type-dd.component.css']
})
export class ResourceTypeDdComponent implements OnInit {
  resoursetypeList: TAResourseType[];
  SelectedResourcetype: TAResourseType;
  inputValue: string;
  _disabled: boolean = false;

  @Input()
  ddSize: number;

  @Input()
  public set disabled(v: boolean) {
    this._disabled = v;
  }

  @Output()
  SelectedResourceTypeName = new EventEmitter();

  @Input()
  public set ddReset(v: string) {
    if (v == null || v == undefined) {
      this.SelectedResourcetype = null;
    }
  }

  @Input()
  selectedCourse: string;


  @Input()
  set ddObj(value: string) {
    this.inputValue = value;
    this.SetDDBasedOnInput(value);
  }
  constructor(private tafbs: TekarchFbService, private tads: TekarchDataService) {

  }

  ngOnInit() {
    this.tafbs.getDetailList<TAResourseType>("resourcetype").subscribe(data => {
      this.resoursetypeList = data;
      if (this.inputValue) {
        this.SetDDBasedOnInput(this.inputValue);
      }
    });
  }
  onKeyPress(event) {
    this.tafbs.getDetailList<TAResourseType>("resourcetype").subscribe(data => {
      this.resoursetypeList = this.filterAction(event.query, data);
    });
  }

  filterAction(query, data: TAResourseType[]): TAResourseType[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: TAResourseType[] = [];
    for (let i = 0; i < data.length; i++) {
      let SelectedResourcetype = data[i];
      if (SelectedResourcetype.ResourceTypeName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(SelectedResourcetype);
      }
    }
    return filtered;
  }

  //#endregion

  onDropDownSelect(e) {
    this.SelectedResourceTypeName.emit(e);
  }
  SetDDBasedOnInput(input: string) {
    this.tafbs.getDetailList<TAResourseType>("resourcetype").subscribe(data => {
      this.resoursetypeList = data;
      if (input && this.resoursetypeList) {
        this.resoursetypeList.forEach(element => {
          if (element.ResourceTypeName == input) {
            this.SelectedResourcetype = element;
          }
        });
      } else {
        this.SelectedResourcetype = undefined;
      }
    });
  }
}
