import { Component, OnInit } from '@angular/core';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TAStudentManagement } from 'src/app/Interface/studentmanagement';
import { TAUser } from 'src/app/Interface/ta-user';
import { TekarchDataService } from 'src/app/service/tekarchdataservice';
import { HttpClient } from '@angular/common/http';
import { Email } from 'src/app/Interface/email';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class EmailComponent implements OnInit {
  selecteduser: TAStudentManagement;
  endpoint = 'https://us-central1-bluelinecertication-dev.cloudfunctions.net/httpEmail';
  newStr: string;

  userCourseData: any[];
  selectedCourse: any;
  activatedUsers: TAUser[];
  _userlist: any;
  Emaildata: Email;
  mail: any;
  content: string;
  subject: string;

  constructor(private tfbs: TekarchFbService, private tds: TekarchDataService, private http: HttpClient) {
    this.tfbs.getActiveUsers().subscribe(async data => {
      this.activatedUsers = data;
    })
  }

  ngOnInit() {
  }
  SelectedCourseDD(e) {

    this.selectedCourse = e;

    this.tfbs.getUserCourse(e).subscribe(async data => {
      this.userCourseData = data;
      let newData = [];
      for (var i = this.activatedUsers.length - 1; i >= 0; i--) {
        for (var j = 0; j < this.userCourseData.length; j++) {
          if (this.activatedUsers[i] && (this.activatedUsers[i].UserId === this.userCourseData[j].UserId)) {
            newData.push(this.activatedUsers[i].UserId);
            // this._userlist = newData.join(",")
            var arrayObject = newData.map(function (item) {
              return { email: item };
            });
            this.tds.setUserList(arrayObject);
          }
        }
      }
    })
  }

  test() {  }


  sendEmail(s, c) {

    this.mail = this.tds.getUserList();
    var result = this.mail.reduce((unique, o) => {
      if (!unique.some(obj => obj.email === o.email)) {
        unique.push(o);
      }
      return unique;
    }, []);
    this.Emaildata = {
      fromEmail: `krishna.softwiz@gmail.com`,
      toEmail: "krishnamoorthy878@gmail.com",
      toName: `Krishna`,
      toBcc: result,
      toSubject: s,
      toContent: c
    }
    this.http.post(this.endpoint, this.Emaildata).subscribe();



  }

}
