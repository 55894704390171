import { Component, OnInit } from '@angular/core';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TekarchDataService } from 'src/app/service/tekarchdataservice';
import {  TAResources } from 'src/app/Interface/resources';
import { TAProgram } from 'src/app/Interface/program';
import { TACourse } from 'src/app/Interface/course';
import { UserResponce } from 'src/app/Interface/user-responce';
import { take } from 'rxjs/operators';
import { TAUser } from 'src/app/Interface/ta-user';

@Component({
  selector: 'app-manage-user-progress',
  templateUrl: './manage-user-progress.component.html',
  styleUrls: ['./manage-user-progress.component.css']
})
export class ManageUserProgressComponent implements OnInit {
  SelectedCourse: TACourse;
  selectedProgram: TAProgram;
  userResponcelist: TAResources[];
  SelectedCourseName: any;
  data: any;
  selectedUser: TAUser;
  ddReset:boolean=false;
  constructor(private tafbs: TekarchFbService, private tads: TekarchDataService) {
   
  }

  ngOnInit() {
  }
  SelectedCourseDD(e) {
    this.SelectedCourse = e;
    this.SelectedCourseName = e.CourseName;
    this.selectedProgram=null
  }
  selectedprogramDD(e) {
    if(this.ddReset==false){
      this.ddReset=true
    }else this.ddReset=false
    
    this.selectedProgram = e;
  
  

  }



  SelectedStudentDD(e){
    this.selectedUser=e
    this.tafbs.getSelectedViewedResourceForUser(this.selectedUser, this.SelectedCourse, this.selectedProgram).subscribe(data => {
      this.userResponcelist = data;
      var normalizedArray = this.userResponcelist.map(obj=> {
        return obj.Heading;
      });
      var normalizedvalueArray = this.userResponcelist.map(obj=> {
        return obj.average;
      });
      this.data={labels:normalizedArray,
      datasets: [ 
                {
                    label: 'User Progress',
                    backgroundColor: [
                      "#FF6384",
                      "#36A2EB",
                      "#FFCH96","#FFCE56","#D500F9","#3D5AFE","#1DE9B6","#FFFF00","#FF3D00"
                  ],
                    borderColor: '#1E88E5',
                    data: normalizedvalueArray,
                    
                }
            ]}
    })
  }
}
