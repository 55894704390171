import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../Auth/auth.service';

import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit,OnDestroy {

  constructor(public auth: AuthService,private http:HttpClient) { }

  ngOnInit() {   
    // document.body.className = "bg";
    this.http.get<{ ip: string }>('https://jsonip.com')
    .subscribe(data => {
      // console.log('th data', data);
      // this.ipAddress = data
    })

  }
  ngOnDestroy(){
    // document.body.className="";
  }
}
