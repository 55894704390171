import { Injectable } from '@angular/core';
import { TAUser } from '../Interface/ta-user';
import { TAProgram } from '../Interface/program';
import { Observable, Subject } from 'rxjs';
import { TAResources } from '../Interface/resources';
@Injectable()
export class TekarchDataService {


    private _currentUser: TAUser;
    private _viewprogram: string
    _resoursedata: TAProgram;
    private subject = new Subject<any>();
    _clicktime: number;
    _userList: string;
    _heading: string;
    _questionDetails: any;
    _questionLists: TAResources[];
    _assClicktime: number;
    constructor() { }
    getCurrentUser(): TAUser {
        return this._currentUser;
    }
    setCurrentUser(user: TAUser) {

        this._currentUser = user;
        this.subject.next(user);
    }

    getLocalUser(): Observable<any> {
        return this.subject.asObservable();
    }

    setAccordiantime(u: number) {
        this._clicktime = u;
    }
    getAccordiantime(): number {
        return this._clicktime;
    }
    setviewprogram(u: string) {
        this._viewprogram = u;
    }
    getviewprogram(): string {
        return this._viewprogram;
    }
    setResoursedata(u: TAProgram) {
        this._resoursedata = u;
    }
    getResoursedata(): TAProgram {
        return this._resoursedata;
    }
    getUserList(): any {
        return this._userList;
    }
    setUserList(_userlist: any) {
        this._userList = _userlist;
    }
    setHeading(u: string) {
        this._heading = u
    }
    getHeading() {
        return this._heading;
    }
    setQuestionDetails(ans: any) {
        this._questionDetails = ans;
    }
    getQuestionDetails() {
        return this._questionDetails;
    }
    setQuestionLists(ans: TAResources[]) {
        this._questionLists = ans
    }
    getQuestionLists() {
        return this._questionLists;
    }
    setAssignmentClick(u: number) {
        this._assClicktime = u;
    }
    getAssignmentClick() {
        return this._assClicktime;
    }
}

