import { Component, OnInit, Input } from '@angular/core';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TekarchDataService } from 'src/app/service/tekarchdataservice';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-predefined-questions-heading',
  templateUrl: './predefined-questions-heading.component.html',
  styleUrls: ['./predefined-questions-heading.component.css']
})
export class PredefinedQuestionsHeadingComponent implements OnInit {
  question: any;
  EditHeading: any;
  EditQuestionHeading: any;
  display: boolean;
  [x: string]: any;
  click: boolean;
  _heading: any;
  currentuserRole: string;
  questionslist: any[];
  Questiondialog: boolean;
  EditUserQuestion: any;
  EditUserData: any;

  @Input()
  public set Click(v: boolean) {
    if (v) {
      this.click = v;
    }
  }
  @Input()
  public set heading(v: any) {
    this._heading = v;
    // console.log(v);

    this.tfs.getselectedpredefinedquestions(this._heading.id).subscribe(data => {
      this.questionslist = data;
    })
    
    // if (this.currentuserRole == "Student") {

    //   this.tfs.getQuestionbyHeadingForStudent("questions", this._heading.id).subscribe(data => {
    //     this.questionslist = data;
    //   })
    // }
    // else {
    //   this.tfs.getQuestionbyHeading("questions", this._heading.id).subscribe(data => {
    //     this.questionslist = data;
    //   })
    // }

  }
  constructor(private tfs: TekarchFbService, private tds: TekarchDataService, public afs: AngularFirestore) {
    this.currentuserRole = this.tds.getCurrentUser().RoleName;
  }

  ngOnInit() {
  }
  EditiconClick(field) {
    this.EditUserData = field;
    this.EditUserQuestion = field.Question;
    this.Questiondialog = true;
  }
  clickTime(){
    const clicktime = new Date().getTime()
    this.tds.setAccordiantime(clicktime)
  }
  UpdateQuestion(field, qus) {
   
    this.tfs.updatePredefinedques(this._heading.id, qus,field.id);
    this.Questiondialog = false;
  }
  cancelQuestion(field) {

    this.tfs.getselectedpredefinedquestions(this._heading.id).subscribe(data => {
      this.questionslist = data;
    })
    this.Questiondialog = false;
  }
  deleteQuestion(field) {
    this.tfs.deletePredefinedques(this._heading.id,field.id);
    this.Questiondialog = false;
  }
  isNullOrEmpty(str) {
    return !str || !str.trim();
  }
  saveQuestions(q) {
    let id = this.afs.createId();
    if (q) {
      q = q.charAt(0).toUpperCase() + q.slice(1);
      if (this.isNullOrEmpty(q)) {
        return;
      } else {
        this.tfs.addpredefinedQuestion(this._heading.id,id,q);
        // this.tfs.getInterviewQuestionList("questions", "IndexNo").pipe(take(1)).subscribe(d => {
        //   if (d[0] == undefined) {
        //     this.indexnumber = 1;
        //   } else {
        //     this.indexnumber = d[0].IndexNo + 1;
        //   }
        //   this.tfs.addQuestionForUsers(this._heading, q, id, this.questionNo, this.indexnumber);
        // })

      }
    }

    this.question = null;
  }
  deleteHeadingDialog(h) {
    const clicktime = new Date().getTime()
    this.tds.setAccordiantime(clicktime)
    this.EditHeading = h;
    this.EditQuestionHeading = this.EditHeading.id;
    this.display = true;
  }
  async UpdateHeading(h, sh) {
    // console.log(h);
    // console.log(sh);
    //  this.tfs.updatepredefinedHeading(h.id,sh);
    // this.tfs.updateHeadingresources(h, sh);
    this.display = false;
  }
  cancelHeading() {
    this.tfs.getselectedpredefinedquestions(this._heading.id).subscribe(data => {
      this.questionslist = data;
    })
    this.display = false;
  }
  deleteHeading() {


    // if (this.questionslist.length != 0) {
    //   this.tfs.getUserCourseId(this._heading).subscribe(d => {
    //     var result = d.reduce((unique, o) => {
    //       if (!unique.some(obj => obj.UserId === o.UserId)) {
    //         unique.push(o);
    //       }
    //       return unique;
    //     }, []);


    //     result.forEach(data => {
    //       this.questionslist.forEach(d => {
    //         this.questionslistQus = d;
    //         this.tfs.deleteQuestion(d);
    //         this.tfs.deleteQuestionHeading(this._heading);
    //         this.tfs.deleteQuestions(d, data);
    //       })
    //     })
    //   })
    // } else {
      this.tfs.deletepredefinedHeading(this._heading.id);
    // }
    this.display = false;
  }
}
