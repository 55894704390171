import { Component, OnInit } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { ColDD, webacc } from 'src/app/Interface/col-dd';
import { SelectItem } from 'primeng/api';
// import {findTimeZone,getUnixTime,} from 'timezone-support';
import { AngularFirestore } from '@angular/fire/firestore';
import { listTimeZones, findTimeZone, getZonedTime, getUnixTime } from'timezone-support';
// import * as moment from 'moment-timezone';
// import * as moment from 'moment';s
import * as moment from 'moment-timezone';
import { MessageService } from 'primeng/api';
// var moment = require('moment-timezone');
// var require: any
@Component({
  selector: 'app-webinar',
  templateUrl: './webinar.component.html',
  styleUrls: ['./webinar.component.css']
})
export class WebinarComponent implements OnInit {
  heading:string
  account: webacc;
  headingname: any;
  course: any;
  start: Date;
  end: Date;
  val1:string="classroom";

  ddreset: any;
  starttime: any;
  endtime: any;
  types: SelectItem[];
  selectedButtonValue: string = 'create';
  webinarkey: any;
  accountdetail: webacc;
  webdatail: any;
  timezone:string="Indian Standard Time (IST) - UTC+05:30"
  constructor(private http:HttpClient, private db: AngularFirestore,private messageService: MessageService,public datepipe: DatePipe,private tafbs:TekarchFbService) {
    this.types = [
      { label: 'Create Webinar', value: 'create' },
      { label: 'Add Existing Webinar', value: 'add' },
      { label: 'Edit  Webinar', value: 'delete' }]
   this.tafbs.getUserList("candidate").subscribe(d=>{ 
   })
   }

  ngOnInit() {
   let e=new Date()
   const IST = findTimeZone("Asia/Calcutta")
 
// Convert a date to a specific time zone: { year, month, day, dayOfWeek,
// hours, minutes, seconds, milliseconds, epoch, zone: { abbreviation, offset } }
const nativeDate = {
  year: 2020,
  month: 6,
  day: 16,
  hours: 22,
  minutes: 30,
};
const UTCDate = new Date(getUnixTime(nativeDate, IST));
const time = getZonedTime(UTCDate, IST)
console.log(time,UTCDate);

   let ist = moment.tz("2020-06-16 22:30", "Asia/Calcutta");
   console.log(ist.utc().format());

  }
  SelectedaccountDD(e){
console.log(e);
this.account=e

  }
  onChange(e) {
 
    
    if (e.value != undefined) {
      this.selectedButtonValue = e.value;
    } else {
      this.selectedButtonValue = e;
    }}
  SelectedheadingDD(e){
   this.headingname=e
      }
SelectedcourseDD(e){
        this.course=e
        console.log(e);
        
  }
SelectedtimeformatDD(e){
 this.timezone=e
    }
    addnewWebinar(heading,s,e,val1){
console.log(s,e);
this.start=s;
this.end=e
let starttime = moment.tz(s, "Asia/Calcutta");
let endtime = moment.tz(e, "Asia/Calcutta");
// console.log(starttime.utc().format());
// moment.tz("2020-06-16 22:30", "Asia/Calcutta").format();
    
     
      
      console.log(s,e);
    
      // console.log(this.end.getFullYear(), this.end.getMonth()+1, this.end.getDay(),this.end.getHours(),this.end.getMinutes());
      
      // let e1=new Date(e.setHours(e.getHours() - 9));
      // let e2=new Date(Date.UTC(this.end.getFullYear(), this.end.getMonth(), this.end.getDate(),this.end.getHours(),this.end.getMinutes()));
      // // let s1=new Date(e.setHours(e.getHours() - 9));
      // let s2=new Date(Date.UTC(this.start.getFullYear(), this.start.getMonth(), this.start.getDate(),this.start.getHours(),this.start.getMinutes()));
let str1=starttime.utc().format()
let str2=endtime.utc().format()

     
      // let a=new Date(s.setHours(s.getHours() + 4));
      // let b=new Date(e.setHours(e.getHours() + 4))
      // let startDATE =this.datepipe.transform(str1, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
      // let endDATE =this.datepipe.transform(str2, "yyyy-MM-dd'T'HH:mm:ss.000'Z'") 
      // let str = this.datepipe.transform(a,"HH:mm:ss");
      console.log(str1,str2);
      // console.log(startDATE,endDATE);
      
      
      const httpOptions1 = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': this.account.access_token
        })
      };
      if(val1=="classroom"){
var desc=heading+" - TekArch "+val1
      }else{
        var desc=heading+" - TekArch"
      }
      let url="https://api.getgo.com/G2W/rest/v2/organizers/"+this.account.organizer_key+"/webinars"
      let aa={
        "subject": heading,
        "description": desc,
        "times": [
          {
            "startTime": str1,
            "endTime": str2
          }
        ],
        "timezone": "GMT-08:00",                                         
        "isPasswordProtected": false
      }
      
      console.log(aa,url);
      
      this.http.post(url,aa,httpOptions1).subscribe(d=>{
        this.ddreset=null
        let a=Object.values(d)
        console.log(d);
        console.log(a);
        
        let webinarid=a[0]
        this.tafbs.addwebinar(webinarid,this.account,heading, this.course,this.timezone,str1,str2,val1,this.start,this.end)
        this.account=null;this.heading=null; this.course=null;this.starttime=null;this.endtime=null;this.end=null;
      },  err => alert( err.message), () =>    this.messageService.add({severity:'Successfully Added', summary: 'Successfully Added'}))
     
       }
 savewebinar(e){
   

            

const httpOptions1 = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': this.account.access_token
  })
};
let url="https://api.getgo.com/G2W/rest/v2/organizers/"+this.account.organizer_key+"/webinars/"+e

let batch = this.db.firestore.batch();
this.http.get<any>(url,httpOptions1).subscribe(async d =>{
  console.log(d);
 
  var startdate = new Date(d.times[0].startTime)
  var enddate = new Date(d.times[0].endTime)
  
 await this.db.collection("courses").doc(this.course.id).collection("webinar_sessions").add({webinar_id:d.webinarKey,
    course_name:this.course.CourseName,
    course_id:this.course.id,
    end:d.times[0].endTime,
    start:d.times[0].startTime,
    session_type:"classroom",
    webinar_account:d.organizerEmail,
    attendance_tracked:false,
    starttime:startdate,
    endtime:enddate,
    name:d.name})
},  err => alert( err.message),()=>this.messageService.add({severity:'Successfully Added', summary: 'Successfully Added'})) 
batch.commit().catch(err => console.error(err.message));

setTimeout(() => {
  this.ddreset=null
this.webinarkey=null
}, 3000);

  // this.data=d
  // let a=d.webinarKey
  // console.log(d.webinarKey);
  
  // this.ddreset=null
  // let a=Object.values(d)
  // let webinarid=a[0]
  // this.tafbs.addexistingwebinar(a,d.organizerEmail,d.name, this.course,d.times[0].startTime,d.times[0].endTime,d.type)
 
 }
 selectedwebinar(e){
   console.log(e);
   this.webdatail=e
   this.tafbs.getaccColList<webacc>("GoToWebinar",e.webinar_account).subscribe(data => {
    this.accountdetail = data[0];
  console.log(data);
  })
 }
 deletewebinar(){
  const httpOptions1 = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': this.accountdetail.access_token
    })
  };
  let url="https://api.getgo.com/G2W/rest/v2/organizers/"+this.accountdetail.organizer_key+"/webinars/"+this.webdatail.webinar_id
  this.http.delete(url,httpOptions1).subscribe(d=>{
    // alert("successfully deleted")
    this.messageService.add({severity:'error', summary: 'Successfully Deleted'})
    this.ddreset=null
  },  err => alert( err))
 }
}
