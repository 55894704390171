import { Component, OnInit, HostListener } from '@angular/core';
import { TAProgram } from '../../Interface/program';
import { TekarchFbService } from '../../service/tekarchfbservice';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-manage-programs',
  templateUrl: './manage-programs.component.html',
  styleUrls: ['./manage-programs.component.css']
})
export class ManageProgramsComponent implements OnInit {

  cols: { field: string; header: string; }[];
  SelectedCourse: string;
  adddialogVisible: boolean = false;
  newprogram: TAProgram = {};
  editdialogVisible: boolean = false;
  editselectedProgram: TAProgram;
  programlist: TAProgram[];
  ProgramId: number;
  ddReset: any;
  deleteConfimationdialog: boolean;
  deleteProgramdetails: any;
  disabled: boolean = true;

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      // Do things
      try {
        this.CancelProgram()
        this.CancelEditProgram(this.editselectedProgram)
        this.deleteConfimationdialog = false;
      } catch (error) { }
    }
  }


  constructor(private tafbs: TekarchFbService) {
    this.tafbs.getDetailList<TAProgram>("programs").subscribe(data => {
      this.programlist = data;
    });
  }

  ngOnInit() {
    this.cols = [
      { field: "CourseName", header: "Course" },
      { field: "ProgramName", header: "Program" },
      { field: "Description", header: "Description" }
    ];
  }
  SelectedRow(e) {
    this.editselectedProgram = e;
    this.editdialogVisible = true;
  }
  UpdateProgram(e) {
    this.tafbs.updateProgramDetails(e);
    this.editdialogVisible = false;
  }
  CancelEditProgram(e) {
    this.tafbs.getDetailList<TAProgram>("programs").subscribe(data => {
      this.programlist = data;
    });
    this.editdialogVisible = false;
    this.editselectedProgram = {};
  }
  AddProgram() {
    this.ddReset = null;
    this.adddialogVisible = true;
  }
  AddProgramDetail(e, courses) {


    if (e && courses) {
      this.tafbs.programId<TAProgram>("programs").pipe(take(1)).subscribe(async data => {
        this.ProgramId = data[0].ProgramId + 1;
        await this.ProgramId;
        if (this.ProgramId != data[0].ProgramId) {
          if (this.isNullOrEmpty(e.ProgramName)) {
            alert("Please enter any character")
            return
          } else {
            await this.tafbs.addProgram(e, courses, this.ProgramId);
            this.adddialogVisible = false;
            this.ddReset = true;
          }
        }
      })
      this.newprogram = {}
    } else {
      alert("Please, enter all the required fields");
    }


  }

  CancelProgram() {
    this.ddReset = undefined;
    this.newprogram = {};
    this.adddialogVisible = false;
  }
  SelectedCourseDD(e) {
    this.SelectedCourse = e;
  }
  deleteProgram(e) {
    this.deleteProgramdetails = e;
    this.deleteConfimationdialog = true;
  }
  deleteProgramConfirmation() {
    this.tafbs.deleteProgram(this.deleteProgramdetails);
    this.editdialogVisible = false;
    this.deleteConfimationdialog = false;
  }
  CancelProgramConfirmation() {
    this.editdialogVisible = false;
    this.deleteConfimationdialog = false;
  }
  isNullOrEmpty(str) {
    return !str || !str.trim();
  }
}

