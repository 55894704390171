import { Injectable } from "@angular/core";
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router
} from "@angular/router";

import { AuthService } from "./auth.service";

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private auth: AuthService, private router: Router) {}

    async canActivate(
        next : ActivatedRouteSnapshot,
        state: RouterStateSnapshot
        )    : Promise<boolean> {
        const user = await this.auth.getUser();
        if (!user) {
            this.router.navigate(["/"]);
        }
        return !!user;

}
}
