import { Component, OnInit, Input } from '@angular/core';
import { TAResources } from 'src/app/Interface/resources';
import { TAProgram } from 'src/app/Interface/program';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TekarchDataService } from 'src/app/service/tekarchdataservice';
import { AngularFirestore } from '@angular/fire/firestore';
import { take } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-interview-que-heading',
  templateUrl: './interview-que-heading.component.html',
  styleUrls: ['./interview-que-heading.component.css']
})
export class InterviewQueheadingComponent implements OnInit {
  resource: any;
  resourcelist: any;
  _heading: any;
  click: boolean = false;
  showModel: boolean = false
  resourceList: TAResources[];
  questionslist: any[];
  display: boolean = false;
  EditHeading: any;
  EditQuestionHeading: any;
  currentuserRole: string;
  questionslistQus: any;
  courseId: number;
  courseHeading: string;
  tableDialog: boolean = false;
  questionNo: number;
  indexNo: number;
  question: any;
  EditUserData: any;
  EditUserQuestion: any;
  Questiondialog: boolean;
  indexnumber: any;
  userdata: any;
  selectedUser: SelectItem[];
  userlist: any[];
  audioSelectedUser:string[]=[];

  @Input()
  public set Click(v: boolean) {
    if (v) {
      this.click = v;
    }
  }
  @Input()
  public set heading(v: TAProgram) {
    this._heading = v;
    this.getQuestion(this._heading)
    if(this._heading.CourseId){
      let unique_array1 = [];
      let unique_array2 = [];
      this.tfs.getUserCourseUsingId(this._heading.CourseId).subscribe(async data => {
        for (let i = 0; i < data.length; i++) {
          if (unique_array1.indexOf(data[i].UserId) == -1) {
            data.map(item=>{
              var b=item.UserId
              // var key=b.replace(/\./g,"_");
               item.label=item.UserId
               item.value=item.UserId
                return   item
            })
            unique_array1.push(data[i].UserId);
            unique_array2.push(data[i]);
          }
        }
        this.selectedUser = unique_array2;
        // console.log(   this.selectedUser);
        
      });}

      if(this._heading){
    
        this.tfs.getAudioAccessusers(this._heading.id).subscribe(d => {
      //  console.log(d); 
        this.audioSelectedUser=[]
     d.forEach(s=>{
      this.audioSelectedUser.push(s.UserId)
      // console.log(this.audioSelectedUser);
     })
     
        
          // this.selectedCars2 = [
          //   {name: 'New York'},
          //   {name: 'Rome'},
          // ];
          // console.log(this.selectedCars2);
          
           
        })
      }
    // this.tafbs.getResourceProgramList<TAResources>("resources", this._program).subscribe(data => {
    //   this.UpdateResourceList(data)
    // }
    // );
  }
  constructor(private tfs: TekarchFbService, private tds: TekarchDataService, public afs: AngularFirestore) {
    this.currentuserRole = this.tds.getCurrentUser().RoleName;
  }

  ngOnInit() {

  }
  trackbyfunction(index, item) {
    return index

  }
  isNullOrEmpty(str) {
    return !str || !str.trim();
  }
   checked(){
   if(this.questionslist)
   return this.questionslist.every(data=>{
      return  data.visible==true
    })
  }
  EditiconClick(field) {


    this.EditUserData = field;
    this.EditUserQuestion = this.EditUserData.Question;
    this.Questiondialog = true;
  }
  SelectAllVisible(e,val){
    const clicktime = new Date().getTime()
    this.tds.setAccordiantime(clicktime)
    let batch = this.afs.firestore.batch();
    val.forEach(data=>{
      this.afs.collection("interviewquestions").doc(data.id).update({visible:e.checked})
    })
   
    batch.commit().catch(err => console.error(err));
  }
  saveQuestions(q) {
    let id = this.afs.createId();
// console.log(q);

    if (q) {

      q = q.charAt(0).toUpperCase() + q.slice(1);
      if (this.isNullOrEmpty(q)) {
        return;
      } else {
        this.tfs.getInterviewQuestionList("interviewquestions", "IndexNo").pipe(take(1)).subscribe(d => {
          if (d[0] == undefined) {
            this.indexnumber = 1;
          } else {
            this.indexnumber = d[0].IndexNo + 1;
          }
          this.tfs.addinterviewQuestionForUsers(this._heading ,q, id, this.questionNo, this.indexnumber);
        })
      }
    }

    this.question = null;
  }
  UpdateQuestion(field, qus) {
    this.tfs.getUserCourseId(this._heading).subscribe(d => {
      var result = d.reduce((unique, o) => {
        if (!unique.some(obj => obj.UserId === o.UserId)) {
          unique.push(o);
        }
        return unique;
      }, []);
      result.forEach(data => {

        this.tfs.updateInterviewQuestions(field, qus, data);
      })
    })


    this.tfs.interviewquestionUpdate(field, qus);
    this.Questiondialog = false;
  }
  cancelQuestion() {

    this.tfs.getQuestionbyHeading("interviewquestions", this._heading.Heading).subscribe(data => {
      this.questionslist = data;
    })
    this.Questiondialog = false;
  }
  deleteQuestion(field) {

    this.tfs.getUserCourseId(this._heading).subscribe(d => {
      var result = d.reduce((unique, o) => {
        if (!unique.some(obj => obj.UserId === o.UserId)) {
          unique.push(o);
        }
        return unique;
      }, []);
      result.forEach(data => {
        this.tfs.deleteinterviewQuestions(field, data);
      })
    })

    this.tfs.deleteinterviewQuestion(field);
    this.Questiondialog = false;
  }
  handleChange(e, que) {
    this.tfs.updatevisiblestatus("interviewquestions", e.checked, que)
  }
  getQuestion(_heading) {
    if (this.currentuserRole == "Student") {

      this.tfs.getQuestionbyHeadingForStudent("interviewquestions", _heading.id).subscribe(data => {
        this.questionslist = data;
      })
    }
    else {
      this.tfs.getQuestionbyHeading("interviewquestions", _heading.id).subscribe(data => {
        this.questionslist = data;
        // console.log(data, _heading.id);

        
      })
    }

  }
  clickTime() {
    const clicktime = new Date().getTime()
    this.tds.setAccordiantime(clicktime)
  }
  deleteHeadingDialog(h) {

    const clicktime = new Date().getTime()
    this.tds.setAccordiantime(clicktime)
    this.EditHeading = h;
    this.EditQuestionHeading = this.EditHeading.Heading;
    this.display = true;
  }
  async UpdateHeading(h, sh) {

    this.tfs.updateHeadingresources(h, sh)
    if (this.questionslist.length != 0) {
      await this.tfs.getUserCourseId(this._heading).subscribe(d => {
        var result = d.reduce((unique, o) => {
          if (!unique.some(obj => obj.UserId === o.UserId)) {
            unique.push(o);
          }
          return unique;
        }, []);
        return result.forEach(data => {
          this.questionslist.forEach(d => {
            // this.tfs.updateHeadings(d, data, sh);
            this.tfs.InterviewQuestionColHeadingUpdate(d, sh);
            // this.tfs.updateHeadingresources(h, sh)
          })
        })
      })
    } else {
      // this.tfs.updateHeadingresources(h, sh)
    }

    this.display = false;
  }

  cancelHeading() {

    this.tfs.getQuestionbyHeading("interviewquestions", this._heading.Heading).subscribe(data => {
      this.questionslist = data;
    })
    this.display = false;
  }
  deleteHeading() {

    if (this.questionslist.length != 0) {
      this.tfs.getUserCourseId(this._heading).subscribe(d => {
        var result = d.reduce((unique, o) => {
          if (!unique.some(obj => obj.UserId === o.UserId)) {
            unique.push(o);
          }
          return unique;
        }, []);
        result.forEach(data => {
          this.questionslist.forEach(d => {
            this.questionslistQus = d;
            this.tfs.deleteinterviewQuestionsanswer(d, data);
            this.tfs.deleteinterviewQuestion(d);
            this.tfs.deleteInterviewQuestionHeading(this._heading);
          })
        })
      })
    } else {
      this.tfs.deleteInterviewQuestionHeading(this._heading);
    }
    this.display = false;
  }
  answerTable(heading) {
    const clicktime = new Date().getTime()
    this.tds.setAccordiantime(clicktime)
    this.courseId = heading.CourseId;
    this.courseHeading = heading.id;
    this.tableDialog = true;
  }

  multipleSelectOnclick(heading){

    const clicktime = new Date().getTime();
    this.tds.setAccordiantime(clicktime);
    this.courseId = heading.CourseId;
   
  }

  async onChangeMultiselect(e){
    
    // console.log(e);
if(e.itemValue){
    await this.tfs.getAudiosuserstrue(this._heading.id).pipe(take(1)).subscribe(d => {
        this.userdata = d;
      var usersUpdate = {};
    var b=e.itemValue;
    var key=b.replace(/\./g,"_");
    // console.log(this.userdata[key]);
    
    usersUpdate[`${key}`] = this.userdata[key]==true?false:true;
    if(this.userdata[key]==true||this.userdata[key]==false){
    this.afs.collection("resources").doc(this._heading.id).update(
      usersUpdate
    );
    }
if(this.userdata[key]==false){
  this.tfs.addAudiousers(this._heading.id,e.itemValue);
}else if(this.userdata[key]==undefined){
  usersUpdate[`${key}`] = true;
        this.afs.collection("resources").doc(this._heading.id).update(
          usersUpdate
        );
        this.tfs.addAudiousers(this._heading.id,e.itemValue);   
}
 if(this.userdata[key]==true){
  //  console.log(e.itemValue);
   
  this.tfs.deleteAudiousers(this._heading.id,e.itemValue);
}

    })
  }
  else{
    if(e.value.length!=0){
    let batch = this.afs.firestore.batch();
 
      e.value.forEach(data=>{ 
        // console.log(data);    
        var usersUpdate = {};
        var users = {};
      var b=data
      var key=b.replace(/\./g,"_");
      usersUpdate[`${key}`] = true;
      users["UserId"] = data;
        this.afs.collection("resources").doc(this._heading.id).update(usersUpdate)
        this.afs.collection("resources").doc(this._heading.id).collection("audio").doc(data).set(users)
      }) 
      batch.commit().catch(err => console.error(err));
    
    }
    if(e.value.length==0){
      // console.log(this.selectedUser);
      this.userlist=this.selectedUser
      let batch = this.afs.firestore.batch();
   
      this.userlist.forEach(data=>{ 
          // console.log(data);    
          var usersUpdate = {};
        
        var b=data.UserId
        var key=b.replace(/\./g,"_");
        usersUpdate[`${key}`] = false;
      
          this.afs.collection("resources").doc(this._heading.id).update(usersUpdate)
          this.afs.collection("resources").doc(this._heading.id).collection("audio").doc(b).delete()
        }) 
        batch.commit().catch(err => console.error(err));
      
      }
  }
 
  }
}
