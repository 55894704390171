// List of roles displayed in the dropdown
// these collection comes from "staticdropdown" collection inside "roles" collection

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DropDownHelper } from '../../helper/dropdownhelper';
import { Roles } from 'src/app/Interface/roles';
import { ColDD } from 'src/app/Interface/col-dd';


@Component({
  selector: 'app-roles-dd',
  templateUrl: '../../helper/bl-dd.html'
})
export class RolesDdComponent extends DropDownHelper<ColDD>{
  colName = "roles";
  placeholder = "Select Roles";
  field = "RoleName";
  ddSize = 26;

  @Input()
  selectedElement: string;

  @Output()
  elementChange: EventEmitter<string> = new EventEmitter<string>();

  getDisplayField(obj: ColDD): string {
    return obj.RoleName;
  }

  getKeyField(obj: ColDD): string {
    return obj.RoleName;
  }


  // objects has special filtering logic. So thisis completely overridden
  filterAction(query, data: ColDD[]): ColDD[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: ColDD[] = [];
    for (let i = 0; i < data.length; i++) {
      let obj = data[i];
      if (this.selectedElement) {
        if (
          this.selectedElement == obj.RoleName &&
          obj.RoleName.toLowerCase().indexOf(query.toLowerCase()) != -1
        ) {
          filtered.push(obj);
        }
      } else {
        if (obj.RoleName.toLowerCase().indexOf(query.toLowerCase()) != -1) {
          filtered.push(obj);
        }
      }
    }
    return filtered;
  }

}
