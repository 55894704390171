import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { TAStudentManagement } from '../../Interface/studentmanagement';
import { TekarchFbService } from '../../service/tekarchfbservice';
import { TACourse } from 'src/app/Interface/course';
import { TAProgram } from 'src/app/Interface/program';
import { TAUser } from 'src/app/Interface/ta-user';
import { UserDdComponent } from '../user-dd/user-dd.component';
import { Dropdown } from "primeng/components/dropdown/dropdown";
import { AutoComplete } from 'primeng/autocomplete';
import { ConfirmationService } from 'primeng/api';
@Component({
  selector: 'app-manage-student-access',
  templateUrl: './manage-student-access.component.html',
  styleUrls: ['./manage-student-access.component.css']
})
export class ManageStudentAccessComponent implements OnInit {
  cols: { field: string; header: string; }[];
  SelectedProgram: string;
  SelectedCourse: string;
  editselectedUser: TAStudentManagement;
  SelectedUser: string;
  editdialogVisible: boolean = false;
  studentaccess: TAStudentManagement[];
  adddialogVisible: boolean = false;
  usercourse: TAStudentManagement[];
  SelectedCourseID: number;
  SelectedProgramID: number;
  SelectedStudent: any[];
  autocomplete: boolean = false;
  studentAccessConfimation: boolean = false;
  SelectedCourseDD: any
  @ViewChild('dropDownThing')
  dropDownThing: AutoComplete;
  student: any;
  ddReset: boolean;
  SelectedRowData: any;
  selectedProgramList: any;
  program: TAProgram;
  visibleAccess:boolean=true;
  calendarVisible:boolean=true
expiryDate:Date;
  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      // Do things
      try {
        this.CancelUserCourse()
        this.studentAccessConfimation = false;
      } catch (error) { }

    }
  }

  constructor(private tafbs: TekarchFbService, private el: ElementRef, private confirmationService: ConfirmationService) {
    this.tafbs.getDetailList<TAStudentManagement>("studentmanagement").subscribe(data => {
      this.studentaccess = data;
    });
  }

  ngOnInit() {
    this.cols = [
      { field: "UserId", header: "User ID" },
      { field: "UserName", header: "User Name" },
      { field: "CourseName", header: "Course" },
      // { field: "ProgramName", header: "Program" },
    ];
  }

  autoHideVisible(e){
if(e){
  this.calendarVisible=true
  this.expiryDate=null
}else{
  this.calendarVisible=false
}
  }
  SelectedRow(e) {
    this.SelectedRowData = e;
    this.studentAccessConfimation = true;

  }
  studentAccessDelete() {
    this.tafbs.deleteStudentManagement(this.SelectedRowData);
    this.studentAccessConfimation = false;
  }
  studentAccessCancel() {
    this.studentAccessConfimation = false;
  }


  AddStudentAccess() {
    this.ddReset = false;
    this.adddialogVisible = true;
  }
  AddUserCourse(studentDD, courseDD,expiryDate) {

    var flags = [], output = [], l = studentDD.length, i;
    for (i = 0; i < l; i++) {
      if (flags[studentDD[i].UserId]) continue;
      flags[studentDD[i].UserId] = true;
      output.push(studentDD[i]);
    }

    // var programslist = [], selectedProgram = [], length = programDD.length, j;
    // for (j = 0; j < length; j++) {
    //   if (programslist[programDD[j].ProgramName]) continue;
    //   programslist[programDD[j].ProgramName] = true;
    //   selectedProgram.push(programDD[j]);
    // }
if(expiryDate){
 
    if (output && courseDD) {
      // selectedProgram.forEach(d => {
      //   this.program = d;

      this.tafbs.addStudentPrograms(output, courseDD,expiryDate);

      // })

    } else {
      alert("Please, enter all the required fields");
    }
  }else{
    let date=new Date()
    let after365days = new Date(date.setDate(date.getDate() + 365))
    console.log(after365days);
    this.tafbs.addStudentPrograms(output, courseDD,after365days);
    
  }
  

    this.SelectedStudent = null;
    this.adddialogVisible = false;
    this.ddReset = true;
    this.autocomplete = true;
  }

  selectedprogramDD(e) {
    this.selectedProgramList = e;
  }

  CancelUserCourse() {
    this.ddReset = true;
    this.adddialogVisible = false;
  }
  SelectedStudentDD(e) {
    this.SelectedStudent = e;
  }

}
