import { Component, OnInit, Input } from '@angular/core';
import { TAResources } from 'src/app/Interface/resources';
import { TAUser } from 'src/app/Interface/ta-user';
import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TekarchDataService } from 'src/app/service/tekarchdataservice';

@Component({
  selector: 'app-interview-que-candidate-view-user',
  templateUrl: './interview-que-candidate-view-user.component.html',
  styleUrls: ['./interview-que-candidate-view-user.component.css']
})
export class InterviewQueCandidateViewUserComponent implements OnInit {
  _questionList: TAResources[];
  currentUser: TAUser;
  userAns: any;
  selectedRadioButton: string;
  val2: string = '';
  answerList: any[];
  _questionList1: any;
  aaaa: any;
  _heading: TAResources;
  selectedinputType: any = {};
  rad = [];
  arrayObject: { radiobuttonData: any; }[];
  questionNo: any;
  question: any;
  inputType: { Question: string; id: number }[];
  radiobuttonname: {};
  currentUserRole: string;
  paraAns: string;
  radiButtonData: { label: string; value: string; }[];
  display: boolean;
  EditUserData: TAResources;
  EditUserQuestion: string;
  UserQuestion: any;
  docRef: AngularFirestoreDocument;
  indexNo: any;
  checked1: boolean = false;
  studentAnswerDD: { name: string; }[];
  selectedAns: string;
  showaudio: boolean;
  @Input()
  public set questionlist(v: any[]) {
    this._questionList = v;
    this.getAnswer(this._questionList)
  }

  @Input()
  public set heading(v: TAResources) {
    this._heading = v;

    this.showaudio = this._heading.interviewpreparationaudioaccess; 
    // console.log(this._heading);
    
  }


  constructor(private tfs: TekarchFbService, private tds: TekarchDataService, public afs: AngularFirestore) {
    this.currentUser = this.tds.getCurrentUser();
    this.currentUserRole = this.tds.getCurrentUser().RoleName;
  }
  getAnswer(Ans) {
    this._questionList1=Ans
    if (this._questionList1)
    this._questionList1.map(element => {
        // this.tfs.getUserinterviewAnswers(this.currentUser, element).subscribe(d => {
        //   if (d) {
        //     this.aaaa = d
        //     let data={"name":this.aaaa.SelectedAnswer}
        //     element.SelectedAnswer = data
        //   }
        // })
        // 
        // console.log(Ans);
        // console.log(element);
        // console.log(element["CourseId"]);
        // console.log(element["krishna_softwiz@gmail_com"]);
        // console.log(element[this.currentUser.UserId]);
        var b=this.currentUser.UserId
        var key=b.replace(/\./g,"_");
    
       
       if(element[key]){
        let data={"name":element[key]};
          return  element.SelectedAnswer = data
        }
          //   this._questionList1=element
          //   console.log( this._questionList1);
            
          // return element
      });    
  }
  ngOnInit() {

    this.radiButtonData = [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" }
    ]

    this.studentAnswerDD = [
      { name: 'Need Time' },
      { name: 'Notes Taken' },
      { name: 'Ready for Interview' },
      { name: 'Need Help' },
      { name: 'None' }
    ]
  }

  trackbyfunction(index, item) {
    return index

  }

  onChange(e, ans) {
    var usersUpdate = {};
    var b=this.currentUser.UserId
    var key=b.replace(/\./g,"_");
    usersUpdate[`${key}`] = e.value.name;
    this.afs.collection("interviewquestions").doc(ans.id).update(usersUpdate);
    // this.tfs.adduserinterviewAns(this.currentUser, ans, e.value);
  }

  onClick(ans, selectedAns) {
    this.tfs.adduserinterviewAns(this.currentUser, ans, selectedAns);
  }




}
