import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-interviewans-datasheet',
  templateUrl: './interviewans-datasheet.component.html',
  styleUrls: ['./interviewans-datasheet.component.css']
})
export class InterviewansDatasheetComponent implements OnInit,OnDestroy {

  user: any;
  question: any;
  data: any;
  task: any;
  @Input()
  public set rowData(v: any) {
    this.user = v


  }
  @Input()
  public set colData(v: any) {

    this.question = v


   this.task= this.tafbs.getUserinterviewAnswerDATA(this.user.UserId, this.question.id).subscribe(d => {
      if (d) {
        this.data = d
      } else {
        this.data = null
      }


    })

  }
  constructor(private tafbs: TekarchFbService) { }

  ngOnInit() {
  }
ngOnDestroy(){
  this.task.unsubscribe()
}
}

