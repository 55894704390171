import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Observable } from "rxjs";
import { tap, finalize } from "rxjs/operators";
import {
  AngularFireUploadTask,
  AngularFireStorage
} from "@angular/fire/storage";
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TAResources } from 'src/app/Interface/resources';
import { TekarchDataService } from 'src/app/service/tekarchdataservice';
import { TAUser } from 'src/app/Interface/ta-user';
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  resource: TAResources;
  currentuser: TAUser;
  path: string;
@Input()

public set Resource(v : TAResources) {
  this.resource = v;  
}

  task: AngularFireUploadTask;

  // Progress monitoring
  percentage: Observable<number>;

  snapshot: Observable<any>;

  // Download URL
  downloadURL: Observable<string>;


  // State for dropzone CSS toggling
  isHovering: boolean;
  photourl: string;
  _candidate: string;
  _currentuser: string;
  queno:string
  @Output()
  imgsrc = new EventEmitter();
  constructor(
    private storage: AngularFireStorage,private tafs:TekarchFbService, private tads: TekarchDataService
  ) { }
  ngOnInit() {
    this.currentuser = this.tads.getCurrentUser()
  }
  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  startUpload(event: FileList) {      
    // The File object
    const file = event.item(0);
    // Client-side validation example
    if (file.type.split("/")[0] !== "image") {
      console.error("unsupported file type :( ");
      // return;
    }

    // The storage path
if(this.currentuser.RoleName=="Admin"){

  this.path = `Assignment/${this.resource.CourseId}/${this.resource.Heading}/${this.resource.Assignment}/Assignment`;
}else{
 this.path = `Assignment/${this.resource.CourseId}/${this.resource.Heading}/${this.resource.Assignment}/${this.currentuser.UserId}/${file.name}`;
}
  

    // Totally optional metadata
    const customMetadata = { app: "My AngularFire-powered PWA!" };

    // The main task
    this.task = this.storage.upload(this.path, file, { customMetadata });
    // setTimeout(() => {
    //   this.storage.ref(this.path).delete();
    // }, 10000);

    // Progress monitoring
    this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges().pipe(
      tap(snap => {
        if (snap.bytesTransferred === snap.totalBytes) {
          // Update firestore on completion
          this.downloadURL = this.storage.ref(this.path).getDownloadURL()          
          this.adddata(this.path, snap, this.downloadURL,this.resource.Assignment);
        }
      }),
      finalize(

        () =>
          (this.downloadURL = this.storage.ref(this.path).getDownloadURL()),
      )
    );
  
    // The file's download URL
  }

  // Determines if the upload task is active
  isActive(snapshot) {
    return (
      snapshot.state === "running" &&
      snapshot.bytesTransferred < snapshot.totalBytes
    );
  }

  async adddata(path, snap, downloadlink,que) {
    this.downloadURL = this.storage.ref(path).getDownloadURL()
    this.downloadURL.forEach(data => {

      this.photourl = data;
      // update the photo to the "user" collection
      if(this.currentuser.RoleName=="Admin"){
          this.tafs.profilephotoUpdate(this.resource, this.photourl)
        }
      else{
        if(que){
          // this.tafs.profilephotoUpdatebyCandidate( this.currentuser,this.resource, this.photourl,que)
        }else{
          alert("Enter Question number")
        }
        }
       this.imgsrc.emit(this.photourl)
     
     
    })
  }
 
}
