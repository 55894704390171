import { Component, OnInit, Input } from '@angular/core';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { webacc } from 'src/app/Interface/col-dd';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase/app';
import Timestamp = firestore.Timestamp;
import { async } from '@angular/core/testing';
@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.css']
})
export class AttendanceComponent implements OnInit {
  _courseId: number;
  userlist: any[];
  _webinar: webacc[];
  _attendance: webacc;
val1:string="classroom"

  @Input()
  public set courseId(v: number) {
    this._courseId = v;

    let unique_array1 = [];
    let unique_array2 = [];
    this.tafbs.getUserCourseUsingId(this._courseId).subscribe( data => {
      // console.log(data);
      let unique_array3 = [];
      data.forEach(element => {
        
        this.tafbs.getUserProfile(element.UserId).subscribe(d=>{
          if(d && d.RoleName=="Student"){
            unique_array3.push(d)
            }   })

      });
      this.userlist=unique_array3
      console.log(this.userlist);
      
    })
  }
  @Input()
  public set webinar(v:webacc[]) {
    this._webinar =  v
  //   .map(d =>{ 
  //     console.log(d);
      
  //  let a=d.start.seconds
  //     d.date=Timestamp.fromMillis(a * 1000).toDate()
  //     return d
  //   })
    console.log(this._webinar);
    
  }
  
  @Input()
  public set attendance(v:webacc) {
    this._attendance = v;
  }
  constructor(private tafbs: TekarchFbService,private db: AngularFirestore,) { }

  ngOnInit() {
  }
  RGB2HTML(a,e,red, green, blue)
  {
    if(e=="L"){
      var decColor =0x1000000+ blue + 0x100 * green + 0x10000 *red ;
      console.log('#'+decColor.toString(16).substr(1));
      let a=256
      return "rgb(0,256,0)"
    }
    
     if(e=="P"){
      var decColor =0x1000000+ blue + 0x100 * green + 0x10000 *red ;
      console.log('#'+decColor.toString(16).substr(1));
      let a=256
      return "rgb(0,256,0)"
    }
    // else{
    //   var decColor =0x1000000+ blue + 0x100 * green + 0x10000 *red ;
    //   console.log('#'+decColor.toString(16).substr(1));
    //   let a=256
    //   return "rgb(256,0,0)"
    // }
  }
  RGB2(e){
    if(e=="P"){
    
    var decColor =0x1000000+ 0 + 0x100 * 256 + 0x10000 *0 ;
    return '#'+decColor.toString(16).substr(1);
    }
  }
}
