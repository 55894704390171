import { Component, OnInit } from '@angular/core';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TekarchDataService } from 'src/app/service/tekarchdataservice';
import { TAResources, TAResourseType } from 'src/app/Interface/resources';
import { TACourse } from 'src/app/Interface/course';
import { TAProgram } from 'src/app/Interface/program';

@Component({
  selector: 'app-manage-video-resource',
  templateUrl: './manage-video-resource.component.html',
  styleUrls: ['./manage-video-resource.component.css']
})
export class ManageVideoResourceComponent implements OnInit {
  courselist: TACourse[];
  resourseTypelist: TAResourseType[];
  programlist: TAProgram[];
  SelectedCourse: TACourse;
  SelectedCourseId: number;
  SelectedCourseName: string;
  selectedProgram: TAProgram;
  selectedProgramName: string;
  resourseslist: TAResources[];
  SelectedResourceData: any;
  cols: { field: string; header: string; }[];
  resourceViewedlist: any;
  data: any

  constructor(private tafbs: TekarchFbService, private tads: TekarchDataService, ) {
    this.tafbs.getDetailList<TACourse>("courses").subscribe(data => {
      this.courselist = data;
    });
    this.tafbs.getDetailList<TAResourseType>("resourcetype").subscribe(data => {
      this.resourseTypelist = data
    })
    this.tafbs.getDetailList<TAProgram>("programs").subscribe(data => {
      this.programlist = data
    })

  }

  ngOnInit() {
    this.cols = [
      { field: "UserId", header: "User Id" },
      { field: "UserName", header: "User Name" },
      { field: "VideoPlayed", header: "Video Played" },
      { field: "VideoCompleted", header: "Video Completed" }
    ];
  }
  SelectedCourseDD(e) {
    this.resourseslist=null
    this.SelectedCourse = e;
    this.SelectedCourseId = e.CourseId;
    this.SelectedCourseName = e.CourseName;
  }
  selectedprogramDD(e) {
    this.resourseslist=[]
    this.selectedProgram = e;
    this.selectedProgramName = e.ProgramName;

    if (e) {
      this.tafbs.getUserProgress<TAResources>("resources", e.CourseId, e.ProgramId).subscribe(data => {
        this.resourseslist = data;
        this.UpdateResouresList(this.resourseslist);
      })
    }
  }
  UpdateResouresList(resourceList: TAResources[]) {
    this.resourseslist = resourceList;

    for (let i = 0; i < this.resourseslist.length; i++) {
      for (let j = 0; j < this.courselist.length; j++) {
        if (this.resourseslist[i].CourseId === this.courselist[j].CourseId) {
          this.resourseslist[i].CourseName = this.courselist[j].CourseName;
        }
        for (let k = 0; k < this.programlist.length; k++) {
          if (this.resourseslist[i].ProgramId === this.programlist[k].ProgramId) {
            this.resourseslist[i].ProgramName = this.programlist[k].ProgramName;
          }
          for (let l = 0; l < this.resourseTypelist.length; l++) {
            if (this.resourseslist[i].ResourceTypeId === this.resourseTypelist[l].ResourceTypeId) {
              this.resourseslist[i].ResourceTypeName = this.resourseTypelist[l].ResourceTypeName; // filtered to display resourcetypename
            }
          }
        }
      }
    }
  }
  SelectedResource(e) {
    this.SelectedResourceData = e;
    this.tafbs.getSelectedViewedResource(e.ResourceId).subscribe(d => {
      this.resourceViewedlist = d;
      var normalizedArray = this.resourceViewedlist.map(obj=> {
                  return obj.UserName;
                });
                var normalizedvalueArray = this.resourceViewedlist.map(obj=> {
                  return obj.average;
                });
                this.data={labels:normalizedArray,
                datasets: [ 
                          {
                              label: 'My First dataset',
                              backgroundColor: [
                                "#FF6384",
                                "#36A2EB",
                                "#FFCH96","#FFCE56","#D500F9","#3D5AFE","#1DE9B6","#FFFF00","#FF3D00"
                            ],
                              borderColor: '#1E88E5',
                              data: normalizedvalueArray,
                              
                          }
                      ]}
    })
  }
}
