import { Component, OnInit, Input } from '@angular/core';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TekarchDataService } from 'src/app/service/tekarchdataservice';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-predefined-interview-ques-heading',
  templateUrl: './predefined-interview-ques-heading.component.html',
  styleUrls: ['./predefined-interview-ques-heading.component.css']
})
export class PredefinedInterviewQuesHeadingComponent implements OnInit {
  question: any;
  EditHeading: any;
  EditQuestionHeading: any;
  display: boolean;
  [x: string]: any;
  click: boolean;
  _heading: any;
  currentuserRole: string;
  questionslist: any[];
  Questiondialog: boolean;
  EditUserQuestion: any;
  EditUserData: any;

  @Input()
  public set Click(v: boolean) {
    if (v) {
      this.click = v;
    }
  }
  @Input()
  public set heading(v: any) {
    this._heading = v;
    // console.log(v);

    this.tfs.getselectedpredefinedInterviewquestions(this._heading.id).subscribe(data => {
      this.questionslist = data;
    })
    
 

  }
  constructor(private tfs: TekarchFbService, private tds: TekarchDataService, public afs: AngularFirestore) {
    this.currentuserRole = this.tds.getCurrentUser().RoleName;
  }
  ngOnInit() {
  }
  EditiconClick(field) {
    this.EditUserData = field;
    this.EditUserQuestion = field.Question;
    this.Questiondialog = true;
  }
  clickTime(){
    const clicktime = new Date().getTime()
    this.tds.setAccordiantime(clicktime)
  }
  UpdateQuestion(field, qus) {
   
    this.tfs.updatePredefinedInterviewques(this._heading.id, qus,field.id);
    this.Questiondialog = false;
  }
  cancelQuestion(field) {

    this.tfs.getselectedpredefinedInterviewquestions(this._heading.id).subscribe(data => {
      this.questionslist = data;
    })
    this.Questiondialog = false;
  }
  deleteQuestion(field) {
    this.tfs.deletePredefinedInterviewques(this._heading.id,field.id);
    this.Questiondialog = false;
  }
  isNullOrEmpty(str) {
    return !str || !str.trim();
  }
  saveQuestions(q) {
    let id = this.afs.createId();
    if (q) {
      q = q.charAt(0).toUpperCase() + q.slice(1);
      if (this.isNullOrEmpty(q)) {
        return;
      } else {
        this.tfs.addpredefinedInterviewQuestion(this._heading.id,id,q);
        // this.tfs.getInterviewQuestionList("questions", "IndexNo").pipe(take(1)).subscribe(d => {
        //   if (d[0] == undefined) {
        //     this.indexnumber = 1;
        //   } else {
        //     this.indexnumber = d[0].IndexNo + 1;
        //   }
        //   this.tfs.addQuestionForUsers(this._heading, q, id, this.questionNo, this.indexnumber);
        // })

      }
    }

    this.question = null;
  }
  deleteHeadingDialog(h) {
    const clicktime = new Date().getTime()
    this.tds.setAccordiantime(clicktime)
    this.EditHeading = h;
    this.EditQuestionHeading = this.EditHeading.id;
    this.display = true;
  }
 
  cancelHeading() {
    this.tfs.getselectedpredefinedInterviewquestions(this._heading.id).subscribe(data => {
      this.questionslist = data;
    })
    this.display = false;
  }
  deleteHeading() {

    this.tfs.deleteInterviewpredefinedHeading(this._heading.id);
    // }
    this.display = false;
  }
  
}
