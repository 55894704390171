// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {

    // apiKey: "AIzaSyD-KI_pqab82JKuU_N6GUn_z5rQexZOkZs",
    // authDomain: "bluelinedatabackup.firebaseapp.com",
    // databaseURL: "https://bluelinedatabackup.firebaseio.com",
    // projectId: "bluelinedatabackup",
    // storageBucket: "bluelinedatabackup.appspot.com",
    // messagingSenderId: "35306724375",
    // appId: "1:35306724375:web:d66e2f418cdfb54f"

    // apiKey: "AIzaSyAqxeT2MTmQdJ1H9GZUZd1V1v6jSc6HVLg",
    // authDomain: "bluelinecertication-dev.firebaseapp.com",
    // databaseURL: "https://bluelinecertication-dev.firebaseio.com",
    // projectId: "bluelinecertication-dev",
    // storageBucket: "bluelinecertication-dev.appspot.com",
    // messagingSenderId: "390760132245",
    // appId: "1:390760132245:web:0cc580fa31498961"

    // apiKey: "AIzaSyBDshMN6GBBR7tFoTReT20Shxn8PponRpQ",
    // authDomain: "tekarchwebportal.firebaseapp.com",
    // databaseURL: "https://tekarchwebportal.firebaseio.com",
    // projectId: "tekarchwebportal",
    // storageBucket: "tekarchwebportal.appspot.com",
    // messagingSenderId: "328280640649",
    // appId: "1:328280640649:web:9efea7859e1e98de"

    // apiKey: "AIzaSyAy08lghxP7rP3TqTfk9inH2VT_YzIfE9E",
    // authDomain: "tekarchwebportal-qas.firebaseapp.com",
    // databaseURL: "https://tekarchwebportal-qas.firebaseio.com",
    // projectId: "tekarchwebportal-qas",
    // storageBucket: "tekarchwebportal-qas.appspot.com",
    // messagingSenderId: "586787157091",
    // appId: "1:586787157091:web:c77f1cb96e87adef9f3456",
    // measurementId: "G-KJMVZCK9G9"
    apiKey: "AIzaSyBDshMN6GBBR7tFoTReT20Shxn8PponRpQ",
    authDomain: "tekarchwebportal.firebaseapp.com",
    databaseURL: "https://tekarchwebportal.firebaseio.com",
    projectId: "tekarchwebportal",
    storageBucket: "tekarchwebportal.appspot.com",
    messagingSenderId: "328280640649",
    appId: "1:328280640649:web:9efea7859e1e98de"
    // apiKey: "AIzaSyAy08lghxP7rP3TqTfk9inH2VT_YzIfE9E",
    // authDomain: "tekarchwebportal-qas.firebaseapp.com",
    // databaseURL: "https://tekarchwebportal-qas.firebaseio.com",
    // projectId: "tekarchwebportal-qas",
    // storageBucket: "tekarchwebportal-qas.appspot.com",
    // messagingSenderId: "586787157091"
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
