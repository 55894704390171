import { Component, OnInit, Input } from '@angular/core';
import { TAResources } from 'src/app/Interface/resources';
import { TAProgram } from 'src/app/Interface/program';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TekarchDataService } from 'src/app/service/tekarchdataservice';
import { AngularFirestore } from '@angular/fire/firestore';
import { take } from 'rxjs/operators';
import { TAStudentManagement } from 'src/app/Interface/studentmanagement';
import { TAUser } from 'src/app/Interface/ta-user';

@Component({
  selector: 'app-interview-que-heading-user',
  templateUrl: './interview-que-heading-user.component.html',
  styleUrls: ['./interview-que-heading-user.component.css']
})
export class InterviewQueHeadingUserComponent implements OnInit {

  resource: any;
  resourcelist: any;
  _heading: any;
  click: boolean = false;
  showModel: boolean = false
  resourceList: TAResources[];
  questionslist: any[];
  display: boolean = false;
  EditHeading: any;
  EditQuestionHeading: any;
  currentuserRole: string;
  questionslistQus: any;
  courseId: number;
  courseHeading: string;
  tableDialog: boolean = false;
  questionNo: number;
  indexNo: number;
  question: any;
  EditUserData: any;
  EditUserQuestion: any;
  Questiondialog: boolean;
  indexnumber: any;
  _selecteduser: TAStudentManagement;
  visibleaudio:boolean = false;
  currentuser:TAUser;

  @Input()
  public set Click(v: boolean) {
    if (v) {
      this.click = v;
    }
  }
  @Input()
  public set SelectedStudent(v: TAStudentManagement) {
    this._selecteduser = v;
  }
  @Input()
  public set heading(v: TAProgram) {
    this._heading = v;
    this.getQuestion(this._heading)

    // this.tafbs.getResourceProgramList<TAResources>("resources", this._program).subscribe(data => {
    //   this.UpdateResourceList(data)
    // }
    // );
  }
  constructor(private tfs: TekarchFbService, private tds: TekarchDataService, public afs: AngularFirestore) {
    this.currentuserRole = this.tds.getCurrentUser().RoleName;
    this.currentuser= this.tds.getCurrentUser();
  }

  ngOnInit() {

  }
  trackbyfunction(index, item) {
    return index

  }
  isNullOrEmpty(str) {
    return !str || !str.trim();
  }
   checked(){
   if(this.questionslist)
   return this.questionslist.every(data=>{
      return  data.visible==true
    })
  }
  EditiconClick(field) {


    this.EditUserData = field;
    this.EditUserQuestion = this.EditUserData.Question;
    this.Questiondialog = true;
  }
  SelectAllVisible(e,val){
    const clicktime = new Date().getTime()
    this.tds.setAccordiantime(clicktime)
    let batch = this.afs.firestore.batch();
    val.forEach(data=>{
      this.afs.collection("userquestions").doc(this._selecteduser.UserId).collection("que").doc(data.id).update({visible:e.checked})
    })
   
    batch.commit().catch(err => console.error(err));
  }
  saveQuestions(q) {
    let id = this.afs.createId();

    if (q) {

      q = q.charAt(0).toUpperCase() + q.slice(1);
      if (this.isNullOrEmpty(q)) {
        return;
      } else {
        this.tfs.getInterviewQuestionListuser("userquestions",this._selecteduser.UserId, "IndexNo").pipe(take(1)).subscribe(d => {
          if (d[0] == undefined) {
            this.indexnumber = 1;
          } else {
            this.indexnumber = d[0].IndexNo + 1;
          }
          this.tfs.addinterviewQuestionForusersIP(this._heading,this._selecteduser.UserId, q, id, this.questionNo, this.indexnumber);
        })
      }
    }

    this.question = null;
  }
  UpdateQuestion(field, qus) {
    this.tfs.getUserCourseIdIP(this._heading).subscribe(d => {
      var result = d.reduce((unique, o) => {
        if (!unique.some(obj => obj.UserId === o.UserId)) {
          unique.push(o);
        }
        return unique;
      }, []);
      result.forEach(data => {

        this.tfs.updateInterviewQuestionsuser(field,this._selecteduser.UserId, qus, data);
      })
    })


    // this.tfs.interviewquestionUpdate(field, qus);
    this.Questiondialog = false;
  }
  cancelQuestion() {

    this.tfs.getQuestionbyHeadinguser("userquestions", this._selecteduser.UserId,this._heading.Heading).subscribe(data => {
      this.questionslist = data;
    })
    this.Questiondialog = false;
  }
  deleteQuestion(field) {

    this.tfs.getUserCourseId(this._heading).subscribe(d => {
      var result = d.reduce((unique, o) => {
        if (!unique.some(obj => obj.UserId === o.UserId)) {
          unique.push(o);
        }
        return unique;
      }, []);
      result.forEach(data => {
        this.tfs.deleteinterviewQuestionsuser(field, this._selecteduser.UserId, data);
      })
    })

    // this.tfs.deleteinterviewQuestion(field);
    this.Questiondialog = false;
  }
  handleChange(e, que) {

    // console.log(e.checked,this._selecteduser.UserId);
    
    var usersUpdate = {};
  var b=this._selecteduser.UserId
  var key=b.replace(/\./g,"_");
  usersUpdate[`${key}`] = e.checked;
  // console.log(que);
  this.afs.collection("userquestions").doc(que.id).update(usersUpdate)
    // this.tfs.updatevisiblestatususer("userquestions",this._selecteduser.UserId, usersUpdate, que,e.checked)
  }
  getQuestion(_heading) {
   
    if (this.currentuserRole == "Student") {

      this.tfs.getQuestionbyHeadingForStudentinterviewuser("userquestions",this.currentuser.UserId, _heading.id).subscribe(data => {
        let quelist=[]
         var b=this.currentuser.UserId
         var key=b.replace(/\./g,"_");
         let element:any
         data.forEach(element => {
         if(element[key]==true){
          quelist.push(element)
         }
       });
       this.questionslist=quelist
      })
    }
    else {
      this.tfs.getQuestionbyHeadinginterviewuser("userquestions",this._selecteduser.UserId, _heading.id).subscribe(data => {

        var b=this._selecteduser.UserId
        var key=b.replace(/\./g,"_");
        let quelist=[]
        data.forEach(element => {
          let val:any=element;
          val.visible=element[key]?element[key]:false
          quelist.push(val)
       });
       this.questionslist = quelist;
      })
    }

  }
  clickTime() {
    const clicktime = new Date().getTime()
    this.tds.setAccordiantime(clicktime)
  }
  deleteHeadingDialog(h) {

    const clicktime = new Date().getTime()
    this.tds.setAccordiantime(clicktime)
    this.EditHeading = h;
    this.EditQuestionHeading = this.EditHeading.Heading;
    this.display = true;
  }
  async UpdateHeading(h, sh) {

    this.tfs.updateHeadingresourcesip(h, sh)
    if (this.questionslist&&this.questionslist.length != 0) {
      await this.tfs.getUserCourseIdIP(this._heading).subscribe(d => {
        var result = d.reduce((unique, o) => {
          if (!unique.some(obj => obj.UserId === o.UserId)) {
            unique.push(o);
          }
          return unique;
        }, []);
        return result.forEach(data => {
          this.questionslist.forEach(d => {
            // this.tfs.updateHeadings(d, data, sh);
            this.tfs.InterviewQuestionColHeadingUpdateIP(d, sh);
            // this.tfs.updateHeadingresources(h, sh)
          })
        })
      })
    } else {
      // this.tfs.updateHeadingresources(h, sh)
    }

    this.display = false;
  }

  cancelHeading() {

    this.tfs.getQuestionbyHeadinguser("usertopic",this._selecteduser.UserId, this._heading.Heading).subscribe(data => {
      this.questionslist = data;
    })
    this.display = false;
  }
  deleteHeading() {

    if (this.questionslist&&this.questionslist.length != 0) {
      this.tfs.getUserCourseIdIP(this._heading).subscribe(d => {
        var result = d.reduce((unique, o) => {
          if (!unique.some(obj => obj.UserId === o.UserId)) {
            unique.push(o);
          }
          return unique;
        }, []);
        result.forEach(data => {
          this.questionslist.forEach(d => {
            this.questionslistQus = d;
            // this.tfs.deleteinterviewQuestionsanswer(d, data);
            this.tfs.deleteinterviewQuestion(d);
            this.tfs.deleteInterviewQuestionHeadingIP(this._heading);
          })
        })
      })
    } else {
      this.tfs.deleteInterviewQuestionHeadingIP(this._heading);
    }
    this.display = false;
  }
  answerTable(heading) {
    const clicktime = new Date().getTime()
    this.tds.setAccordiantime(clicktime)
    this.courseId = heading.CourseId;
    this.courseHeading = heading.id;
    this.tableDialog = true;
  }

  interviewaudioAccess(e,h){
    // console.log(e);
    // console.log(h);
    
    const clicktime = new Date().getTime()
    this.tds.setAccordiantime(clicktime)
    this.visibleaudio = e;
    this.tfs.addInterviewPreperation(e,h);
  }
  interviewheadingAccess(e,h){
    const clicktime = new Date().getTime()
    this.tds.setAccordiantime(clicktime)
    var usersUpdate = {};
    var b=this._selecteduser.UserId
    var key=b.replace(/\./g,"_");
    usersUpdate[`${key}`] = e;
   
    this.afs.collection("usertopic").doc(h.id).update(usersUpdate)
    // this.tfs.addIPheadingaccess(e,h,this._selecteduser.UserId);
    // console.log(e);
    
  }
}
