import { Component, OnInit } from '@angular/core';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TAProgram } from 'src/app/Interface/program';
import { TekarchDataService } from 'src/app/service/tekarchdataservice';

@Component({
  selector: 'app-predefined-questions',
  templateUrl: './predefined-questions.component.html',
  styleUrls: ['./predefined-questions.component.css']
})
export class PredefinedQuestionsComponent implements OnInit {
  programListData: TAProgram[];
  accordion: string;
  predefinedQuestionList: any[];
  heading: any;

  constructor( private tafbs: TekarchFbService,private tads: TekarchDataService,) { 
    this.tafbs.getpredefinedQuestionList().subscribe(d => {
      this.predefinedQuestionList = d;
      // console.log(d);
      
    })
  }

  ngOnInit() {
  }
  Accordian(e) {
    // console.log('aaa');
    
    const clicktime = new Date().getTime()
    if (clicktime - this.tads.getAccordiantime() < 1000) {
      return
    } else {
      if (e) {
        if (e == this.tads.getviewprogram()) {
          this.tads.setviewprogram("null");
          this.accordion = "null"
        }
        else {
          this.accordion = e
          this.tads.setviewprogram(e);
        }
      }

    }
  }
  saveHeading(q){
    this.tafbs.addpredefinedHeading(q);
    this.heading = null;
  }
}
