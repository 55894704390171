// selected video to play 
//  update progress for video time


import { Component, OnInit, Input, HostListener } from '@angular/core';
import { TAProgram } from '../../Interface/program';
import { TekarchFbService } from '../../service/tekarchfbservice';

import { SafeResourceUrl } from '@angular/platform-browser';
import Player from "@vimeo/player";
import { TekarchDataService } from 'src/app/service/tekarchdataservice';
import { TAUser } from 'src/app/Interface/ta-user';
import { TAResources } from 'src/app/Interface/resources';
import { retry } from 'rxjs/operators';
@Component({
  selector: 'app-program-video',
  templateUrl: './program-video.component.html',
  styleUrls: ['./program-video.component.css']
})
export class ProgramVideoComponent implements OnInit {
  resource: any;
  currentUrl: SafeResourceUrl;
  resourcelist: TAResources;
  _program: TAProgram;
  showModel: boolean = false
  currentuser: TAUser;
  getuserstatusvalue: TAResources = {};
  startInterval: any;
  playtime: number
  uploaddialog: boolean;

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {

    if (event.key === "Escape") {
      // Do things
      try {
        // this.closableIconClick()
      } catch (error) { }
    }
  }

  @Input()
  public set Resource(v: TAResources) {
    this.resourcelist = v;
  }
  constructor(private tafbs: TekarchFbService, private tads: TekarchDataService) { }

  ngOnInit() {
    this.currentuser = this.tads.getCurrentUser()
  }
  trackbyfunction(index, item) {
    if (!item) return index

    return item
  }

  // async openvideo(r) {
  // let a= r.Heading.toLowerCase().includes("assignment")?true:false
  // if(a==true){
  //   this.uploaddialog=true
  //   return;
  // } 
    
  //   this.resource = r;
  //   this.showModel = true;
  //   let videoplaytime: number = 0;


  //   this.getuserstatusvalue = await this.tafbs.getuservideostatus(r, this.currentuser.UserId);
  //   this.startInterval = setInterval(data => {
  //     try {
  //       var iframe = document.querySelector('iframe');
  //       var player = new Player(iframe);
  //       this.addTimer(videoplaytime)
  //       player.getCurrentTime().then(function (seconds) {
  //         if (videoplaytime <= seconds) {
  //           videoplaytime = seconds;
  //         }
  //       });
  //     } catch (error) {
  //     }

  //   }, 1000);


  // }
  // addTimer(a) {
  //   this.playtime = a
  // }
  // async closableIconClick() {
  //   this.showModel = false;
  //   clearInterval(this.startInterval);
  //   var iframe = document.querySelector('iframe');
  //   try {
  //     var player = new Player(iframe);
  //     let videoduration: number = 0;
  //     let completetiming: number = 0;
  //     let halfvideoduration: number = 0;
  //     let average: number = 0
  //     player.getEnded().then(function (ended) {
  //       // `ended` indicates whether the video has ended
  //     });
  //     await player.getDuration().then(function (duration) {
  //       // `ended` indicates whether the video has ended
  //       videoduration = duration
  //     });
  //     await player.getCurrentTime().then(function (seconds) {
  //       // `seconds` indicates the current playback position of the video
  //       completetiming = seconds
  //       halfvideoduration = videoduration / 2;
  //       average = completetiming / videoduration * 100
  //     });
  //     // player.getLoop().then(function(loop) {
  //     //   // `loop` indicates whether the loop behavior is active

  //     // });
  //     // player.getVideoTitle().then(function (title) {
  //     // });
  //     // if (this.currentuser.RoleName == "Student" && !this.getuserstatusvalue || this.getuserstatusvalue && this.getuserstatusvalue.VideoCompleted == "No") {
  //     this.updatestatusforvideo(videoduration)
  //     // }
  //   } catch (error) {
  //     console.log('this is not a vimeo video');

  //   }


  // }

  // updatestatusforvideo(videoduration) {
  //   let average: number = 0

  //   average = (this.playtime / videoduration) * 100

  //   let avg = this.getuserstatusvalue ? this.getuserstatusvalue.average : 0

  //   if (avg < average && this.currentuser.RoleName == "Student") {
  //     this.tafbs.updateUserVideoStatus(this.resource, this.currentuser, average);
  //     this.tafbs.updateUserVideoStatusforUser(this.resource, this.currentuser, average);
  //   }
  // }
  contextmenu1() {
  
    return false
  }

}


