import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TAUser } from 'src/app/Interface/ta-user';
import { AutoComplete } from 'primeng/autocomplete';

@Component({
  selector: 'app-user-dd',
  templateUrl: './user-dd.component.html',
  styleUrls: ['./user-dd.component.css']
})
export class UserDdComponent implements OnInit {
  studentList: TAUser[];
  selectedStudent: TAUser[];
  inputValue: string;
  empList: Array<TAUser> = [];
  _autocom: boolean;
  ddsize: number =40;
  multiple:boolean=true
  @Output()
  SelectedStudents: EventEmitter<TAUser[]> = new EventEmitter<TAUser[]>();

  @Input()
  public set Multiple(v: boolean) {
    this.multiple=v;
    
  }
  @Input()
  public set ddSize(v : number) {
    this.ddsize = v;
    // console.log(v);
    
  }
  

  @Input()
  public set ddReset(v: boolean) {
    this.selectedStudent = null;
    if (v == true) {
      
      this.selectedStudent = null;
      this.empList = []
    this.tafbs.getStudentlist<TAUser>("candidate").subscribe(data => {
      this.studentList = this.filterAction('', data);
    })
     
    }
  }

  constructor(private tafbs: TekarchFbService) {
    
  }

  ngOnInit() {
    this.tafbs.getStudentlist<TAUser>("candidate").subscribe(data => {
     this.studentList = this.filterAction('', data);
    })

  }
  onKeyPress(event) {

    this.tafbs.getStudentlist<TAUser>("candidate").subscribe(data => {
      this.studentList = this.filterAction(event.query, data);
     })
  }

  filterAction(query, data: TAUser[]): TAUser[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: TAUser[] = [];
    for (let i = 0; i < data.length; i++) {
      let selectedStudent = data[i];
      if (selectedStudent.UserId.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(selectedStudent);
      }
    }
    return filtered;
  }

  onDropDownSelect(e) {
// console.log(e);

    if(this.multiple){
    if (this.empList.indexOf(e) === -1) {
      this.empList.push(e);
      this.SelectedStudents.emit(this.empList);
    }
  }else{
    this.SelectedStudents.emit(e);
  }
  }
  onDropDownUnSelect(e) {
    const index = this.empList.findIndex(data => data.UserId === e.UserId);
    this.empList.splice(index, 1); 
    this.SelectedStudents.emit(this.empList);
  }
 

}
