import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TAStudentManagement } from 'src/app/Interface/studentmanagement';
import { TAProgram } from 'src/app/Interface/program';

@Component({
  selector: 'app-program-dd',
  templateUrl: './program-dd.component.html',
  styleUrls: ['./program-dd.component.css']
})
export class ProgramDdComponent implements OnInit {

  inputValue: string;
  programlist: TAProgram[];
  SelectedProgramName: TAProgram;
  _disabled: boolean = false;
  selectedCourse: string;

  @Output()
  SelectedProgram = new EventEmitter();

  @Input()
  ddSize: number;


  @Input()
  public set disabled(v: boolean) {
    this._disabled = v;
  }

  @Input()
  public set SelectedCourse(v: string) {
    this.selectedCourse = v;
    this.tafbs.getProgrambyCourse<TAProgram>("programs", this.selectedCourse).subscribe(data => {
      this.programlist = data;
    });
    this.SelectedProgramName = null;
  }

  @Input()
  set ddObj(value: TAProgram) {
    this.SelectedProgramName = value
  }

  @Input()
  public set ddReset(v: string) {

    if (v == null || v == undefined) {
      this.SelectedProgramName = null;
    }
  }

  constructor(private tafbs: TekarchFbService) {
    if (this.selectedCourse) {
      this.tafbs.getProgrambyCourse<TAProgram>("programs", this.selectedCourse).subscribe(data => {
        setTimeout(() => {
          this.programlist = data;
        }, 100)
      });
    }
  }

  ngOnInit() {

  }
  //#region keypress filter 

  onKeyPress(event) {
    this.tafbs.getProgrambyCourse<TAProgram>("programs", this.selectedCourse).subscribe(data => {
      this.programlist = this.filterAction(event.query, data);
    });
  }

  filterAction(query, data: TAProgram[]): TAProgram[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: TAProgram[] = [];
    for (let i = 0; i < data.length; i++) {
      let selectedProgramName = data[i];
      if (selectedProgramName.ProgramName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(selectedProgramName);
      }
    }
    return filtered;
  }

  //#endregion

  onDropDownSelect(e) {
    this.SelectedProgram.emit(e);
  }
  onDropdownClick(e) {
    this.tafbs.getProgrambyCourse<TAProgram>("programs", this.selectedCourse).subscribe(data => {
      this.programlist = data;
    });
  }


}
