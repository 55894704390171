import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import * as firebase from "firebase/app";
import { Observable, of } from "rxjs";
import { first, switchMap, take } from "rxjs/operators";
import { NotifyService } from "./notify.service";
import { AngularFireAuth } from "@angular/fire/auth";
import {
    AngularFirestore,
    AngularFirestoreDocument
} from "@angular/fire/firestore";
import { TekarchDataService } from "../service/tekarchdataservice";
import { TekarchFbService } from "../service/tekarchfbservice";
import { TAUser } from "../Interface/ta-user";
import { HttpClient } from "@angular/common/http";

interface User {
    Ip?: String;
    uid?: string;
    UserId?: string;
    ImageUrl?: string;
    UserName?: string;
    RoleName?: string;
    email?: string;
    photoURL?: string;
    displayName?: string;
    favoriteColor?: string;
    mobno?: number;
    role?: string;
    userstatusvalue?: string;
}

@Injectable()
export class AuthService {
    user: Observable<User>;
    getuser: TAUser;
    initialuser: any;
    userProfile: Object;
    ipAddress: { ip: string; };
    // authState: FirebaseAuthState = null;

    constructor(
        private afAuth: AngularFireAuth,
        private afs: AngularFirestore,
        private router: Router,
        private bls: TekarchDataService,
        private blfb: TekarchFbService,
        private notify: NotifyService, private http: HttpClient
    ) {

        //// Get auth data, then get firestore user document || null
        this.user = this.afAuth.authState.pipe(
            switchMap(user => {
                if (user) {
                    let username=user.displayName.charAt(0).toUpperCase() + user.displayName.slice(1);
                    const data: any = {
                        uid: user.uid,
                        UserId: user.email,
                        UserName: username,
                        ImageUrl: user.photoURL,
                        mobno: null,
                        RoleName: "None",
                    };
                    this.blfb.getUserProfile(user.email).subscribe(user => {
                        this.getuser = user;
                        if (this.getuser == undefined) {
                            this.bls.setCurrentUser(this.initialuser);
                        } else {
                            this.bls.setCurrentUser(this.getuser);
                        }
                    });
                    return this.afs
                        .doc<TAUser>(`candidate/${user.email}`)
                        .valueChanges();
                } else {

                    return of(null);
                }
            })
        );
    }
    emailSignUp(email: string, password: string) {
        return this.afAuth.auth
            .createUserWithEmailAndPassword(email, password)
            .then(credential => {
                // this.notify.update('Welcome new user!', 'success');
                this.updateUserData(credential); // if using firestore
            })
            .catch(error => this.handleError(error));
    }
    emailLogin(email: string, password: string) {
        return this.afAuth.auth
            .signInWithEmailAndPassword(email, password)
            .then(credential => {
                // this.notify.update('Welcome back!', 'success');
                // return
                this.updateUserData(credential);
            })
            .catch(error => this.handleError(error));
    }
    private handleError(error: Error) {
        // console.error(error);
        this.notify.update(error.message, "error");
    }
    googleLogin() {
        const provider = new firebase.auth.GoogleAuthProvider();
        return this.oAuthLogin(provider);
    }

    private oAuthLogin(provider) {
        return this.afAuth.auth.signInWithPopup(provider).then(credential => {
            this.getdata(credential.user);
        });
    }
    getUser() {
        return this.user.pipe(first()).toPromise();
    }

    getdata(user) {
        this.initialuser = user;
        this.blfb.getUserProfile(user.email).pipe(take(1)).subscribe(user => {
            this.getuser = user;
            if (this.getuser == undefined) {
                this.updateUserData(this.initialuser);
            } else {
                this.updateUserData(this.getuser);
            }
        });
    }

    private updateUserData(user) {
        // Sets user data to firestore on login
// console.log(user);

        if (user.email) {
            const userRef: AngularFirestoreDocument<any> = this.afs.doc(
                `candidate/${user.email}`
            );
            let username=user.displayName.charAt(0).toUpperCase() + user.displayName.slice(1);
            const data: User = {
                UserId: user.email,
                UserName: username,
                ImageUrl: user.photoURL,
                userstatusvalue: "Not Placed",
                RoleName: this.getuser == undefined ? "None" : this.getuser.RoleName,
                Ip: this.ipAddress ? this.ipAddress.ip : "none"
            };
            this.router.navigate(["/welcome"]);
console.log('111');

            return userRef.set(data, { merge: true });
        } else if (user.UserId) {
            const userRef: AngularFirestoreDocument<any> = this.afs.doc(
                `candidate/${user.UserId}`
            );
            // console.log('222',user);
            const data: User = {
                UserId: user.UserId,
                UserName: user.UserName,
                ImageUrl: user.ImageUrl,
                userstatusvalue: "Not Placed",
                RoleName: this.getuser == undefined ? "None" : this.getuser.RoleName
            };
            this.router.navigate(["/welcome"]);


            return userRef.set(data, { merge: true });
        }


    }

    signOut() {
        this.afAuth.auth.signOut().then(() => {
            this.router.navigate(["/"]);
        });
    }

    IsAdmin(): boolean {
        if (!this.bls.getCurrentUser()) return false;
        return this.bls.getCurrentUser().RoleName == "Admin" ? true : false;
    }
    IsStudent() {
        if (!this.bls.getCurrentUser()) return false;
        return this.bls.getCurrentUser().RoleName == "Student" ||
            this.bls.getCurrentUser().RoleName == "Admin" ||
            this.bls.getCurrentUser().RoleName == "Coach" ||
            this.bls.getCurrentUser().RoleName == "Recruiter" ||
            this.bls.getCurrentUser().RoleName == "Vendor"
            ? true
            : false;
    }
    IsVendor() {
        if (!this.bls.getCurrentUser()) return false;
        return this.bls.getCurrentUser().RoleName == "Admin" ||
            this.bls.getCurrentUser().RoleName == "Vendor"
            ? true
            : false;
    }
    IsCoach() {
        if (!this.bls.getCurrentUser()) return false;
        this.bls.getCurrentUser().RoleName == "Admin" ||
            this.bls.getCurrentUser().RoleName == "Coach" ||
            this.bls.getCurrentUser().RoleName == "Recruiter"
            ? true
            : false;
    }
    IsRecruiter() {
        if (!this.bls.getCurrentUser()) return false;
        return this.bls.getCurrentUser().RoleName == "Admin" ||
            this.bls.getCurrentUser().RoleName == "Recruiter" ||
            this.bls.getCurrentUser().RoleName == "Coach"
            ? true
            : false;
    }
    public authenticated() {
        var user = firebase.auth().currentUser;
        if (user) {
            return true;
        } else {
            return false
        }


    }
}
