import { Component, OnInit, Input } from '@angular/core';
import { TAResources } from 'src/app/Interface/resources';
import { TAProgram } from 'src/app/Interface/program';
import { TekarchFbService } from 'src/app/service/tekarchfbservice';
import { TekarchDataService } from 'src/app/service/tekarchdataservice';
import { AngularFirestore } from '@angular/fire/firestore';
import { take } from 'rxjs/operators';
import { AudioRecordingService } from 'src/app/service/audio-recording.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TAStudentManagement } from 'src/app/Interface/studentmanagement';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-question-heading',
  templateUrl: './question-heading.component.html',
  styleUrls: ['./question-heading.component.css']
})
export class QuestionHeadingComponent implements OnInit {
  resource: any;
  resourcelist: any;
  _heading: any;
  click: boolean = false;
  showModel: boolean = false
  resourceList: TAResources[];
  questionslist: any[];
  display: boolean = false;
  EditHeading: any;
  EditQuestionHeading: any;
  currentuserRole: string;
  questionslistQus: any;
  courseId: number;
  courseHeading: string;
  tableDialog: boolean = false;
  question: any;
  questionNo: number;
  indexNo: number;
  EditUserData: any;
  EditUserQuestion: any;
  Questiondialog: boolean;
  indexnumber: any;
  isRecording = false;
  starRrec:boolean=false
  recordedTime;
  blobUrl;
  cities2: { name: string; }[];
  selectedUser: SelectItem[];
  audioSelectedUser:string[]=[];
  selectedCars2: { name: string; }[];
  _heading1: any;
  userdata: any;
  userlist: any[];

  

  @Input()
  public set Click(v: boolean) {
    if (v) {
      this.click = v;
    }
  }
  @Input()
  public set heading(v: TAProgram) {
    this._heading = v;
    if(this._heading.CourseId){
      let unique_array1 = [];
      let unique_array2 = [];
      this.tfs.getUserCourseUsingId(this._heading.CourseId).subscribe(async data => {
        for (let i = 0; i < data.length; i++) {
          if (unique_array1.indexOf(data[i].UserId) == -1) {
            data.map(item=>{
              var b=item.UserId
              // var key=b.replace(/\./g,"_");
               item.label=item.UserId
               item.value=item.UserId
                return   item
            })
            unique_array1.push(data[i].UserId);
            unique_array2.push(data[i]);
          }
        }
        this.selectedUser = unique_array2;
        // console.log(   this.selectedUser);
        
      });}
    // this._heading.SelectedAns=v["ashwinraj0010@gmail.com"]
    // this._heading1=this.heading.map( this._heading=>this._heading["ashwinraj0010@gmail.com"])
   
    if(this._heading){
    
      this.tfs.getAudioAccessusers(this._heading.id).subscribe(d => {
    //  console.log(d); 
      this.audioSelectedUser=[]
   d.forEach(s=>{
    this.audioSelectedUser.push(s.UserId)
    // console.log(this.audioSelectedUser);
   })
   
      
        // this.selectedCars2 = [
        //   {name: 'New York'},
        //   {name: 'Rome'},
        // ];
        // console.log(this.selectedCars2);
        
         
      })
    }

    if (this.currentuserRole == "Student") {

      this.tfs.getQuestionbyHeadingForStudent("questions", this._heading.id).subscribe(data => {
        this.questionslist = data;
      })
    }
    else {
      this.tfs.getQuestionbyHeading("questions", this._heading.id).subscribe(data => {
        this.questionslist = data;
      })
    }

  }
  constructor(private tfs: TekarchFbService, private tds: TekarchDataService,private db: AngularFirestore, public afs: AngularFirestore,private audioRecordingService: AudioRecordingService,private sanitizer: DomSanitizer) {
    this.currentuserRole = this.tds.getCurrentUser().RoleName;
    // this.currentuser = this.tds.getCurrentUser();
    // if(this._heading){
    //   this.tfs.getAudioAccessusers(this._heading.id).subscribe(d => {
    //     this.audioSelectedUser = d;
    //   })
    // }
    
    this.selectedCars2 = [
      {name: 'New York'},
      {name: 'Rome'},
    ];
    this.audioRecordingService.recordingFailed().subscribe(() => {
      this.isRecording = false;
    });

    this.audioRecordingService.getRecordedTime().subscribe((time) => {
      this.recordedTime = time;
    });

    this.audioRecordingService.getRecordedBlob().subscribe((data) => {
      this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.blob));
    });
  }

  ngOnInit() {
    
    //   this.cities2 = [
    //     {name: 'New York'},
    //     {name: 'Rome'},
    //     {name: 'London'},
    //     {name: 'Istanbul'}
    // ];
    
    
    
   
    
  }
  
  trackbyfunction(index, item) {
    return index

  }
  isNullOrEmpty(str) {
    return !str || !str.trim();
  }

  clickTime() {
    const clicktime = new Date().getTime()
    this.tds.setAccordiantime(clicktime)
  }
  checked(){
    if(this.questionslist){
    if(this.questionslist.length>=1){
      return this.questionslist.every(data=>{
        return  data.visible==true
      })
    }
   
    }
   }
   SelectAllVisible(e,val){
    const clicktime = new Date().getTime()
    this.tds.setAccordiantime(clicktime)
    let batch = this.afs.firestore.batch();
    if(val&&val.length>=1){
    val.forEach(data=>{
      this.afs.collection("questions").doc(data.id).update({visible:e.checked})
    }) 
    batch.commit().catch(err => console.error(err));
  }
  }
  deleteHeadingDialog(h) {
    const clicktime = new Date().getTime()
    this.tds.setAccordiantime(clicktime)
    this.EditHeading = h;
    this.EditQuestionHeading = this.EditHeading.Heading;
    this.display = true;
  }
  async UpdateHeading(h, sh) {
 
    this.tfs.updateHeadingresources(h, sh)
    if (this.questionslist.length != 0) {
      // await this.tfs.getUserCourseId(this._heading).subscribe(d => {
      //   var result = d.reduce((unique, o) => {
      //     if (!unique.some(obj => obj.UserId === o.UserId)) {
      //       unique.push(o);
      //     }
      //     return unique;
      //   }, []);
        let batch = this.db.firestore.batch();
        // return result.forEach(data => {
          this.questionslist.forEach(d => {
            // this.tfs.updateHeadings(d, data, sh);
            this.db.collection(`questions`).doc(d.id).update({
              id: d.id,
              Heading: sh,
              CourseId: d.CourseId,
              ResourceId: d.ResourceId,
              ResourceTypeId: d.ResourceTypeId,
              ResourceTypeName: d.ResourceTypeName,
            })
            // this.tfs.QuestionColHeadingUpdate(d, sh);
            // this.tfs.updateHeadingresources(h, sh)
          })
          batch.commit().catch(err => console.error(err));
        // })
      // })
    } else {
      // this.tfs.updateHeadingresources(h, sh)
    }

    this.display = false;
  }
  async newUpdateHeading(h, sh) {

    await this.questionslist.forEach(d => {
      this.tfs.QuestionColHeadingUpdate(d, sh);
    })
    await this.tfs.updateHeadingresources(h, sh)
  }
  cancelHeading() {
    this.tfs.getQuestionbyHeading("questions", this._heading.Heading).subscribe(data => {
      this.questionslist = data;
    })
    this.display = false;
  }
  deleteHeading() {


    if (this.questionslist.length != 0) {
      this.tfs.getUserCourseId(this._heading).subscribe(d => {
        var result = d.reduce((unique, o) => {
          if (!unique.some(obj => obj.UserId === o.UserId)) {
            unique.push(o);
          }
          return unique;
        }, []);


        result.forEach(data => {
          this.questionslist.forEach(d => {
            this.questionslistQus = d;
            this.tfs.deleteQuestion(d);
            this.tfs.deleteQuestionHeading(this._heading);
            this.tfs.deleteQuestions(d, data);
          })
        })
      })
    } else {
      this.tfs.deleteQuestionHeading(this._heading);
    }
    this.display = false;
    // this.questionslist.forEach(d => {
    //   this.tfs.deleteQuestion(d);
    // })

    // this.tfs.deleteQuestionHeading(this._heading);
  }
  answerTable(heading) {
    const clicktime = new Date().getTime()
    this.tds.setAccordiantime(clicktime)
    this.courseId = heading.CourseId;
    this.courseHeading = heading.id;
    this.tableDialog = true;
  }
  onShow(e) {
  }
  saveQuestions(q) {
    let id = this.afs.createId();
    if (q) {
      q = q.charAt(0).toUpperCase() + q.slice(1);
      if (this.isNullOrEmpty(q)) {
        return;
      } else {
        this.tfs.getInterviewQuestionList("questions", "IndexNo").pipe(take(1)).subscribe(d => {
          if (d[0] == undefined) {
            this.indexnumber = 1;
          } else {
            this.indexnumber = d[0].IndexNo + 1;
          }
          this.tfs.addQuestionForUsers(this._heading, q, id, this.questionNo, this.indexnumber);
        })

      }
    }

    this.question = null;
  }
  EditiconClick(field) {
    this.EditUserData = field;
    this.EditUserQuestion = this.EditUserData.Question;
    this.Questiondialog = true;
  }
  handleChange(e, que) {
    this.tfs.updatevisiblestatus("questions", e.checked, que)
  }
  UpdateQuestion(field, qus) {
    this.tfs.getUserCourseId(this._heading).subscribe(d => {
      var result = d.reduce((unique, o) => {
        if (!unique.some(obj => obj.UserId === o.UserId)) {
          unique.push(o);
        }
        return unique;
      }, []);
      result.forEach(data => {

        this.tfs.updateQuestions(field, qus, data);
      })
    })
    this.tfs.questionUpdate(field, qus);
    this.Questiondialog = false;
  }
  cancelQuestion(field) {

    this.tfs.getQuestionbyHeading("questions", this._heading.Heading).subscribe(data => {
      this.questionslist = data;
    })
    this.Questiondialog = false;
  }
  deleteQuestion(field) {

    this.tfs.getUserCourseId(this._heading).subscribe(d => {
      var result = d.reduce((unique, o) => {
        if (!unique.some(obj => obj.UserId === o.UserId)) {
          unique.push(o);
        }
        return unique;
      }, []);
      result.forEach(data => {
        this.tfs.deleteQuestions(field, data);
      })
    })

    this.tfs.deleteQuestion(field);
    this.Questiondialog = false;
  }
  startRecording() {
    this.starRrec=true
    if (!this.isRecording) {
      this.isRecording = true;
      this.audioRecordingService.startRecording();
    }
  }

  abortRecording() {
    if (this.isRecording) {
      this.isRecording = false;
      this.audioRecordingService.abortRecording();
    }
  }

  stopRecording() {
    this.starRrec=false
    if (this.isRecording) {
      // this.audioRecordingService.stopRecording();
      this.isRecording = false;
    }
  }
  async onChangeMultiselect(e){
    
    // console.log(e);
if(e.itemValue){
    await this.tfs.getAudiosuserstrue(this._heading.id).pipe(take(1)).subscribe(d => {
        this.userdata = d;
      var usersUpdate = {};
    var b=e.itemValue;
    var key=b.replace(/\./g,"_");
    // console.log(this.userdata[key]);
    
    usersUpdate[`${key}`] = this.userdata[key]==true?false:true;
    if(this.userdata[key]==true||this.userdata[key]==false){
    this.afs.collection("resources").doc(this._heading.id).update(
      usersUpdate
    );
    }
if(this.userdata[key]==false){
  this.tfs.addAudiousers(this._heading.id,e.itemValue);
}else if(this.userdata[key]==undefined){
  usersUpdate[`${key}`] = true;
        this.afs.collection("resources").doc(this._heading.id).update(
          usersUpdate
        );
        this.tfs.addAudiousers(this._heading.id,e.itemValue);   
}
 if(this.userdata[key]==true){
  //  console.log(e.itemValue);
   
  this.tfs.deleteAudiousers(this._heading.id,e.itemValue);
}

    })
  }
  else{
    if(e.value.length!=0){
    let batch = this.afs.firestore.batch();
 
      e.value.forEach(data=>{ 
        // console.log(data);    
        var usersUpdate = {};
        var users = {};
      var b=data
      var key=b.replace(/\./g,"_");
      usersUpdate[`${key}`] = true;
      users["UserId"] = data;
        this.afs.collection("resources").doc(this._heading.id).update(usersUpdate)
        this.afs.collection("resources").doc(this._heading.id).collection("audio").doc(data).set(users)
      }) 
      batch.commit().catch(err => console.error(err));
    
    }
    if(e.value.length==0){
      // console.log(this.selectedUser);
      this.userlist=this.selectedUser
      let batch = this.afs.firestore.batch();
   
      this.userlist.forEach(data=>{ 
          // console.log(data);    
          var usersUpdate = {};
        
        var b=data.UserId
        var key=b.replace(/\./g,"_");
        usersUpdate[`${key}`] = false;
      
          this.afs.collection("resources").doc(this._heading.id).update(usersUpdate)
          this.afs.collection("resources").doc(this._heading.id).collection("audio").doc(b).delete()
        }) 
        batch.commit().catch(err => console.error(err));
      
      }
  }
 
  }
  multipleSelectOnclick(heading){

    const clicktime = new Date().getTime();
    this.tds.setAccordiantime(clicktime);
    this.courseId = heading.CourseId;
   
    // this.tfs.getAudioAccessusers(this._heading.id).subscribe(d => {
    //   // console.log(d);
    //   this.audioSelectedUser = d;
    // })
  //   let unique_array1 = [];
  //   let unique_array2 = [];
  //   this.tfs.getUserCourseUsingId(this.courseId).subscribe(async data => {
  //     for (let i = 0; i < data.length; i++) {
  //       if (unique_array1.indexOf(data[i].UserId) == -1) {
  //         data.map(item=>{
  //           var b=item.UserId
  //           var key=b.replace(/\./g,"_");
  //           item.SearchId=key
  //             return   item
  //         })
  //         unique_array1.push(data[i].UserId);
  //         unique_array2.push(data[i]);
  //       }
  //     }
  //     this.selectedUser = unique_array2;
  //     console.log(this.selectedUser);
      
  //   })
  }
  onfocus(e){
// console.log(e);

  }
  onblur(e){
    // console.log(e);
  }
}
