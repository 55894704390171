import { Injectable } from '@angular/core';
import * as RecordRTC from 'recordrtc';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { TekarchFbService } from './tekarchfbservice';
import { TekarchDataService } from './tekarchdataservice';
import { tap, finalize } from 'rxjs/operators';
import { TAResources } from '../Interface/resources';
import { TAUser } from '../Interface/ta-user';

interface RecordedAudioOutput {
  blob: Blob;
  title: string;
}

@Injectable({
  providedIn: 'root'
})
export class AudioRecordingService {
  task: AngularFireUploadTask;

  // Progress monitoring
  percentage: Observable<number>;

  snapshot: Observable<any>;

  // Download URL
  downloadURL: Observable<string>;


  // State for dropzone CSS toggling
  isHovering: boolean;
  photourl: string;
  _candidate: string;
  _currentuser: string;
  resource: TAResources;
  currentuser: TAUser;
  path: string;
  constructor(
    private storage: AngularFireStorage,private tafs:TekarchFbService, private tads: TekarchDataService
  ) { 
    this.currentuser = this.tads.getCurrentUser()
  }
 

  private stream;
  private recorder;
  private interval;
  private startTime;
  private _recorded = new Subject<RecordedAudioOutput>();
  private _recordingTime = new Subject<string>();
  private _recordingFailed = new Subject<string>();


  getRecordedBlob(): Observable<RecordedAudioOutput> {
    return this._recorded.asObservable();
  }

  getRecordedTime(): Observable<string> {
    return this._recordingTime.asObservable();
  }

  recordingFailed(): Observable<string> {
    return this._recordingFailed.asObservable();
  }


  startRecording() {

    if (this.recorder) {
      // It means recording is already started or it is already recording something
      return;
    }

    this._recordingTime.next('00:00');
    navigator.mediaDevices.getUserMedia({ audio: true }).then(s => {
      this.stream = s;
      this.record();
    }).catch(error => {
      this._recordingFailed.next();
    });

  }

  abortRecording() {
    this.stopMedia();
  }

  private record() {

    this.recorder = new RecordRTC.StereoAudioRecorder(this.stream, {
      type: 'audio',
      mimeType: 'audio/webm'
    });

    this.recorder.record();
    this.startTime = moment();
    this.interval = setInterval(
      () => {
        const currentTime = moment();
        const diffTime = moment.duration(currentTime.diff(this.startTime));
        const time = this.toString(diffTime.minutes()) + ':' + this.toString(diffTime.seconds());
        this._recordingTime.next(time);
      },
      1000
    );
  }

  private toString(value) {
    let val = value;
    if (!value) {
      val = '00';
    }
    if (value < 10) {
      val = '0' + value;
    }
    return val;
  }

  stopRecording(question ,user) {

    if (this.recorder) {
      this.recorder.stop(async (blob) => {
        if (this.startTime) {
          const mp3Name = encodeURIComponent('audio_' + new Date().getTime() + '.mp3');
          this.stopMedia();
          this._recorded.next({ blob: blob, title: mp3Name });
          // console.log(mp3Name);
          
          // The storage path
      if(this.currentuser.RoleName=="Admin"){
      
        this.path = `InterviewPreperation/Audio/${question.id}/${mp3Name}`;
      }
      // else{
        // this.path = `Assignment/Assignment111/${mp3Name}`;
        // return
      //  this.path = `Assignment/${this.resource.CourseId}/${this.resource.Heading}/${this.resource.Assignment}/${this.currentuser.UserId}/${file.name}`;
      // }
          // Totally optional metadata
          const customMetadata = { app: "My AngularFire-powered PWA!" };
          // The main task
          // console.log(this.path);
          // console.log(blob);
          // console.log(customMetadata);
          
          
          
          this.task = this.storage.upload(this.path, blob, { customMetadata });

         await this.task.then(res=>{console.log('Success')
         this.adddata(this.path,question,user); 
    });
          // this.adddata(this.path,question); 
          // setTimeout(() => {
            // this.storage.ref(this.path).delete();
          // }, 1000);
        
         
        }
      }, () => {
        this.stopMedia();
        this._recordingFailed.next();
      
      });
    }
  }
adddata(path,question,user){
  this.downloadURL = this.storage.ref(path).getDownloadURL()
  this.downloadURL.forEach(data => {
    this.photourl = data;
    // update the photo to the "user" collection
    if(this.currentuser.RoleName=="Admin"){
        this.tafs.AudioUpdate(question,this.photourl,user)
      }
    });
  
}
  private stopMedia() {
    if (this.recorder) {
      this.recorder = null;
      clearInterval(this.interval);
      this.startTime = null;
      if (this.stream) {
        this.stream.getAudioTracks().forEach(track => track.stop());
        this.stream = null;
      }
    }
  }

}
